<template>
  <el-dialog
    title="详情"
    width="520px"
    :visible="calendarUserDetailsDialog"
    @close="closeDialog"
  >
    <div class="d" v-if="selectUserDetails">
      <el-tree
        :data="selectUserDetails"
        node-key="uid"
        accordion
        :expand-on-click-node="false"
        :default-checked-keys="[1]"
        :default-expanded-keys="expandDefault"
        @node-click="handleNodeClick"
        @current-change="currentChange"
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{ data.title }}</span>
          <span>
            <!-- <el-button
            type="text"
            size="mini"
            @click="() => append(data)">
            Append
          </el-button>
          <el-button
            type="text"
            size="mini"
            @click="() => remove(node, data)">
            Delete
          </el-button> -->
          </span>
        </span>
      </el-tree>
    </div>

    <!-- 详情 -->
    <calendarDetials
      v-if="detailsDialog"
      :detailsDialog="detailsDialog"
      :detailsData="selectThingsDetails"
      v-on:listenDetails="listenDetails"
    ></calendarDetials>
    <!-- b -->
    <calendarEdit
      v-if="editDialog"
      :type="type"
      :dialogVisible="editDialog"
      :selectUserList="selectThingsDetails.userList"
      :calendarFormBefore="selectThingsDetails"
      v-on:listenEditClose="listenEditClose"
    ></calendarEdit>
  </el-dialog>
</template>

<script>
import {
  getCalendarThingDetails,
  deleteCalendarThing,
  getScheduleCalendarInfo,
} from "@/api/calendar.js";
import { formatDate } from "@/common/date.js";
import calendarDetials from "@/components/calendar/calendarDetails.vue";
import calendarEdit from "@/components/calendar/edit.vue";
// import { observable } from 'vue/types/umd';

export default {
  name: "calendarUserDetailsDialog",

  data() {
    return {
      editDialog: false,
      // calendarUserDetailsDialog: false,
      // selectUserDetails: [],
      expandDefault: [],
      checkDefault: [],
      selectThingsDetails: {},
      detailsDialog: false,
      type: null,
    };
  },
  components: {
    calendarDetials,
    calendarEdit,
  },
  props: {
    calendarUserDetailsDialog: Boolean,
    selectUserDetails: Array,
    info: Object,
  },
  created() {
    if(this.selectUserDetails.length > 0){
        this.expandDefault.push(this.selectUserDetails[0].uid);
    this.checkDefault.push(this.selectUserDetails[0].uid);
    }

    // console.log(this.$parent.$data.selectUserDetails);
    // this.selectUserDetails = this.$parent.$data.selectUserDetails;
    // this.calendarUserDetailsDialog = this.$parent.$data.calendarUserDetailsDialog;
  },

  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  watch: {
    selectUserDetails: {
      handler(newValue, oldValue) {
        console.log(newValue);
        if (newValue) {
          this.expandDefault.push(this.selectUserDetails[0].uid);
          this.checkDefault.push(this.selectUserDetails[0].uid);
        }
      },
      deep: true,
    },

    //  'checkDefault':function(newVal, oldVal) {
    //    if(newVal){
    //      this.$nextTick(() => {
    //           document.querySelector('.el-tree-node__content').click()
    //       })
    //    }
    //  }
  },
  methods: {
    handleNodeClick(data) {
      console.log(data);
       if (data.uid){
        let info = []
        let one = []
        one.push(data.deptList[0].deptId)
        one.push(data.deptList[0].userId)
        info.push(one)

        this.$emit("listenCalendarUserDetails", { close: true, type: "scope",info:info });

      }
      if (data.id) {
        if(data.level == 1){
        this.getCalendarDetails(data.id);

        }else if(data.level == 2){
        // this.getCalendarDetails(data.id);

        }
      }
    },
    //获取事件详情
    getCalendarDetails(id) {
      getCalendarThingDetails(id).then((response) => {
        this.selectThingsDetails = response.data;
        console.log(this.selectThingsDetails);
        this.detailsDialog = true;
      });
    },
    getScheduleCalendarInfo(info) {
      getScheduleCalendarInfo(info).then((response) => {
        let selectUserDetails = response.data;
        console.log(selectUserDetails);
        let nameTitle = "";
        if (selectUserDetails.deptList) {
          let nameTitle = this.getTextByJs(selectUserDetails.deptList) + ":";
        }

        selectUserDetails.forEach(function (item, index) {
          item.children = item.scheduleList;
          item.title = nameTitle + item.realName;
        });
        this.selectUserDetails = selectUserDetails;
      });
    },
    getTextByJs(arr) {
      console.log("arr");
      var str = "";
      for (var i = 0; i < arr.length; i++) {
        str += arr[i].deptName + "、";
      }
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        str = str.substr(0, str.length - 1);
      }
      return str;
    },
    //监听详情弹窗关闭
    listenDetails(params) {
      console.log(params);
      let { close, type } = params;
      if (type == "delete") {
        this.detailsDialog = !close;
        console.log("5---------");
      } else if (type == "edit") {
        this.type = "edit";
        this.editDialog = true;
      } else {
        this.detailsDialog = !close;
      }
      this.getScheduleCalendarInfo(this.info);
    },
    // 监听编辑、新增弹窗关闭
    listenEditClose(params) {
      let { close, editType } = params;
      if (editType == "add") {
        console.log("6---------");

        // this.getCalendarList(
        //   this.getYearAndMounth,
        //   this.userSet.value.userIdList
        // );
      } else if (editType == "edit") {
        this.getScheduleCalendarInfo(this.info);
        console.log("4---------");

        // this.getCalendarList(
        //   this.getYearAndMounth,
        //   this.userSet.value.userIdList
        // );
        this.detailsDialog = false;
      }
      this.editDialog = !close;
      console.log("3---------");

      // this.getCalendarList(
      //   this.getYearAndMounth,
      //   this.userSet.value.userIdList
      // );
    },
    //打开详情弹窗
    showThingsDetails(thingsId, data) {},
    currentChange(data, node) {
      console.log(data);
      // console.log(node);
    },
    closeDialog() {
      this.$emit("listenCalendarUserDetails", { close: true });
    },

    //删除本事件提示
    delectThings() {
      this.$confirm("确定要删除此事件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: false,
      })
        .then(() => {
          deleteCalendarThing(this.$parent.$data.selectThingId).then(
            (response) => {
              if (response.success) {
                this.$emit("listenCalendarUserDetails", {
                  close: true,
                  type: "delete",
                });
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
              } else {
                this.$message({
                  type: "info",
                  message: response.message,
                });
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.cotent {
  width: calc(100% - 80px);
  text-align: left;
}
</style>
