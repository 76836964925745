<template>
  <el-dialog
    title="详情"
    width="620px"
    :visible="detailsDialog"
    @close="closeDialog"
    append-to-body
  >
    <el-row type="flex" justify="end" v-if="detailsData.modify">
      <i
        class="el-icon-edit"
        style="font-size: 30px; margin-right: 30px"
        @click="editThings"
        v-permission="['admin:schedule:update_thing']"
      ></i>
      <i
        class="el-icon-delete-solid"
        style="font-size: 30px"
        @click="delectThings"
        v-permission="['admin:schedule:delete_thing']"
      ></i>
    </el-row>
    <el-form :model="detailsData" ref="inputInvestor" label-width="160px">
      <el-row>
        <el-col>
          <el-form-item label="标题:" prop="title">
            <div class="cotent">{{ detailsData.title }}</div>
          </el-form-item>

          <el-form-item label="相关人员:" prop="userList">
            <div class="cotent">
              <span
                v-for="(item, index) in detailsData.userList"
                :key="index"
                style="padding-right: 5px"
              >
                {{ index + 1 }}.{{ item.realName }}
              </span>
            </div>
          </el-form-item>
          <el-form-item label="时间:" prop="time">
            <div class="cotent">
              {{ detailsData.beginTime | formatDateFilter }} 至
              {{ detailsData.endTime | formatDateFilter }}
            </div>
          </el-form-item>
          <el-form-item label="地点:" prop="place">
            <div class="cotent">{{ detailsData.place }}</div>
          </el-form-item>
          <el-form-item label="行程:">
            <div class="flexFcJf trip">
              <div
                v-for="(item, index) in detailsData.tripList"
                :key="index"
                style="padding-right: 15px"
                class="flexJfAc"
              >
                <div class="tripIndex">
                  <span>{{ index + 1 }}. </span>
                </div>
                <div>
                  <div class="flex">
                    <span class="tripLeft ">往返城市：</span>
                    <span> {{ item.出差城市 }} - {{ item.目的城市 }}</span>
                  </div>
                  <div class="flex">
                    <span class="tripLeft ">起止时间：</span>
                    <span
                      >{{ item.开始时间 | formatDateFilter2 }} 至
                      {{ item.结束时间 | formatDateFilter2 }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="日程类别:" prop="officeMode">
            <div class="cotent">{{ detailsData.officeModeText }}</div>
          </el-form-item>
          <el-form-item label="事由类型:" prop="type">
            <div class="cotent">{{ detailsData.typeText }}</div>
          </el-form-item>

          <el-form-item label="事由:" prop="officeMode">
            <div class="cotent">{{ detailsData.reason }}</div>
          </el-form-item>
          <el-form-item label="关联的项目或投资机构:">
            <div class="flexFcJf">
              <span
                v-for="(item, index) in detailsData.businessList"
                :key="index"
                style="padding-right: 15px"
              >
                <span>{{ index + 1 }}. {{ item.businessName }}</span>
              </span>
            </div>
          </el-form-item>
          <!-- <el-form-item label="预期产出:" v-if="detailsData.output">
            <div class="cotent">
              【{{ detailsData.output | transOutput }}】
              <el-button
                v-if="detailsData.outputId"
                @click="openMeetingDetail(detailsData.outputId)"
                type="text"
              >
                {{ detailsData.outputName }}
              </el-button>
              <el-link
                type="primary"
                v-else-if="isOwner"
                :href="'/myMission?new_meeting=1&schedule_id=' + detailsData.id"
                target="_blank"
                :underline="false"
                >去提交
              </el-link>
              <span v-else style="color: #ed4014">待提交</span>
            </div>
          </el-form-item> -->

          <el-form-item label="预期产出:">
            <!-- <div class="cotent">{{ businessFullName }}</div> -->
            <div
              class=""
              v-if="detailsData.outputList && detailsData.outputList.length > 0"
            >
              <div
                v-for="(item, index) in detailsData.outputList"
                :key="index"
                style="padding-right: 15px"
              >
                <span class="cotent"
                  >{{ index + 1 }}.关联的<span v-show="item.extName"
                    >{{ item.extName }}：</span
                  >
                  <el-button
                    v-if="item.outputId"
                    @click="openMeetingDetail(item.outputId)"
                    type="text"
                  >
                    {{ item.outputName }}
                  </el-button>

                  <el-link
                    type="primary"
                    v-else-if="item.extId"
                    :href="
                      '/myMission?new_meeting=1&schedule_id=' +
                      detailsData.id +
                      '&ext_id=' +
                      item.extId +
                      '&ext_type=' +
                      item.extType
                    "
                    target="_blank"
                    :underline="false"
                    >去提交【会议纪要】
                  </el-link>
                  <span v-else style="color: #ed4014">待提交</span>
                </span>
              </div>
            </div>
            <div v-else>
              <span v-show="detailsData.output == 'meeting_record'"
                >【会议纪要】
                <el-link
                  type="primary"
                  :href="
                    '/myMission?new_meeting=1&schedule_id=' + detailsData.id
                  "
                  target="_blank"
                  :underline="false"
                  >去提交【会议纪要】
                </el-link></span
              >
            </div>
          </el-form-item>

          <el-form-item label="是否随行人员:" prop="isFollowUser">
            <div class="cotent">
              {{
                detailsData.isFollowUser == 1
                  ? "是"
                  : detailsData.isFollowUser == 0
                  ? "否"
                  : ""
              }}
            </div>
          </el-form-item>
          <el-form-item label="备注:" prop="note">
            <div class="cotent">{{ detailsData.note }}</div>
          </el-form-item>
          <el-form-item label="创建人:" prop="note">
            <div class="cotent">{{ detailsData.createUserName }}</div>
          </el-form-item>
          <el-form-item label="创建时间:" prop="note">
            <div class="cotent">
              {{ detailsData.createDate | formatDateFilter }}
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-dialog>
</template>

<script>
import {
  getCalendarThingDetails,
  deleteCalendarThing,
} from "../../api/calendar.js";
import { formatDate } from "../../common/date.js";
import { selectListDict } from "@/api/dataManagement";

export default {
  name: "calendarDetials",

  data() {
    return {
      // detailsDialog: false,
      // detailsData: {},
    };
  },
  props: {
    detailsDialog: Boolean,
    detailsData: Object,
  },
  created() {
    console.log(this.$parent.$data);
    this.selectListDict("schedule_type", this.detailsData.type, 1);
    this.selectListDict("office_mode", this.detailsData.officeMode, 2);
    // this.detailsData = this.$parent.$data.selectThingsDetails;
    // this.detailsDialog = this.$parent.$data.detailsDialog;
  },

  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    formatDateFilter2(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm");
    },
    transOutput(output) {
      if (output === "meeting_record") {
        return "会议纪要";
      }
      return output;
    },
    transExtType(extType) {
      if (extType === "project") {
        return "项目";
      } else if (extType === "organization") {
        return "投资机构";
      }
      return extType;
    },
    businessType(businessType) {
      if (businessType === 2) {
        return "项目";
      } else if (businessType === 3) {
        return "投资机构";
      }
      return businessType;
    },
  },

  methods: {
    selectListDict(type, id, modle) {
      var that = this;
      let info = { group: type };
      selectListDict(info).then((response) => {
        if (response.success) {
          let dictList = response.data;
          dictList.forEach(function (item, index) {
            if (id == item.value) {
              if (modle == 1) {
                that.$set(that.detailsData, "typeText", item.name);
              } else {
                that.$set(that.detailsData, "officeModeText", item.name);
              }
            }
          });
        }
      });
    },
    closeDialog() {
      this.$emit("listenDetails", { close: true });
    },

    //删除本事件提示
    delectThings() {
      this.$confirm("确定要删除此事件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: false,
      })
        .then(() => {
          deleteCalendarThing(this.detailsData.id).then((response) => {
            if (response.success) {
              this.$emit("listenDetails", { close: true, type: "delete" });
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            } else {
              this.$message({
                type: "info",
                message: response.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    //编辑事件
    editThings() {
      console.log("223");
      this.$emit("listenDetails", { close: false, type: "edit" });
    },

    openMeetingDetail(meetingId) {
      this.$emit("openMeetingDetail", { meetingId });
    },
  },

  computed: {
    businessFullName() {
      let name = "";
      if (this.detailsData) {
        if (this.detailsData.businessType === 2) {
          name = "项目-";
        } else if (this.detailsData.businessType === 3) {
          name = "投资机构-";
        }
        if (this.detailsData.businessName) {
          name = name + this.detailsData.businessName;
        }
      }
      return name;
    },

    isOwner() {
      return window.localStorage.getItem("userId") === this.detailsData.ownerId;
    },
  },
};
</script>

<style lang="scss" scoped>
.cotent {
  width: calc(100% - 80px);
  text-align: left;
}
.el-form-item {
    margin-bottom: 5px;
}
.trip {
  .tripIndex {
    align-self: start;
    margin-right: 3px;
  }
  .tripLeft {
    // min-width: 70px;
  }
}
</style>
