<!--
 * @Description: 
 * @Author: transparent
 * @Date: 2022-01-07 09:57:10
 * @LastEditTime: 2022-08-18 14:47:06
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/components/officeAutomation/flightCitySuggest.vue
-->
<template>
  <div>
    <el-select
      v-model="selectCity"
      filterable
      remote
      :remote-method="getFlightCitySuggest"
      :loading="loading"
      @change="onSelectCity"
      value-key="name"
      placeholder="请输入"
      no-data-text="加载中···"
      :disabled="type == 'edit'"
    >
      <template v-if="nearby">
        <el-option disabled value="">"{{ keyword }}"没有机场</el-option>
        <el-option v-for="(item, index) in cities" :key="index" :label="item.value" :value="item">
          <template>
            <span>附近机场 </span>
            <span style="color:blue;">{{ item.travelName + ' - ' + item.distance + '公里' }}</span>
            <span style="color: #999;"> {{ item.code }}</span>
          </template>
        </el-option>
      </template>
      <template v-else>
        <el-option v-for="(item, index) in cities" :key="index" :label="item.value" :value="item"></el-option>
      </template>
    </el-select>
  </div>
</template>

<script>
import {getFlightCitySuggest} from "@/api/calendar";

export default {
  name: "flightCitySuggest",
  props: {
    value: {
      type: String,
      default: null
    },
    fromSelectCity:String,
    type:String

  },
  data() {
    return {
      keyword: null,
      selectCity: null,
      cities: [],
      nearby: false,
      loading: false,
    }
  },
  watch: {
    selectCity: val => {
      console.log('selectCity', val);
    },
   fromSelectCity: {
      handler(newVal, old) {
        console.log(newVal);
        this.selectCity = newVal;
      },
      immediate: true,
      deep: true,
    },
    
  },
  methods: {
    getFlightCitySuggest(keyword) {
      this.keyword = keyword;
      if (!keyword) {
        this.cities = [];
        return;
      }

      this.loading = true;
      getFlightCitySuggest(keyword, 2).then(res => {
        if (res.success) {
          this.nearby = res.data.nearby || false;
          this.cities = res.data.cities || [];
          this.cities.forEach(x => x.value = this.nearby ? `${x.name} (从${x.travelName}机场出发)` : x.name);
        }
      }).finally(() => {
        this.loading = false;
      })

    },
    onSelectCity(city) {
      this.$emit('input', city.value);
    }
  }
}
</script>

<style scoped lang="scss">
div {
  width: 100%;

  .el-select {
    width: 100%;
  }
}
</style>
