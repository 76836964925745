<!--
 * @Description: 
 * @Author: transparent
 * @Date: 2022-01-07 09:57:10
 * @LastEditTime: 2022-08-18 14:45:37
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/components/officeAutomation/trainCitySuggest.vue
-->
<template>
  <div>
    <el-select
      v-model="selectCity"
      filterable
      remote
      :remote-method="getTrainCitySuggest"
      :loading="loading"
      @change="onSelectCity"
      value-key="name"
      placeholder="请输入"
      no-data-text="加载中···"
      :disabled="type == 'edit'"
    >
      <el-option
        v-for="(item, index) in cities"
        :key="index"
        :label="item.name"
        :value="item"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import { getTrainCitySuggest } from "@/api/calendar";

export default {
  name: "trainCitySuggest",
  props: {
    value: {
      type: String,
      default: null,
    },
    fromSelectCity: String,
    type: String,
  },
  watch: {
    selectCity: (val) => {
      console.log("selectCity", val);
    },
    fromSelectCity: {
      handler(newVal, old) {
        console.log(newVal);
        this.selectCity = newVal;
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      selectCity: null,
      cities: [],
      loading: false,
    };
  },
  methods: {
    getTrainCitySuggest(keyword) {
      if (!keyword) {
        this.cities = [];
        return;
      }

      this.loading = true;
      getTrainCitySuggest(keyword)
        .then((res) => {
          if (res.success) {
            this.cities = res.data.cities;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSelectCity(city) {
      this.$emit("input", city.name);
    },
  },
};
</script>

<style scoped lang="scss">
div {
  width: 100%;

  .el-select {
    width: 100%;
  }
}
</style>
