<template>
  <el-row class="scrollbar">
    <div class="" v-if="overviewList.length > 0">
        <div class="flexJA tips">
      <span> 小提示： </span>
      <span> 流程节点上的数字为 </span>
      <el-tag type="danger" effect="plain">进行中</el-tag>
      、
      <el-tag type="" effect="plain">待启动</el-tag>
      、
      <el-tag type="success" effect="plain">已完成</el-tag>
      <span> 任务的数量。</span>
    </div>
      <div v-for="(item, index) in overviewList" :key="index" class="text item">
        <div class="title">流程{{ index + 1 }}-{{ item.name }}</div>
        <div class="nodeList flexJfAc scrollbarX">
          <div
            class="nodeOne flexJA"
            v-for="(node, nodeIndex) in item.nodeList"
            :key="nodeIndex"
          >
            <el-button
              size="small"
              round
              @click="projectOverviewJump(item, node)"
              slot="reference"
              class="flexJfAc"
              >{{ node.nodeName }} (
              <span
                v-show="
                  displayStatusList.indexOf(5) != '-1' ||
                  displayStatusList.length == 0
                "
                class="runningProjectNum"
              >
                <el-tag class="tagW" type="danger" effect="plain">{{
                  node.taskQuantityMap.runningTaskQuantity
                }}</el-tag>
              </span>
              <span
                v-show="
                  displayStatusList.indexOf(4) != '-1' ||
                  displayStatusList.length == 0
                "
                class="notStartedProjectNum"
              >
                <el-tag class="tagW" type="" effect="plain">{{
                  node.taskQuantityMap.notStartedTaskQuantity
                }}</el-tag>
              </span>

              <span
                style=""
                v-show="
                  displayStatusList.indexOf(6) != '-1' ||
                  displayStatusList.length == 0
                "
                class="finishedTaskNum"
              >
                <el-tag class="tagW" type="success" effect="plain">{{
                  node.taskQuantityMap.finishedTaskQuantity
                }}</el-tag>
              </span>
              )</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="" v-else>
      <el-empty :image-size="100" description="暂无数据"></el-empty>
    </div>
  </el-row>
</template>

<script>
import { getOverviewList } from "@/api/home";
import eventBus from "@/common/eventBus";
import { getOptionUserInfo } from "@/api/calendar";

export default {
  name: "projectOverview",
  data() {
    return {
      overviewList: [], //待办事项
      detailsDialog: false,
      editDialog: false,
      selectThingsDetails: null,
      editType: null,
      selectThingId: null,
      projectOverviewDays: "-7",
      popoverVisible: false,
      displayStatusList: [5],
      optionName: "task:my_mission:preference:task_status",
    };
  },
  components: {},
  computed: {},
  watch: {
    projectOverviewTime(newVal, old) {
      if (newVal == "-7") {
        this.projectOverviewDays = newVal;
        this.getOverviewList();
      } else if (newVal == "all") {
        this.projectOverviewDays = null;
        this.getOverviewList();
      }
      this.getOptionUserInfo();

    },
  },
  created() {
    // this.getOverviewList();
  },
  props: {
    projectOverviewTime: String,
  },
  activated() {
    console.log("home--------actived4");
    this.getOverviewList();
    this.getOptionUserInfo();
  },
  methods: {
    getOptionUserInfo() {
      let item = window.localStorage.getItem(this.optionName);
      if (item) {
        this.displayStatusList = JSON.parse(item) || [5];
      }
      let param = {
        name: this.optionName,
      };
      getOptionUserInfo(param).then((res) => {
        if (res.success) {
          this.displayStatusList = JSON.parse(res.data) || [5];
        }
      });
    },
    projectOverviewJump(item, node) {
      console.log(item);
      console.log(node);
      let workflowInfo = {
        id: item.id,
        name: item.name,
      };
      let nodeInfo = {
        id: node.nodeId,
        name: node.nodeName,
      };
      // eventBus.$emit("projectOverview", { workflowInfo, nodeInfo });
      const { href } = this.$router.push({
        name: "myMission",
        query: {
          tab: "myMission",
          workflowId: item.id,
        },
      });
      eventBus.$emit("onSelectOverviewWorkflow", item.id);
    },
    jumpFirstLevel(node) {
      console.log(node);
      const { href } = this.$router.resolve({
        name: "project",
        query: { nodeId: node.nodeId, workflowId: node.workflowId },
      });
      window.open(href);
    },
    jumpSecondLevel(node) {
      const { href } = this.$router.resolve({
        name: "projectSecondaryMarket",
        query: { nodeId: node.nodeId, workflowId: node.workflowId },
      });
      window.open(href);
    },
    jumpGovPlatform(node) {
      const { href } = this.$router.resolve({
        name: "projectGovernment",
        query: { nodeId: node.nodeId, workflowId: node.workflowId },
      });
      window.open(href);
    },
    //
    jumpLink(item) {
      console.log(item);
      this.$emit("listenDetailsClose", {
        type: "sure",
        isClose: true,
        info: item,
      });
    },

    handleClick(tab, event) {
      console.log(tab.index, event);
      let index = tab.index;
      if (index == 0) {
        this.getTodoQueryList();
      } else if (index == 1) {
      }
    },
    getOverviewList() {
      let info = { day: this.projectOverviewDays };
      getOverviewList(info).then((response) => {
        console.log(response.data);
        this.overviewList = response.data;
      });
    },
    //
  },
};
</script>

<style lang="scss" scoped>
.tagW {
  padding: 0 3px !important;
  height: 1.2rem !important;
  line-height: 1.1rem !important;
  font-size: 14px;
  margin: 0 1px;
  border-radius: 8px;
}
.tips {
  font-size: 12px;
  color: #606266;

  .el-tag {
    padding: 0px 3px;
    height: 1.2rem !important;
    line-height: 1.1rem !important;
  }
}
.el-row {
  max-height: 18rem;
  overflow-y: auto;
  overflow-x: hidden;
  .item {
    margin: 10px 0;
    .nodeList {
      overflow-x: auto;
      overflow-y: hidden;
      height: 4rem;
      width: 100%;
      .nodeOne {
        .el-button {
          margin: 5px 10px;
          border-style: solid;
        }

        .nodeListIcon {
        }
      }
    }
  }
}
</style>
