<template>
  <el-row type="flex" justify="space-between" class="home">
    <el-col class="left">
      <!-- 我的待办 -->
      <el-card class="box-card needDealt">
        <div slot="header" class="clearfix flexJfAc">
          <span
            class="headerTitle headerTitleLink"
            :class="{ timeSelect: isSchedule }"
            @click="openSchedule"
            >我的日程</span
          >
          <el-divider direction="vertical"></el-divider>
          <el-badge :is-dot="false" class="item">
            <span
              class="headerTitle headerTitleLink"
              :class="{ timeSelect: !isSchedule }"
              @click="openExamineAndApprove"
              >申请与审批</span
            >
          </el-badge>
        </div>
        <div class="clearfix" v-show="isSchedule">
          <el-tabs
            style="width: 100%"
            v-model="activeName"
            @tab-click="handleClick"
            :stretch="false"
          >
            <el-tab-pane label="待办事项" name="first">
              <needDealt
                :indexType="indexType"
                v-on:listenDetailsClose="listenDetailsClose"
              ></needDealt>
            </el-tab-pane>
            <el-tab-pane
              label="日历"
              name="second"
              v-permission="['admin:schedule:my_things']"
            >
              <!-- 日程 -->

              <calendar
                :indexType="indexType"
                :scheduleId="scheduleId * 1"
              ></calendar>
            </el-tab-pane>
            <!--            <el-tab-pane-->
            <!--              label="新增日程"-->
            <!--              name="Third"-->
            <!--              v-permission="['admin:schedule:new_schedule']"-->
            <!--            >-->
            <!--              &lt;!&ndash; 新增日程 &ndash;&gt;-->
            <!--              <newSchedule></newSchedule>-->
            <!--            </el-tab-pane>-->
            <el-tab-pane name="fourth">
              <template slot="label">
                <span :class="hasNotReplyComment ? 'new-badge' : ''"
                  >提到我的</span
                >
              </template>
              <!-- 提到我的 -->
              <comment
                :indexType="indexType"
                @hasNotReply="handleHasNotReply"
              ></comment>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="clearfix" v-show="!isSchedule">
          <div class="examineAndApprove flexJsbAc">
            <div>
              <el-link
                :underline="false"
                @click="changeIsApplyStatus('apply')"
                :class="{ timeSelect: isApply == 'apply' }"
                style="padding: 2px 0"
              >
                <el-badge :is-dot="applicationResults" class="title">
                  我的申请
                </el-badge>
              </el-link>
              <el-divider direction="vertical"></el-divider>
              <el-link
                :underline="false"
                @click="changeIsApplyStatus('pending')"
                :class="{ timeSelect: isApply == 'pending' }"
                style="padding: 2px 0"
              >
                <el-badge :is-dot="pending" class="title"> 待我处理 </el-badge>
              </el-link>
              <el-divider direction="vertical"></el-divider>
               <el-link
                :underline="false"
                @click="changeIsApplyStatus('processed')"
                :class="{ timeSelect: isApply == 'processed' }"
                style="padding: 2px 0"
              >
                <el-badge  class="title"> 已处理 </el-badge>
              </el-link>
            </div>

            <div>
              <el-button
                v-if="userInfo && !userInfo.dingTalkUnionId"
                style="margin-left: 30px"
                size="mini"
                @click="goBindDingTalk"
                >去绑定钉钉</el-button
              >

              <span
                v-if="userInfo && userInfo.dingTalkUnionId"
                style="margin: 0 0 10px 30px; font-size: 14px; color: #666"
                >钉钉名: {{ userInfo.dingTalkDisplayName || "" }}</span
              >
            </div>
          </div>
          <examineApprove
            v-if="hasApprove"
            :isApply="isApply"
            :hasApprove="hasApprove"
            :oaTodoNum="oaTodoNum"
          ></examineApprove>
        </div>
      </el-card>
      <!-- <el-card
        class="box-card"
        :class="{
          'box-card-approve': !hasApprove,
          'box-card-approve2': hasApprove,
        }"
      >
        <div slot="header" class="clearfix">
          <div class="flexJfAc" style="float: left; padding: 3px 0">
            <el-badge :is-dot="false" class="item">
              <span class="headerTitle">申请与审批</span>
            </el-badge>
            <div class="time">
              <el-link
                :underline="false"
                @click="changeIsApplyStatus(true)"
                :class="{ timeSelect: isApply }"
                style="padding: 2px 0"
              >
                <el-badge :is-dot="applicationResults" class="item">
                  我的申请
                </el-badge>
              </el-link>
              <el-divider direction="vertical"></el-divider>
              <el-link
                :underline="false"
                @click="changeIsApplyStatus(false)"
                :class="{ timeSelect: !isApply }"
                style="padding: 2px 0"
              >
                <el-badge :is-dot="pending" class="item"> 待我处理 </el-badge>
              </el-link>

              <el-button
                v-if="userInfo && !userInfo.dingTalkUnionId"
                style="margin-left: 30px"
                size="mini"
                @click="goBindDingTalk"
                >去绑定钉钉</el-button
              >

              <span
                v-if="userInfo && userInfo.dingTalkUnionId"
                style="margin-left: 30px; font-size: 14px; color: #666"
                >钉钉名: {{ userInfo.dingTalkDisplayName || "" }}</span
              >
            </div>
          </div>
          <div
            @click="changeApproveStatus"
            class="hasApprove"
            style="float: right; padding: 3px 0"
          >
            <i
              :class="{
                'el-icon-arrow-down': !hasApprove,
                'el-icon-arrow-up': hasApprove,
              }"
              class="approveIcon"
            ></i>
          </div>
        </div>
        <examineApprove
          v-if="hasApprove"
          :isApply="isApply"
          :hasApprove="hasApprove"
          :oaTodoNum="oaTodoNum"
        ></examineApprove>
      </el-card> -->
      <!-- 项目概览 -->
      <el-card class="box-card projectOverview">
        <div slot="header" class="clearfix flexJfAc">
          <span class="headerTitle">任务概览</span>
          <div class="time">
            <el-link
              :underline="false"
              @click="projectOverviewAll('-7')"
              :class="{ timeSelect: timeSelect }"
              style="padding: 2px 0"
              >本周新增</el-link
            >
            <el-divider direction="vertical"></el-divider>
            <el-link
              :underline="false"
              @click="projectOverviewAll('all')"
              :class="{ timeSelect: !timeSelect }"
              style="padding: 2px 0"
              >全部</el-link
            >
          </div>
        </div>
        <projectOverview
          :projectOverviewTime="projectOverviewTime"
        ></projectOverview>
      </el-card>
    </el-col>
    <el-col class="right">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span
            class="headerTitle"
            :class="{ timeSelect: dynamic == 9 }"
            @click="changeDynamic(9)"
            >系统动态</span
          >
          <el-divider direction="vertical"></el-divider>
          <span
            class="headerTitle"
            :class="{ timeSelect: dynamic == 7 }"
            @click="changeDynamic(7)"
            >业务进展动态</span
          >
          <el-divider direction="vertical"></el-divider>
          <span
            class="headerTitle"
            :class="{ timeSelect: dynamic == 8 }"
            @click="changeDynamic(8)"
            >任务进展动态</span
          >
        </div>
        <latestNews :dynamic="dynamic"></latestNews>
      </el-card>

      <!-- <el-card class="box-card">
        <div slot="header" class="clearfix flexJfAc">
          <span class="headerTitle">项目统计</span>
          <div class="time">
            <el-link
              :underline="false"
              @click="projectStatisticsAll('-7')"
              :class="{ timeSelect: projectStatisticsSelect }"
              style="padding: 2px 0"
              >本周新增</el-link
            >
            <el-divider direction="vertical"></el-divider>
            <el-link
              :underline="false"
              @click="projectStatisticsAll('all')"
              :class="{ timeSelect: !projectStatisticsSelect }"
              style="padding: 2px 0"
              >全部</el-link
            >
          </div>
        </div>
        <projectStatistics
          :projectStatisticsTime="projectStatisticsTime"
        ></projectStatistics>
      </el-card> -->
      <!-- <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span class="headerTitle">工作进展</span>
        </div>
        <workProgress></workProgress>
      </el-card> -->
      <el-card class="box-card">
        <div slot="header" class="clearfix flexJfAc">
          <span class="headerTitle">我的偏好</span>
          <div class="time">
            <el-link
              :underline="false"
              @click="userPreferencesStatusChange(1)"
              :class="{ timeSelect: userPreferencesStatus == 1 }"
              style="padding: 2px 0"
              >日历</el-link
            >
            <el-divider direction="vertical"></el-divider>
            <el-link
              :underline="false"
              @click="userPreferencesStatusChange(2)"
              :class="{ timeSelect: userPreferencesStatus == 2 }"
              style="padding: 2px 0"
              >我的任务</el-link
            >
            <el-divider direction="vertical"></el-divider>
            <el-link
              :underline="false"
              @click="userPreferencesStatusChange(3)"
              :class="{ timeSelect: userPreferencesStatus == 3 }"
              style="padding: 2px 0"
              >任务管理</el-link
            >
          </div>
        </div>
        <userPreferences
          :userPreferencesStatus="userPreferencesStatus"
        ></userPreferences>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import calendar from "@/components/home/calendar";
import needDealt from "@/components/home/needDealt";
import latestNews from "@/components/home/latestNews";
import projectOverview from "@/components/home/projectOverview";
import projectStatistics from "@/components/home/projectStatistics";
import workProgress from "@/components/home/workProgress";
import newSchedule from "@/components/home/newSchedule";
import comment from "@/components/home/comment";
import userPreferences from "@/components/home/userPreferences";
import examineApprove from "@/components/home/examineApprove";
import { formatDate } from "@/common/date";
import { getHomeTodoNum } from "@/api/officeAutomation";
import { getUserInfo } from "@/api/user";

export default {
  name: "home",
  data() {
    return {
      activeName: "first",
      todoList: [], //待办事项
      scheduleId: null,
      timeSelect: true,
      projectOverviewTime: "-7",
      projectStatisticsSelect: true,
      projectStatisticsTime: "-7",
      indexType: null,
      hasNotReplyComment: false,
      userPreferencesStatus: 1, //1日历，2我的任务，3任务管理
      isApply: 'apply', // 申请:true 处理:false
      hasApprove: false, //是否显示审批模块
      applicationResults: false, //是否有申请结果
      pending: false, //是否有需要处理的
      oaTodoNum: null,
      userInfo: null,
      isSchedule: true,
      dynamic: 9, // 9:系统动态，7:业务进展动态，8:任务进展动态
    };
  },
  components: {
    calendar,
    needDealt,
    latestNews,
    projectOverview,
    projectStatistics,
    workProgress,
    newSchedule,
    comment,
    userPreferences,
    examineApprove,
  },

  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getUserInfo();
  },
  activated() {},
  methods: {
    changeDynamic(status) {
      this.dynamic = status;
    },
    openSchedule() {
      this.isSchedule = true;
    },
    openExamineAndApprove() {
      this.isSchedule = false;
    },
    getUserInfo() {
      getUserInfo().then((res) => {
        if (res.success) {
          this.userInfo = res.data;
          if (this.userInfo.dingTalkUnionId) {
            this.getHomeTodoNum()
          }
        }
      });
    },
    getHomeTodoNum() {
      getHomeTodoNum().then((res) => {
        if (res.success) {
          let data = res.data;

          if (data.processTodoNum > 0 || data.dataAuthTodoNum > 0) {
            this.oaTodoNum = data;
            this.hasApprove = true;
            this.pending = true;
            this.isApply = 'pending';
          }else if (data.oaNoticeNum > 0 || data.dataAuthApplyingNum > 0) {
            this.oaTodoNum = data;
            this.hasApprove = true;
            this.applicationResults = true;
            this.isApply = 'apply';
          }else {
            this.oaTodoNum = null;
            // this.isApply = '';
            this.hasApprove = true; 
          }
        }
      });
    },
    changeIsApplyStatus(status) {
      console.log(status);
      if (status == 'apply') {
        this.applicationResults = false;
      } else if (status == 'pending'){
        this.pending = false;
      }else  if (status == 'processed'){

      }
      this.isApply = status;
    },
    changeApproveStatus() {
      this.hasApprove = !this.hasApprove;
    },
    userPreferencesStatusChange(data) {
      this.userPreferencesStatus = data;
    },
    jumpMyMission() {
      this.$router.push("/myMission");
    },
    projectOverviewAll(num) {
      // console.log(num);
      if (num == "all") {
        this.timeSelect = false;
      } else if (num == "-7") {
        this.timeSelect = true;
      }
      this.projectOverviewTime = num;
    },
    projectStatisticsAll(num) {
      if (num == "all") {
        this.projectStatisticsSelect = false;
      } else if (num == "-7") {
        this.projectStatisticsSelect = true;
      }
      this.projectStatisticsTime = num;
    },
    listenDetailsClose(params) {
      console.log(params);
      const { type, isClose, info } = params;
      if (type == "sure") {
        this.scheduleId = info.businessId;
        this.activeName = "second";
        this.indexType = "1";
      }
    },
    handleClick(tab, event) {
      console.log(tab.index, event);
      let index = tab.index;
      this.indexType = index;
    },
    handleHasNotReply(val) {
      console.log("hasNotReplyComment", val);
      this.hasNotReplyComment = val;
    },
    goBindDingTalk() {
      this.$router.push({
        path: "/personalCenter",
        query: {
          type: "bindDingTalk",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  .left {
    width: 69%;
    text-align: left;
    .needDealt {
      .cardHeader {
        display: flex;
        justify-content: flex-start;
        // align-items: baseline;
      }
    }
    .projectOverview {
      margin-top: 30px;
    }
    .headerTitleLink:hover {
      color: #409eff;
      cursor: pointer;
    }
  }
  .right {
    width: 29%;
    text-align: left;
    .el-card {
      // margin-bottom: 10px;
    }
  }
  .box-card {
    margin-bottom: 20px;
  }
  .box-card-approve {
    /deep/ .el-card__body {
      padding: 0px !important;
    }
  }
  .box-card-approve2 {
    /deep/ .el-card__body {
      padding: 15px !important;
    }
  }
  .headerTitle {
    font-weight: bold;
    cursor: pointer;
    font-size: 16px;
  }
  .timeSelect {
    color: #409eff;
  }
}
.clearfix {
  width: 100% !important;
  .time {
    margin-left: 20px;
  }
  .approveIcon {
    font-size: 26px;
    color: #409eff;
  }
  .examineAndApprove {
    .title {
      font-size: 15px;
    }
  }
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.is-selected {
  color: #f8a535;
  font-size: 16px;
  margin-top: 5px;
}
.tip {
  color: red;
  font-size: 18px;
  text-align: left;
  margin-left: 15px;
}
.thingsTitle {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  font-size: 12px;
}
.new-badge {
  position: relative;

  &::after {
    position: absolute;
    content: "新";
    top: -10px;
    right: -18px;
    background-color: #f56c6c;
    line-height: 16px;
    border-radius: 100%;
    padding: 1px 3px;
    color: white;
    font-size: 12px;
  }
}
</style>
