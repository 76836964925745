<!--
 * @Description: 
 * @Author: transparent
 * @Date: 2021-12-14 15:51:50
 * @LastEditTime: 2022-01-28 14:07:54
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/components/home/examineApprove.vue
-->
<template>
  <el-main class="" v-if="hasApprove">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane name="first" v-if="shawOA">
        <span slot="label" class="badgeLable" v-show="shawOA">
          <el-badge
            v-if="oaTodoNum"
            :value="
              isApply == 'apply'
                ? oaTodoNum.oaNoticeNum || ''
                : isApply == 'pending'
                ? oaTodoNum.processTodoNum || ''
                : ''
            "
            :hidden="
              oaTodoNum.oaNoticeNum == 0 && oaTodoNum.processTodoNum == 0
            "
            class="item"
          >
            OA
          </el-badge>
          <span v-else v-show="shawOA">OA</span>
        </span>
        <!-- oa -->
        <div class="s" v-if="activeName == 'first'">
          <oATable
            :tabIndex="
              isApply == 'apply' ? '2' : isApply == 'pending' ? '0' : '1'
            "
            :status="
              isApply == 'apply' ? '-1' : isApply == 'pending' ? '0' : '1'
            "
          ></oATable>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="数据申请审批"
        name="second"
        v-permission="['data_auth:approve']"
      >
        <span
          slot="label"
          class="badgeLable"
          v-permission="['data_auth:approve']"
        >
          <el-badge
            v-if="oaTodoNum"
            :value="
              isApply == 'apply'
                ? oaTodoNum.dataAuthApplyingNum
                : isApply == 'pending'
                ? oaTodoNum.dataAuthTodoNum || ''
                : ''
            "
            :hidden="
              oaTodoNum.dataAuthApplyingNum == 0 &&
              oaTodoNum.dataAuthTodoNum == 0
            "
            class="item"
          >
            数据权限
          </el-badge>
          <span v-else v-permission="['data_auth:approve']">数据权限</span>
        </span>
        <div class="" v-if="activeName == 'second' && isApply == 'apply'">
          <dataApplication></dataApplication>
        </div>

        <div class="" v-if="activeName == 'second' && isApply == 'pending'">
          <dataAuthApprove></dataAuthApprove>
        </div>
        <div class="" v-if="activeName == 'second' && isApply == 'processed'">
          <dataAuthApprove></dataAuthApprove>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-main>
</template>

<script>
import { formatDate } from "@/common/date";
import { getoaProcessTodoNum } from "@/api/officeAutomation";
import oATable from "@/components/officeAutomation/OATable";
import dataAuthApprove from "@/components/dataAuth/dataAuthApprove";
import dataApplication from "@/components/officeAutomation/dataApplication";
export default {
  name: "examineApprove",
  data() {
    return {
      activeName: "first",
      oaApplyTodoNum: null, //oa已申请
      oaProcessTodoNum: null, //oa待处理
      dataApplicationApplyNum: null, //数据申请已申请数量
      dataApplicationProcessNum: null, //数据申请待处理数量
      userInfo: null,
      shawOA: false,
    };
  },
  props: {
    isApply: String, // 申请:apply 处理:pending，已处理 processed
    hasApprove: Boolean,
    oaTodoNum: Object,
  },
  components: {
    oATable,
    dataAuthApprove,
    dataApplication,
  },
  computed: {},
  watch: {
    "$store.state.permissionInfo": function () {
      console.log("this.$store.state.permissionInfo");
      console.log(this.$store.state.permissionInfo);
    },

    shawOA: {
      handler(newVal, oldVal) {
        let that = this;
        console.log(newVal)
        if (newVal == true) {
          that.activeName = "first";
          that.$forceUpdate()
        } else {
          that.activeName = "second";
          that.$forceUpdate()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {},
  mounted() {
    this.userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    // this.getoaProcessTodoNum();
    this.changeStatus();
    this.getPermissionInfo();
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "MM-dd hh:mm");
    },
  },
  activated() {},
  methods: {
    getPermissionInfo() {
      let that = this;
      if (this.$store.state.permissionInfo.menus.length > 0) {
        let menuList = this.$store.state.permissionInfo.menus;
        menuList.forEach((item, index) => {
          if (item.routeAddress == "/officeAutomation") {
            console.log(item.routeAddress);
            that.shawOA = true;
            that.activeName = "first";
            // return false;
          }
        });
      }
    },
    changeStatus() {
      console.log(this.oaTodoNum);
      if (this.oaTodoNum) {
        if (
          this.oaTodoNum.processTodoNum > 0 ||
          this.oaTodoNum.oaNoticeNum > 0
        ) {
          this.activeName = "first";
        } else if (
          this.oaTodoNum.dataAuthTodoNum > 0 ||
          this.oaTodoNum.dataAuthApplyingNum > 0
        ) {
          this.activeName = "second";
        }
        this.$forceUpdate();
      } else {
        // this.activeName = "first";
        this.$forceUpdate();
      }
    },
    getoaProcessTodoNum() {
      getoaProcessTodoNum(this.userInfo.uid).then((res) => {
        if (res.success) {
          let data = res.data.todoNum;
          if (data > 0) {
            this.oaProcessTodoNum = data;
          }
        }
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-main {
  max-height: 30rem;
  overflow-y: auto;
  overflow-x: hidden;
  /deep/ .el-tabs__item {
    margin-top: 18px;
  }
  .item {
    color: #909399;
    font-size: 13px;
  }
}
</style>
