var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{staticClass:"scrollbar"},[(_vm.overviewList.length > 0)?_c('div',{},[_c('div',{staticClass:"flexJA tips"},[_c('span',[_vm._v(" 小提示： ")]),_c('span',[_vm._v(" 流程节点上的数字为 ")]),_c('el-tag',{attrs:{"type":"danger","effect":"plain"}},[_vm._v("进行中")]),_vm._v(" 、 "),_c('el-tag',{attrs:{"type":"","effect":"plain"}},[_vm._v("待启动")]),_vm._v(" 、 "),_c('el-tag',{attrs:{"type":"success","effect":"plain"}},[_vm._v("已完成")]),_c('span',[_vm._v(" 任务的数量。")])],1),_vm._l((_vm.overviewList),function(item,index){return _c('div',{key:index,staticClass:"text item"},[_c('div',{staticClass:"title"},[_vm._v("流程"+_vm._s(index + 1)+"-"+_vm._s(item.name))]),_c('div',{staticClass:"nodeList flexJfAc scrollbarX"},_vm._l((item.nodeList),function(node,nodeIndex){return _c('div',{key:nodeIndex,staticClass:"nodeOne flexJA"},[_c('el-button',{staticClass:"flexJfAc",attrs:{"slot":"reference","size":"small","round":""},on:{"click":function($event){return _vm.projectOverviewJump(item, node)}},slot:"reference"},[_vm._v(_vm._s(node.nodeName)+" ( "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.displayStatusList.indexOf(5) != '-1' ||
                _vm.displayStatusList.length == 0
              ),expression:"\n                displayStatusList.indexOf(5) != '-1' ||\n                displayStatusList.length == 0\n              "}],staticClass:"runningProjectNum"},[_c('el-tag',{staticClass:"tagW",attrs:{"type":"danger","effect":"plain"}},[_vm._v(_vm._s(node.taskQuantityMap.runningTaskQuantity))])],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.displayStatusList.indexOf(4) != '-1' ||
                _vm.displayStatusList.length == 0
              ),expression:"\n                displayStatusList.indexOf(4) != '-1' ||\n                displayStatusList.length == 0\n              "}],staticClass:"notStartedProjectNum"},[_c('el-tag',{staticClass:"tagW",attrs:{"type":"","effect":"plain"}},[_vm._v(_vm._s(node.taskQuantityMap.notStartedTaskQuantity))])],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.displayStatusList.indexOf(6) != '-1' ||
                _vm.displayStatusList.length == 0
              ),expression:"\n                displayStatusList.indexOf(6) != '-1' ||\n                displayStatusList.length == 0\n              "}],staticClass:"finishedTaskNum"},[_c('el-tag',{staticClass:"tagW",attrs:{"type":"success","effect":"plain"}},[_vm._v(_vm._s(node.taskQuantityMap.finishedTaskQuantity))])],1),_vm._v(" )")])],1)}),0)])})],2):_c('div',{},[_c('el-empty',{attrs:{"image-size":100,"description":"暂无数据"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }