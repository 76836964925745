var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasApprove)?_c('el-main',{},[_c('el-tabs',{on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[(_vm.shawOA)?_c('el-tab-pane',{attrs:{"name":"first"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.shawOA),expression:"shawOA"}],staticClass:"badgeLable",attrs:{"slot":"label"},slot:"label"},[(_vm.oaTodoNum)?_c('el-badge',{staticClass:"item",attrs:{"value":_vm.isApply == 'apply'
              ? _vm.oaTodoNum.oaNoticeNum || ''
              : _vm.isApply == 'pending'
              ? _vm.oaTodoNum.processTodoNum || ''
              : '',"hidden":_vm.oaTodoNum.oaNoticeNum == 0 && _vm.oaTodoNum.processTodoNum == 0}},[_vm._v(" OA ")]):_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.shawOA),expression:"shawOA"}]},[_vm._v("OA")])],1),(_vm.activeName == 'first')?_c('div',{staticClass:"s"},[_c('oATable',{attrs:{"tabIndex":_vm.isApply == 'apply' ? '2' : _vm.isApply == 'pending' ? '0' : '1',"status":_vm.isApply == 'apply' ? '-1' : _vm.isApply == 'pending' ? '0' : '1'}})],1):_vm._e()]):_vm._e(),_c('el-tab-pane',{directives:[{name:"permission",rawName:"v-permission",value:(['data_auth:approve']),expression:"['data_auth:approve']"}],attrs:{"label":"数据申请审批","name":"second"}},[_c('span',{directives:[{name:"permission",rawName:"v-permission",value:(['data_auth:approve']),expression:"['data_auth:approve']"}],staticClass:"badgeLable",attrs:{"slot":"label"},slot:"label"},[(_vm.oaTodoNum)?_c('el-badge',{staticClass:"item",attrs:{"value":_vm.isApply == 'apply'
              ? _vm.oaTodoNum.dataAuthApplyingNum
              : _vm.isApply == 'pending'
              ? _vm.oaTodoNum.dataAuthTodoNum || ''
              : '',"hidden":_vm.oaTodoNum.dataAuthApplyingNum == 0 &&
            _vm.oaTodoNum.dataAuthTodoNum == 0}},[_vm._v(" 数据权限 ")]):_c('span',{directives:[{name:"permission",rawName:"v-permission",value:(['data_auth:approve']),expression:"['data_auth:approve']"}]},[_vm._v("数据权限")])],1),(_vm.activeName == 'second' && _vm.isApply == 'apply')?_c('div',{},[_c('dataApplication')],1):_vm._e(),(_vm.activeName == 'second' && _vm.isApply == 'pending')?_c('div',{},[_c('dataAuthApprove')],1):_vm._e(),(_vm.activeName == 'second' && _vm.isApply == 'processed')?_c('div',{},[_c('dataAuthApprove')],1):_vm._e()])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }