<template>
  <el-dialog
    class="edit"
    :title="title"
    :visible="addTaskEditDalogVisible"
    center
    @close="dialogClose"
    width="900px"
    :close-on-click-modal="false"
    :destroy-on-close="false"
    append-to-body
  >
    <div class="bar" v-if="formData != null">
      <li
        class="width50"
        v-if="
          type != 'update' &&
          (type == 'standard' || (type == 'edit' && formData.templateId != ''))
        "
      >
        <span>任务模板：</span>
        <el-select
          v-model="formData.templateId"
          class="childWidth"
          filterable
          placeholder="请选择任务模板"
          @visible-change="getTaskTemplateList($event)"
          @change="choosePorject($event)"
          clearable
          :disabled="type == 'edit'"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in tskTemplateListOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            <!-- {{ item.projectName }}
            <span style="color: #8492a6; font-size: 12px"
              >投资机构编号:{{ item.id }}</span
            > -->
          </el-option>
        </el-select>
      </li>
      <li
        class="width50"
        v-if="
          type != 'update' &&
          (type == 'other' ||
            type == 'other2' ||
            (type == 'edit' && formData.templateId == ''))
        "
      >
        <span>任务名称：</span>
        <el-input
          v-model="formData.name"
          class="childWidth"
          placeholder="任务名称"
          :disabled="type == 'edit'"
        ></el-input>
      </li>
      <li
        class="width50"
        v-if="
          type != 'update' &&
          (type == 'other' ||
            type == 'other2' ||
            (type == 'edit' && formData.templateId == ''))
        "
      >
        <span>任务描述：</span>
        <el-input
          v-model="formData.description"
          class="childWidth"
          placeholder="任务描述"
        ></el-input>
      </li>
      <li class="width50" v-if="type != 'update'">
        <span>负责人：</span>
        <el-select
          v-model="formData.ownerId"
          @visible-change="projectTeam($event)"
          filterable
          placeholder="请选择"
          clearable
          class="childWidth"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in internalUseroptionsList"
            :key="item.uid"
            :label="item.realName"
            :value="item.uid"
          >
          </el-option>
        </el-select>
      </li>

      <li class="width50">
        <span>计划开始时间：</span>
        <el-date-picker
          v-model="formData.planBeginTime"
          type="datetime"
          value-format="timestamp"
          placeholder="选择日期时间"
          align="right"
          :picker-options="pickerOptions"
          class="childWidth"
        >
        </el-date-picker>
      </li>
      <li class="width50">
        <span>计划结束时间：</span>
        <el-date-picker
          v-model="formData.planEndTime"
          type="datetime"
          value-format="timestamp"
          placeholder="选择日期时间"
          align="right"
          :picker-options="pickerOptions"
          class="childWidth"
        >
        </el-date-picker>
      </li>

      <li class="width50">
        <span>任务进度：</span>
        <el-input
          v-model="formData.progress"
          class="childWidth"
          placeholder="直接填写数字（百分比：%）"
        ></el-input>
      </li>
      <li class="width50" v-if="type != 'other2'">
        <span>关联日程：</span>
        <el-select
          v-model="formData.scheduleIdList"
          @visible-change="getCalendarScheduleSelectList($event)"
          filterable
          placeholder="请选择"
          clearable
          multiple
          class="childWidth"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in calendarScheduleSelectList"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </li>
      <li class="width50">
        <span>备注：</span>
        <el-input
          v-model="formData.remark"
          class="childWidth"
          placeholder="备注"
        ></el-input>
      </li>
    </div>

    <div class="dialog-footer">
      <el-button @click="dialogClose">取消</el-button>
      <el-button type="primary" @click="sure">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
// import Vue from "vue";
//1

import {
  getTaskTemplateList,
  addTask,
  editTask,
  findDetailTask,
} from "@/api/myMission";
import { getCalendarScheduleSelectList } from "@/api/calendar.js";
import { projectTeam } from "@/api/projectManage";
import { formatDate } from "@/common/date";
export default {
  name: "addTaskEdit",
  data() {
    return {
      title: "新增",
      token: null,
      formData: {
        businessType: 1,
        businessId: null,
      },
      searchPorjectListLoading: false,
      tskTemplateListOptions: null,
      workflowListOptions: null,
      investorwListOptions: null,
      internalUseroptionsList: null,
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      pageNum: 1,
      pageSize: 10,
      total: null,
      pageCount: null,
      loadingTable: false,
      statusList: [
        { key: "未开始", value: 0 },
        { key: "进行中", value: 1 },
        { key: "已完成", value: 2 },
      ],
      calendarScheduleSelectList: [],
      // taskOneInfo: {},
    };
  },
  props: {
    type: String,
    addTaskEditDalogVisible: Boolean,
    id: String,
    businessType: Number,
    info: Object,
    taskOneInfo: Object,
  },
  components: {},
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  created() {
    if (this.type == "standard") {
      this.title = "添加标准任务";
      this.formData.businessId = this.info.id;
      this.projectTeam(true);
      this.formData.ownerId = window.localStorage.getItem("userId");
    } else if (this.type == "other") {
      this.title = "添加其他任务";
      this.formData.businessId = this.info.id || this.taskOneInfo.businessId;
      this.formData.ownerId = window.localStorage.getItem("userId");
      this.projectTeam(true);
    } else if (this.type == "other2") {
      //首页的新增日程里添加任务
      this.title = "添加其他任务";
      this.formData.businessId = null;
      this.formData.businessType = "-1";
      this.formData.ownerId = window.localStorage.getItem("userId");
      this.projectTeam(true);
    } else if (this.type == "edit") {
      this.title = "编辑" + "——" + this.info.name;
      this.formData.businessId = this.info.businessId;
      this.findDetailTask(this.info.id);
    } else if (this.type == "update") {
      console.log(this.taskOneInfo);
      // console.log(this.info)

      this.title = "更新" + "——" + this.taskOneInfo.name;
      //   this.getTaskTemplateList(true);
      //   this.projectTeam(true);
      this.formData.id = this.taskOneInfo.id;
      console.log(this.formData);
    }
  },
  methods: {
    findDetailTask(id) {
      let info = { id: id };
      findDetailTask(info).then((response) => {
        if (response.success) {
          this.getTaskTemplateList(true);
          this.projectTeam(true);
          this.getCalendarScheduleSelectList(true);
          this.formData = { ...response.data };
        }
      });
    },
    getCalendarScheduleSelectList(cb) {
      if (cb) {
        let info = { mode: 2 };
        getCalendarScheduleSelectList(info).then((response) => {
          if (response.success) {
            let list = response.data;

            list.map(function (item, index) {
              item.title =
                item.title +
                " " +
                formatDate(new Date(item.beginTime), "yyyy-MM-dd");
            });
            this.calendarScheduleSelectList = list;
          }
        });
      }
    },
    //
    projectTeam(cb) {
      if (cb) {
        projectTeam({}).then((response) => {
          if (response.success) {
            this.internalUseroptionsList = response.data;
          }
        });
      }
    },
    //
    choosePorject(id) {
      var that = this;
      console.log(id);
      //   that.tskTemplateListOptions.forEach(function (item, index) {
      //     if (id == item.id) {
      //       that.formData.templateId = item.id;
      //       that.formData.projectName = item.projectName;
      //     }
      //   });
    },
    //
    getTaskTemplateList(cb) {
      console.log(cb);
      let one = {};
      one.businessType = "1";
      one.businessId = this.info.nodeId;
      if (cb) {
        getTaskTemplateList(one).then((response) => {
          if (response.success) {
            this.tskTemplateListOptions = response.data;
          }
        });
      }
      //   if (query.length > 0 ) { //&& !this.searchPorjectListLoading
      //     this.searchPorjectListLoading = true;

      //     this.searchPorjectListLoading = false;
      //   } else {
      //     this.tskTemplateListOptions = [];
      //   }
    },

    //弹窗关闭
    dialogClose() {
      this.formData = {};
      this.$emit("listenAddTaskEditClose", { type: "cancel", isClose: true });
    },

    add() {
      addTask(this.formData).then((response) => {
        if (response.success) {
          console.log(response.data);
          this.$message({ type: "success", message: "添加成功!" });
          this.formData = {};
          this.$emit("listenAddTaskEditClose", {
            type: "sure",
            isClose: true,
            info: response.data,
          });
        }
      });
    },
    edit() {
      editTask(this.formData).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "编辑成功!" });
          this.formData = {};
          this.$emit("listenAddTaskEditClose", { type: "sure", isClose: true });
        }
      });
    },
    //确定
    sure() {
      this.formData = { ...this.formData };
      console.log(this.formData);
      if (this.type == "edit" || this.type == "update") {
        this.edit();
      } else {
        this.add();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bigTitle {
  font-size: 20px;
  margin-bottom: 15px;
}
.bar {
  overflow: hidden;
  padding: 10px;
  li {
    min-height: 50px;
    line-height: 50px;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5px 0;
    span {
      display: inline-block;
      width: 120px;
      text-align: right;
    }
    .childWidth {
      width: calc(100% - 150px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100%;
    p {
      width: calc(100% - 120px);
      float: right;
      span {
        display: inline;
        padding: 0 10px;
      }
    }
  }
}
.buttonGrop {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  button {
    margin-right: 100px;
  }
}
.el-icon-iconName {
  margin: 0 5px;
}
</style>