<!--
 * @Description:
 * @Author: transparent
 * @Date: 2021-11-15 16:25:38
 * @LastEditTime: 2022-08-22 16:20:56
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/components/home/comment.vue
-->

<template>
  <el-main class="scrollbar">
    <el-row v-if="commentsNotice.length > 0">
      <el-col v-for="(item, index) in commentsNotice" :key="index">
        <!-- <div class="flexJfAc itemOne" v-if="item.type == 'comment_notice'">
          <div class="time">
            <span class="fromUserName">{{ item.fromUserName }}</span>
            <span> {{ item.commentTime | formatDateFilter }} </span>

            <span class=""
              >评论了你的{{ item.subjectType | subjectType }} ({{
                item.subjectName
              }})</span
            >：
            <span
              class="content"
              :class="item.hasReply === 1 ? 'hasReply' : ''"
              @click="quickReplyComment(item)"
              >{{ item.content }}</span
            >
          </div>
        </div> -->
        <div class="flexJfAc itemOne" v-if="item.type">
          <div class="time" @click="operationQeply(item)">
            <!-- <span class="fromUserName">{{ item.fromUserName }}</span> -->
            <span> {{ item.createDate | formatDateFilter }} </span>
            <span :class="(item.content == null && item.isNoticed === 0) ? 'content' :(item.content == null && item.isNoticed === 1) ? 'hasReply':''">
              {{ item.title }}
            </span>
            <span
              v-if="item.content"
              class="content"
              :class="item.isNoticed === 1 ? 'hasReply' : ''"
              @click.stop="operationQeply(item)"
              >: {{ item.content }}</span
            >
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row v-else>
      <el-empty :image-size="50" description="暂无数据"></el-empty>
    </el-row>
    <el-dialog
      title="回复"
      :show-overflow-tooltip="true"
      :close-on-click-modal="false"
      :destroy-on-close="false"
      :visible.sync="dialogFormVisible"
    >
      <transition name="fade">
        <div class="input-wrapper">
          <el-input
            class="gray-bg-input"
            v-model="inputComment"
            type="textarea"
            :rows="3"
            autofocus
            placeholder="写下你的评论"
          >
          </el-input>
          <div class="quote flexJfAc" v-if="quickReplyInfo">
            <span class="fromUserName">{{ quickReplyInfo.fromUserName }}</span>
            <span>
              {{ quickReplyInfo.commentTime | formatDateFilter }}
            </span>

            <span class=""
              >评论了你的{{ quickReplyInfo.subjectType | subjectType }} ({{
                quickReplyInfo.subjectName
              }})</span
            >：
            <span class="content">{{ quickReplyInfo.content }}</span>
          </div>

          <div class="btn-control">
            <a
              class="notice"
              v-if="quickReplyInfo && quickReplyInfo.isNoticed !== 1"
              @click="notNoticeAnyMore('comment')"
              >不再提醒</a
            >
            <span class="cancel" @click="dialogFormVisible = false">取消</span>
            <el-button class="btn" type="success" round @click="commitComment"
              >确定</el-button
            >
          </div>
        </div>
      </transition>
    </el-dialog>
  </el-main>
</template>

<script>
import {
  getCommentsNotice,
  addComments,
  setCommentNoticed,
  getUserNoticeList,
  updateNoticed,
} from "@/api/comment";
import { formatDate } from "@/common/date";
import eventBus from "@/common/eventBus";

export default {
  name: "comment",
  data() {
    return {
      commentsNotice: [],
      form: {
        content: null,
        replyCommentId: null,
        quoteCommentId: null,
      },
      dialogFormVisible: false,
      inputComment: null,
      quickReplyInfo: null,
      hasNotReply: false,
      quickReplyInfoAll: null,
    };
  },
  components: {},
  computed: {},
  props: {
    indexType: String,
  },
  watch: {
    indexType(newVal, old) {
      console.log("newVal=== " + newVal);
      if (newVal == 2) {
        this.getUserNoticeList();
      }
    },
  },
  created() {},
  mounted() {
    this.getUserNoticeList();
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "MM-dd hh:mm");
    },
    subjectType(value) {
      switch (value) {
        case "MEETING_DETAIL":
          return "会议纪要";
        case "PROJECT_DETAIL":
          return "项目";
        case "EVALUATION_DETAIL":
          return "评估";
        case "BUSINESS_PROCESS":
          return "任务进度表";
        case "TASK_DETAIL":
          return "任务";
      }
    },
  },
  activated() {
    this.getUserNoticeList();
  },
  methods: {
    operationQeply(item) {
      console.log(item);
      if (item.type == "expense_financial_notice") {
        this.notNoticeAnyMore("expense", item);
        //费用报销财务通知相关，
        eventBus.$emit("expenseUserNotice", {
          id: item.targetId,
          detailInfo: item.detailInfo,
        });
        this.jumpCostManagementSelfConfirmation(item);
      } else if (item.type == "ding_talk_process_instance") {
        //钉钉审核相关
        this.notNoticeAnyMore("expense", item);
      } else if (item.type == "comment_notice") {
        //评论通知相关
        // this.notNoticeAnyMore('comment',item.id)
        this.quickReplyComment(item);
      } else if (item.type == "expense_user_notice") {
        this.notNoticeAnyMore("expense", item);
        //
        eventBus.$emit("expenseUserNotice", {
          id: item.targetId,
          detailInfo: item.detailInfo,
        });
        this.jumpCostManagementSelfConfirmation(item);
      }
      //
    },

    jumpCostManagementSelfConfirmation(item) {
      this.$router.push("/costManagementSelfConfirmation");
    },

    commitComment() {
      if (!this.inputComment) {
        this.$message({ type: "warning", message: "请填写评论内容!" });
        return false;
      }
      let info = {
        replyCommentId:
          this.form.replyCommentId != null
            ? this.form.replyCommentId
            : this.form.quoteCommentId,
        // quoteCommentId: this.form.replyCommentId !=  null ? this.form.quoteCommentId : null,
        content: this.inputComment,
      };

      addComments(info).then((res) => {
        if (res.success) {
          this.$message({ type: "success", message: "回复成功!" });
          this.notNoticeAnyMore("comment");
          this.getUserNoticeList();
          this.dialogFormVisible = false;
          this.inputComment = null;
          this.form.replyCommentId = null;
          this.form.quoteCommentId = null;
        }
      });
    },
    quickReplyComment(item) {
      console.log(item);
      this.quickReplyInfoAll = item;
      this.quickReplyInfo = item.detailInfo;
      this.form.replyCommentId = item.targetId;
      this.form.quoteCommentId = item.id;
      this.dialogFormVisible = true;
    },
    getUserNoticeList() {
      let info = {
        pageNum: 1,
        pageSize: 20,
      };
      getUserNoticeList(info).then((res) => {
        if (res.success) {
          if (res.data) {
            this.commentsNotice = res.data;
            this.hasNotReply = !!this.commentsNotice.find(
              (x) => x.isNoticed === 0
            );
            console.log(this.hasNotReply);
            this.$emit("hasNotReply", this.hasNotReply);
          }
        }
      });
    },
    notNoticeAnyMore(type, item) {
      console.log(type);
      let param = {
        id: type == "comment" ? this.quickReplyInfoAll.id : item.id,
      };
      updateNoticed(param).then((res) => {
        if (res.success) {
          this.dialogFormVisible = false;
          this.getUserNoticeList();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-main {
  // padding: 8px !important;
  height: 10rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.itemOne {
  .time {
    // align-self: baseline;
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    cursor: pointer;
    .content {
      color: #409eff;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;

      &.hasReply {
        color: #666;
      }
    }
    .content:hover {
      color: #66b1ff;
    }
    .fromUserName {
      font-weight: bold;
    }
    // .content{

    // }
  }
}
.fade-enter-active,
fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.input-wrapper {
  padding: 10px;
  .gray-bg-input,
  .el-input__inner {
    /*background-color: #67C23A;*/
  }
  .quote {
    width: 100%;
    background-color: #e1e1e1;
    padding: 1px 5px;
    width: max-content;
    border-radius: 2px;
    margin-top: 10px;
    span {
      display: block;
      color: #676767;
    }
  }
}
.btn-control {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px;
  .cancel {
    font-size: 16px;
    color: #606266;
    margin-right: 20px;
    cursor: pointer;
    &:hover {
      color: 333;
    }
  }
  .confirm {
    font-size: 16px;
  }
  .notice {
    position: absolute;
    display: block;
    left: 0;
    top: 22px;
    color: #409eff;

    &:hover {
      color: #66b1ff;
    }
  }
}

.badge {
  margin-top: 10px;
  // margin-right: 40px;
}
</style>

