<template>
  <el-main>
    <el-form
      :model="calendarForm"
      ref="inputInvestor"
      label-width="160px"
      :rules="rules"
    >
      <el-row>
        <el-col>
          <el-form-item label="标题" prop="title">
            <el-input type="text" v-model="calendarForm.title"></el-input>
          </el-form-item>
          <el-form-item label="日程类别" prop="officeMode">
            <el-select
              v-model="calendarForm.officeMode"
              @visible-change="selectListDict($event, 'office_mode', 2)"
              filterable
              placeholder="请选择"
              clearable
              no-data-text="加载中···"
              @change="onOfficeModeChange"
            >
              <el-option
                v-for="item in dictList2"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="事由类型" prop="type">
            <el-select
              v-model="calendarForm.type"
              filterable
              placeholder="请选择"
              clearable
              no-data-text="加载中···"
            >
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-show="typeTitle"
            :label="typeTitle + '事由'"
            prop="reason"
          >
            <el-input type="text" v-model="calendarForm.reason"></el-input>
          </el-form-item>

          <!--          <el-form-item label="相关人员" prop="userIdList">-->
          <!--            <el-select-->
          <!--              v-model="calendarForm.userIdList"-->
          <!--              filterable-->
          <!--              placeholder="请输入关键词从中搜索"-->
          <!--              @visible-change="projectTeam($event)"-->
          <!--              @change="chooseInvestor($event)"-->
          <!--              clearable-->
          <!--              class="childWidth"-->
          <!--              multiple-->
          <!--              no-data-text="加载中···"-->
          <!--            >-->
          <!--              <el-option-->
          <!--                v-for="item in internalUseroptionsList"-->
          <!--                :key="item.uid"-->
          <!--                :label="item.realName"-->
          <!--                :value="item.uid"-->
          <!--              >-->
          <!--              </el-option>-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->

          <el-form-item label="相关人员" prop="userIdList">
            <select-process-users
              ref="selectProcessUsers"
              v-model="calendarForm.userIdList"
              :allow-add="currOfficeMode.code !== 'ask_for_leave'"
              @change="onProcessUsersChange"
              :type="'add'"
            />
          </el-form-item>

          <!-- <el-form-item label="开始日期" prop="beginTime">
            <el-date-picker
              v-model="calendarForm.beginTime"
              type="datetime"
              placeholder="开始日期"
              value-format="timestamp"
              format="yyyy-MM-dd HH:mm"
              :picker-options="pickerStartOptionsend"
              align="right"
              default-time="9:00:00"
            >
              >
            </el-date-picker>
          </el-form-item> -->
          <el-form-item
            label="时间"
            prop="startAendTime"
            v-if="currOfficeMode.code !== 'business_trip'"
          >
            <el-date-picker
              v-model="calendarForm.startAendTime"
              type="datetimerange"
              placeholder="结束日期"
              value-format="timestamp"
              format="yyyy-MM-dd HH:mm"
              :picker-options="pickerOptionsend"
              align="right"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['9:00:00', '18:00:00']"
              @change="onStartEndTimeChange"
            >
            </el-date-picker>
          </el-form-item>

          <!-- <el-form-item label="结束日期" prop="endTime">
            <el-date-picker
              style="width: 100%"
              v-model="calendarForm.endTime"
              type="datetime"
              placeholder="结束日期"
              value-format="timestamp"
              format="yyyy-MM-dd HH:mm"
              :picker-options="pickerOptionsend"
              align="right"
              default-time="18:00:00"
            >
            </el-date-picker>
          </el-form-item> -->

          <!-- 日程类型为出差时 -->
          
            <el-form-item label="行程" v-if="currOfficeMode.code === 'business_trip'">
             
                <template >
                  <div
                    v-for="(item, index) in tripList"
                    :key="index"
                    class="business-trip"
                  >
                    <div class="flexJsbAc">
                      <span style="font-weight: 500"
                        >行程({{ index + 1 }})</span
                      >
                      <div style="text-align: right">
                        <el-button
                          v-if="index >= 1"
                          size="mini"
                          type="danger"
                          @click="deleteBusinessTrip(index)"
                          >删除行程</el-button
                        >
                      </div>
                    </div>

                    <div class="flexJfAc item" label="交通工具">
                      <span class="title">交通工具</span>
                      <el-select
                        v-model="item.transport"
                        @change="onBusinessTripTransportChange(item)"
                        
                      >
                        <el-option
                          v-for="(v, i) in transportOptions"
                          :key="i"
                          :label="v"
                          :value="v"
                        ></el-option>
                      </el-select>
                    </div>
                    <div class="flexJfAc item" label="单程往返">
                      <span class="title">单程往返</span>
                      <el-select v-model="item.oneWayOrRoundTrip">
                        <el-option
                          v-for="(v, i) in oneWayOrRoundTripOptions"
                          :key="i"
                          :label="v"
                          :value="v"
                        ></el-option>
                      </el-select>
                    </div>
                    <div class="flexJfAc item" label="出发城市">
                      <span class="title">出发城市</span>
                      <train-city-suggest
                        v-if="item.transport === '火车'"
                        v-model="item.fromCity"
                        :fromSelectCity="item.fromCity"
                      />
                      <flight-city-suggest
                        v-else-if="item.transport === '飞机'"
                        v-model="item.fromCity"
                        :fromSelectCity="item.fromCity"
                      />
                      <el-input
                        v-else
                        v-model="item.fromCity"
                        placeholder="请输入出发城市"
                      />
                    </div>
                    <div class="flexJfAc item" label="目的城市">
                      <span class="title">目的城市</span>

                      <train-city-suggest
                        v-if="item.transport === '火车'"
                        v-model="item.toCity"
                        :fromSelectCity="item.toCity"
                      />
                      <flight-city-suggest
                        v-else-if="item.transport === '飞机'"
                        v-model="item.toCity"
                        :fromSelectCity="item.toCity"
                      />
                      <el-input
                        v-else
                        v-model="item.toCity"
                        placeholder="请输入目的城市"
                      />
                    </div>
                    <div class="flexJfAc item" label="开始时间">
                      <span class="title">开始时间</span>
                      <el-date-picker
                        v-model="item.startTime"
                        type="datetime"
                        placeholder="选择日期"
                        format="yyyy-MM-dd HH:mm"
                        value-format="timestamp"
                        default-time="09:00:00"
                        style="width: 100% !important"
                        @change="onBusinessTripTimeChange(item)"
                      />
                      <!--                <el-select v-model="item.startTimeAM" style="width: 49%!important;" @change="onBusinessTripTimeChange(item)">-->
                      <!--                  <el-option label="上午" value="AM"></el-option>-->
                      <!--                  <el-option label="下午" value="PM"></el-option>-->
                      <!--                </el-select>-->
                    </div>
                    <div class="flexJfAc item" label="结束时间">
                      <span class="title">开始时间</span>

                      <el-date-picker
                        v-model="item.endTime"
                        type="datetime"
                        placeholder="选择日期"
                        format="yyyy-MM-dd HH:mm"
                        value-format="timestamp"
                        default-time="18:00:00"
                        style="width: 100% !important"
                        @change="onBusinessTripTimeChange(item)"
                      />
                      <!--                <el-select v-model="item.endTimeAM" style="width: 49%!important;" @change="onBusinessTripTimeChange(item)">-->
                      <!--                  <el-option label="上午" value="AM"></el-option>-->
                      <!--                  <el-option label="下午" value="PM"></el-option>-->
                      <!--                </el-select>-->
                    </div>
                    <div class="flexJfAc item" label="时长（小时）">
                      <span class="title">时长(小时)</span>
                      <el-input
                        v-model="item.duration"
                        placeholder="根据排班自动计算时长"
                        disabled
                      />
                    </div>
                  </div>

                  <div class="flexAcJcfe">
                    <el-button
                      icon="el-icon-plus"
                      size="mini"
                      style="margin: 0  0 20px 0"
                      @click="addBusinessTrip"
                      >增加行程</el-button
                    >
                  </div>

                  <div class="flexJfAc item" label="出差时长(小时)" prop="duration">
                    <span class="title">出差时长(小时)</span>
                    <el-input
                      v-model="calendarForm.duration"
                      placeholder="自动计算"
                      disabled
                    ></el-input>
                  </div>
                </template> 
          </el-form-item>
          <!-- 日程类型为请假或外出时 -->
          <el-form-item
            v-if="
              this.currOfficeMode.code === 'ask_for_leave' ||
              this.currOfficeMode.code === 'business_out'
            "
            label="时长（小时）"
            prop="duration"
          >
            <el-input
              v-model="calendarForm.duration"
              placeholder="根据排班自动计算时长"
              disabled
            />
          </el-form-item>

          <el-form-item label="关联的项目或投资机构" prop="businessId">
            <div class="flexJsbAc">
              <el-select
                v-model="calendarForm.businessList"
                @visible-change="searchPorjectList($event)"
                @change="businessSelectChange"
                filterable
                placeholder="请选择项目或投资机构"
                clearable
                multiple
                no-data-text="加载中···"
              >
                <el-option
                  v-for="item in businessSelectList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                  <span>{{ item.name }}</span>
                </el-option>
              </el-select>
            </div>
          </el-form-item>

          <el-form-item label="预期产出" prop="output">
            <el-select
              v-model="calendarForm.output"
              @visible-change="selectListDict($event, 'schedule_output', 3)"
              filterable
              placeholder="请选择"
              clearable
              no-data-text="加载中···"
            >
              <el-option
                v-for="item in dictListScheduleOutput"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="地点" prop="place">
            <el-input type="text" v-model="calendarForm.place"></el-input>
          </el-form-item>

          <el-form-item label="备注" prop="note">
            <el-input type="text" v-model="calendarForm.note"></el-input>
          </el-form-item>

          <el-form-item label="图片" prop="pictures">
            <!-- 上传图片 -->
            <addFile
              :info="addFileInfo"
              type="picture"
              v-on:listenAddFileClose="listenAddFileClose"
            ></addFile>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-alert
      title="提示"
      type="info"
      description="日程创建后，出差/外出/请假类型将同步创建钉钉申请流程，且钉钉流程一经创建，后续即便修改日程信息也将无法更改。请谨慎提交日程！"
      show-icon
      :closable="false"
    >
    </el-alert>
    <div class="buttonGrop">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button @click="addCalendarThing" type="primary">确 定</el-button>
    </div>
    <!-- 添加任务 -->
    <!-- <addTaskEdit
      v-if="addTaskEditDalogVisible"
      :addTaskEditDalogVisible="addTaskEditDalogVisible"
      :type="addTaskEditType"
      v-on:listenAddTaskEditClose="listenAddTaskEditClose"
    ></addTaskEdit> -->
    <!-- 添加任务 -->
    <addTask1
      v-if="add1TaskDalog"
      :add1TaskDalog="add1TaskDalog"
      :type="add1TaskType"
      :source="source"
      v-on:listenAdd1TaskClose="listenAdd1TaskClose"
    ></addTask1>
  </el-main>
</template>

<script>
import { projectTeam } from "@/api/projectManage";

import { selectListDict } from "@/api/dataManagement";

import { getTaskSelectList } from "@/api/myMission";
import {
  addCalendarThing,
  calculateApproveDuration,
  getBusinessSelectList,
} from "@/api/calendar.js";
import { selectListMeetingProject } from "@/api/minutesOfMeeting";
import addTaskEdit from "@/components/myMission/addTask/addTask";
import addTask1 from "@/components/myMission/addTask/addTask1";
import { formatDate } from "@/common/date";
import TrainCitySuggest from "@/components/officeAutomation/trainCitySuggest";
import FlightCitySuggest from "@/components/officeAutomation/flightCitySuggest";
import SelectProcessUsers from "@/components/officeAutomation/selectProcessUsers";
import addFile from "@/components/officeAutomation/addFile";

export default {
  name: "newSchedule",
  data() {
    var validateOutput = (rule, value, callback) => {
      console.info(rule);
      if (
        !value &&
        (this.calendarForm.officeMode === "3" ||
          this.calendarForm.officeMode === "4")
      ) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    };

    return {
      title: "新增",
      calendarForm: {
        userIdList: [],
        dingTalkUserIdList: [],
        taskIdList: [],
        officeMode: null,
        type: null,
        duration: null,
        startAendTime: [],
        pictures: [],
        businessList: [],
        note: "",
      },
      tripList: [
        {
          transport: null, //交通工具
          oneWayOrRoundTrip: null, //单程往返
          fromCity: null, //出发城市
          toCity: null, //目的城市
          startTime: null, //开始时间
          endTime: null, //结束时间
          startTimeAM: "AM", //上午或下午
          endTimeAM: "AM", //上午或下午
          duration: null, //时长（小时）
        },
      ],
      currOfficeMode: {},
      searchName: {},
      beginDate: "",
      begintime: "",
      pickerStartOptionsend: {
        disabledDate: (time) => {
          return time.getTime() < new Date().getTime() - 24 * 60 * 60 * 1000;
        },
      },
      pickerOptionsend: {
        disabledDate: (time) => {
          return time.getTime() < this.calendarForm.beginTime - 8.64e7;
        },
      },
      dictList: [],
      dictList2: [],
      typeList: null,
      typeTitle: "",
      rules: {
        // projectName: [{ validator: checkProjectName, trigger: "blur" }],
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        type: [
          { required: true, message: "请选择事由类型", trigger: "change" },
        ],
        officeMode: [
          { required: true, message: "请选择日程类别", trigger: "change" },
        ],
        // businessId: [{required: true, message: "请选择关联项目或投资机构", trigger: "change"},],
        startAendTime: [
          { required: true, message: "请选择日期", trigger: "change" },
        ],
        reason: [{ required: true, message: "请输入事由", trigger: "blur" }],
        output: [
          {
            required: false,
            validator: validateOutput,
            message: "请选择预期产出",
            trigger: "change",
          },
        ],
        userIdList: [
          { required: true, message: "请选择相关人员", trigger: "change" },
        ],
        note: [{ required: false, message: "请输入备注", trigger: "blur" }],
      },
      taskSelectList: [],
      selectUserList: [],
      addTaskEditDalogVisible: false,
      add1TaskDalog: false,
      addTaskEditType: "other2",
      add1TaskType: null,
      internalUseroptionsList: null,
      source: "newSchedule",
      businessSelectList: [],
      dictListScheduleOutput: [],
      transportOptions: ["飞机", "火车", "汽车", "其他"],
      oneWayOrRoundTripOptions: ["单程", "往返"],
      addFileInfo: {
        relevantFile: "图片",
      },
    };
  },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    SelectProcessUsers,
    FlightCitySuggest,
    TrainCitySuggest,
    addTaskEdit,
    addTask1,
    addFile,
  },
  filters: {
    businessType(type) {
      if (type === 1 || type === 2 || type === 11) {
        return "项目";
      } else if (type === 0) {
        return "投资机构";
      }
      return type;
    },
  },
  watch: {
    info: function (val) {
      if (val) {
        console.log("info", val);
        if (val.beginTime || val.endTime) {
          this.calendarForm.startAendTime = [val.beginTime, val.endTime];
        }
      }
    },
  },
  methods: {
    async searchPorjectList(cb) {
      if (cb) {
        let info = {};
        const response = await selectListMeetingProject(info);
        if (response.success) {
          this.businessSelectList = response.data;
        }
      }
    },
    projectTeam(cb) {
      if (cb) {
        projectTeam({}).then((response) => {
          if (response.success) {
            this.internalUseroptionsList = response.data;
          }
        });
      }
    },
    chooseInvestor(id) {
      console.log(id);
    },
    //监听
    listenAddTaskEditClose(params) {
      const { type, isClose, info } = params;
      this.addTaskEditDalogVisible = !isClose;
      if (type == "sure") {
        this.getTaskSelectList(true);
        this.calendarForm.taskIdList.push(info);
      }
    },
    listenAdd1TaskClose(params) {
      const { type, isClose, info } = params;
      this.add1TaskDalog = !isClose;
      if (type == "sure") {
        this.calendarForm.taskIdList.push(info.id);
        this.getTaskSelectList(true);
      }
    },
    addNewSchedule() {
      this.add1TaskType = "add";
      this.add1TaskDalog = true;
    },
    getTaskSelectList(cb) {
      if (cb) {
        let info = { mode: 2 };

        getTaskSelectList(info).then((response) => {
          if (response.success) {
            let list = response.data;

            list.map(function (item, index) {
              if (item.projectName) {
                item.name = item.name + "——" + item.projectName;
              }
            });
            this.taskSelectList = list;
          }
        });
      }
    },
    selectListDict(cb, type, listType) {
      let info = { group: type };
      selectListDict(info).then((response) => {
        if (response.success) {
          if (listType === 1) {
            this.dictList = response.data;
          } else if (listType === 2) {
            this.dictList2 = response.data;
          } else if (listType === 3) {
            this.dictListScheduleOutput = response.data;
          }
        }
      });
    },
    //新增事件
    addCalendarThing() {
      var that = this;
      this.$refs["inputInvestor"].validate((valid) => {
        // console.log("-------------valid");
        if (valid) {
          //"关联的项目或投资机构"不选时，"备注"必填

          if (that.calendarForm.businessList.length == 0) {
            if (!that.calendarForm.note) {
              that.$message({
                type: "warning",
                message:
                  "您未选择【关联的项目或投资机构】，请在备注中填写原因！",
              });
              return false;
            }
          }

          let scheduleType = this.typeList.find(
            (x) => x.value === this.calendarForm.type
          );

          var calendarForm = JSON.parse(JSON.stringify(this.calendarForm));
          var calendarForm = { ...calendarForm };
          calendarForm.beginTime = calendarForm.startAendTime[0];
          calendarForm.endTime = calendarForm.startAendTime[1];
          calendarForm.title = calendarForm.title.replace(/\s*/g, "");
          calendarForm.officeModeCode = that.currOfficeMode.code;
          calendarForm.officeModeName = that.currOfficeMode.name;
          calendarForm.typeCode = scheduleType.code;
          calendarForm.typeName = scheduleType.name;

          let businessIdList = JSON.parse(
            JSON.stringify(calendarForm.businessList)
          );
          console.log(businessIdList);
          calendarForm.businessList = [];
          businessIdList.map(function (item, index) {
            that.businessSelectList.map(function (item2, index) {
              if (item == item2.id) {
                calendarForm.businessList.push({
                  businessId: item2.id,
                  // item2.type是项目和机构类型（1、2、11是项目，0是机构）; businessType：2是项目,3是机构,现在机构转换成虚拟项目，所以统一传2
                  businessType: 2,
                  // businessType: item2.type == "0" ? "2" : item2.type == "1" ? "2" : item2.type == "2" ? "2": item2.type == "11" ? "2" :"" ,
                });
              }
            });
          });
          console.log(calendarForm);
          let formComponentsValues = [];
          let scheduleName = scheduleType.name;
          let startTime = formatDate(
            new Date(calendarForm.beginTime),
            "yyyy-MM-dd hh:mm"
          );
          let endTime = formatDate(
            new Date(calendarForm.endTime),
            "yyyy-MM-dd hh:mm"
          );

          if (calendarForm.officeModeCode === "ask_for_leave") {
            formComponentsValues.push({
              name: JSON.stringify(["开始时间", "结束时间"]),
              value: JSON.stringify([
                startTime,
                endTime,
                calendarForm.duration,
                "hour",
                scheduleName,
                "请假类型",
              ]),
            });
            formComponentsValues.push({
              name: "请假事由",
              value: calendarForm.reason,
            });
            formComponentsValues.push({
              name: "图片",
              value: JSON.stringify(calendarForm.pictures),
            });
          } else if (calendarForm.officeModeCode === "business_out") {
            formComponentsValues.push({ name: "开始时间", value: startTime });
            formComponentsValues.push({ name: "结束时间", value: endTime });
            formComponentsValues.push({
              name: "时长(小时)",
              value: calendarForm.duration,
            });
            formComponentsValues.push({
              name: "外出事由",
              value: calendarForm.reason,
            });
            formComponentsValues.push({
              name: "同行人",
              value: JSON.stringify(calendarForm.dingTalkUserIdList),
            });
            formComponentsValues.push({
              name: "图片",
              value: JSON.stringify(calendarForm.pictures),
            });
          } else if (calendarForm.officeModeCode === "business_trip") {
            let tripValues = []; //出差行程
            let minTripStartTime = null,
              maxTripEndTime = null;

            for (let i = 0; i < this.tripList.length; i++) {
              let trip = this.tripList[i];
              let tripStartTime = formatDate(
                new Date(trip.startTime),
                "yyyy-MM-dd hh:mm"
              );
              let tripEndTime = formatDate(
                new Date(trip.endTime),
                "yyyy-MM-dd hh:mm"
              );

              if (!minTripStartTime || trip.startTime < minTripStartTime) {
                minTripStartTime = trip.startTime;
              }
              if (!maxTripEndTime || trip.endTime > maxTripEndTime) {
                maxTripEndTime = trip.endTime;
              }

              let rowValue = []; //单个出差行程
              rowValue.push({ name: "交通工具", value: trip.transport });
              rowValue.push({
                name: "单程往返",
                value: trip.oneWayOrRoundTrip,
              });
              rowValue.push({ name: "出差城市", value: trip.fromCity });
              rowValue.push({ name: "目的城市", value: trip.toCity });
              rowValue.push({ name: "开始时间", value: tripStartTime });
              rowValue.push({ name: "结束时间", value: tripEndTime });
              rowValue.push({ name: "时长(小时)", value: trip.duration });

              // tripValues.push({rowValue: rowValue});
              tripValues.push(rowValue);
            }

            // calendarForm.beginTime = formatDate(
            //   new Date(minTripStartTime),
            //   "yyyy-MM-dd"
            // );
            // calendarForm.endTime = formatDate(
            //   new Date(maxTripEndTime),
            //   "yyyy-MM-dd"
            // );
            calendarForm.beginTime = minTripStartTime;
            calendarForm.endTime = maxTripEndTime;

            let formValues = [];
            formValues.push({ name: "出差事由", value: calendarForm.reason }); //出差事由
            formValues.push({
              name: "行程",
              value: JSON.stringify(tripValues),
            }); //行程
            formValues.push({
              name: "出差时长(小时)",
              value: calendarForm.duration,
            }); //出差天数
            formValues.push({
              name: "出差备注",
              value: calendarForm.note || "",
            }); //出差备注
            formValues.push({
              name: "同行人",
              value: JSON.stringify(calendarForm.dingTalkUserIdList),
            }); //同行人, value示例："张三,李四"，extValue示例："[{\"emplId\":\"013955675313784319\",\"name\":\"张三\",\"avatar\":\"\"},{\"emplId\":\"013955675313784320\",\"name\":\"李四\",\"avatar\":\"\"}]"

            // formComponentsValues.push({name: "商旅出差", value: JSON.stringify(formValues)});
            formComponentsValues = formValues;
          }

          calendarForm.formComponentsValues = formComponentsValues;
          addCalendarThing(calendarForm).then((response) => {
            this.$message.success("添加成功");
            try {
              that.$refs["inputInvestor"].resetFields(); //重置form
              that.tripList = [{}];
              // that.calendarForm = {}; //重置form
              that.$emit("listeNewSchedule", { close: true, type: "sure" });
            } catch (e) {}
          });
        }
      });
    },

    //关闭弹窗
    closeDialog() {
      this.$emit("listeNewSchedule", { close: true, type: "cancel" });
    },
    // 获取关联项目或投资机构列表
    async getBusinessSelectList(e) {
      if (e) {
        const businessList = await getBusinessSelectList();
        this.businessSelectList = businessList;
      }
    },

    // 关联项目改变
    businessSelectChange(value) {
      let that = this;
      console.log(value);
      for (const business of this.businessSelectList) {
        if (business.id === value) {
          this.calendarForm.businessType = business.businessType;
        }
      }
    },

    onStartEndTimeChange(dateTime) {
      console.log("dateTime", dateTime);
      if (dateTime) {
        let startTime = dateTime[0];
        let endTime = dateTime[1];
        this.preCalcDuration(startTime, endTime, null, null, (duration) => {
          this.calendarForm.duration = duration;
        });
      }
    },

    /**
     * 预计算时长
     * 参考文档：https://open.dingtalk.com/document/orgapp-server/calculate-duration-based-on-attendance-scheduling
     * @param fromTime 开始时间戳，开始时间不能早于当前时间前31天。
     * @param toTime 结束时间戳，结束时间减去开始时间的天数不能超过31天。
     * @param fromTimeAm AM/PM
     * @param toTimeAm AM/PM
     * @param cb 回调函数，参数为时长
     */
    preCalcDuration(fromTime, toTime, fromTimeAm, toTimeAm, cb) {
      console.log("startTime: ", fromTime, "endTime: ", toTime);

      //审批单类型1：加班，2：出差，3：请假
      //fromTime: 开始时间，支持的时间格式 2019-08-15/2019-08-15 AM/2019-08-15 12:43。开始时间不能早于当前时间前31天
      //toTime: 结束时间，支持的时间格式 2019-08-15/2019-08-15 AM/2019-08-15 12:43。结束时间减去开始时间的天数不能超过31天。biz_type为1时结束时间减去开始时间不能超过1天
      //durationUnit: 时长单位，支持的day,halfDay,hour，biz_type为1时仅支持hour。时间格式必须与时长单位对应，2019-08-15对应day，2019-08-15 AM对应halfDay，2019-08-15 12:43对应hour
      //calculateMode: 计算方法，0：按自然日计算，1：按工作日计算
      let bizType, durationUnit, calculateMode;
      let code = this.currOfficeMode.code;
      if (code === "ask_for_leave") {
        //请假
        bizType = 3;
        durationUnit = "hour";
        fromTime = formatDate(new Date(fromTime), "yyyy-MM-dd hh:mm");
        toTime = formatDate(new Date(toTime), "yyyy-MM-dd hh:mm");
        calculateMode = 1;
      } else if (code === "business_trip") {
        //出差
        bizType = 2;
        durationUnit = "hour";
        fromTime = formatDate(new Date(fromTime), "yyyy-MM-dd hh:mm");
        toTime = formatDate(new Date(toTime), "yyyy-MM-dd hh:mm");
        calculateMode = 1;
      } else if (code === "business_out") {
        //外出
        bizType = 3;
        durationUnit = "hour";
        fromTime = formatDate(new Date(fromTime), "yyyy-MM-dd hh:mm");
        toTime = formatDate(new Date(toTime), "yyyy-MM-dd hh:mm");
        calculateMode = 1;
      } else {
        return;
      }

      let params = {
        bizType,
        fromTime,
        toTime,
        durationUnit,
        calculateMode,
      };

      calculateApproveDuration(params).then((res) => {
        if (res.success) {
          cb && cb(res.data.duration);
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },

    onOfficeModeChange(value) {
      let that = this;
      this.calendarForm.type = null;
      // console.log("onOfficeModeChange", JSON.stringify(value));
      this.currOfficeMode = this.dictList2.find((x) => x.value === value) || {};

      that.dictList2.map(function (item, index) {
        if (value == item.value) {
          that.typeTitle = item.name;
          that.typeList = item.children;
        }
      });
      console.log("currOfficeMode", this.currOfficeMode);

      this.$refs.selectProcessUsers.reset();

      if (this.currOfficeMode.code === "ask_for_leave") {
        //请假
        this.rules.startAendTime.required = true;
        this.rules.note.message = "请在备注中输入请假事由";
        this.addFileInfo.relevantFile = "请假图片";
      } else if (this.currOfficeMode.code === "business_trip") {
        //出差
        this.rules.startAendTime.required = false;
        this.rules.note.message = "请在备注中输入出差事由";
        this.addFileInfo.relevantFile = "出差图片";
      } else if (this.currOfficeMode.code === "business_out") {
        //外出
        this.rules.startAendTime.required = true;
        this.rules.note.message = "请在备注中输入外出事由";
        this.addFileInfo.relevantFile = "外出图片";
      } else {
        this.rules.startAendTime.required = true;
        this.rules.note.message = "请输入备注";
        this.addFileInfo.relevantFile = "图片";
      }
    },

    addBusinessTrip() {
      this.tripList = [
        ...this.tripList,
        {
          transport: null, //交通工具
          oneWayOrRoundTrip: null, //单程往返
          fromCity: null, //出发城市
          toCity: null, //目的城市
          startTime: null, //开始时间
          endTime: null, //结束时间
          startTimeAM: "AM", //上午或下午
          endTimeAM: "AM", //上午或下午
          duration: null, //时长（小时）
        },
      ];
    },
    deleteBusinessTrip(index) {
      this.$confirm("确定删除行程" + (index + 1) + "吗？").then(() => {
        this.tripList.splice(index, 1);
      });
    },
    onBusinessTripTransportChange(item) {
      console.log(item);
      item.fromCity = null;
      item.toCity = null;
    },
    onBusinessTripTimeChange(item) {
      console.log(item);
      if (!item.startTime || !item.endTime) {
        return;
      }

      this.preCalcDuration(
        item.startTime,
        item.endTime,
        item.startTimeAM,
        item.endTimeAM,
        (duration) => {
          item.duration = duration;
          this.calendarForm.duration = this.tripList.reduce(
            (sum, item) => sum + item.duration * 1,
            0
          );
          console.log(this.calendarForm.duration);
        }
      );
    },
    //监听上传文件弹窗关闭
    listenAddFileClose(params) {
      const { type, isClose, mold } = params;
      if (type === "sure") {
        let fileUrls = [];
        let file = JSON.parse(JSON.stringify(params.files));
        file.forEach((item, index) => {
          fileUrls.push(item.url);
        });
        console.log(fileUrls);
        this.calendarForm.pictures = fileUrls;
      }
    },
    onProcessUsersChange(users) {
      console.log("onProcessUsersChange", users);
      this.calendarForm.dingTalkUserIdList = users.map((x) => x.dingTalkUserId);
    },
  },
};
</script>

<style lang="scss" scoped>
.memberList {
  padding: 5px;

  i {
    margin-left: 3px;
  }
}

.el-form {
  .el-form-item {
    width: 100%;

    .el-input {
      width: 99% !important;
    }

    .el-select {
      width: 99% !important;
    }

    .el-date-editor {
      width: 99% !important;
    }
  }
}

.buttonGrop {
  margin-top: 20px;
  display: flex;
  justify-content: center;

  button {
    margin: 0 20px;
  }
}

.business-trip {
  // margin-left: 50px;
  // border-top: 1px solid #eee;
}
.business-trip {
  margin-bottom: 40px;

  .item {
    margin: 15px 0;
    width: 95%;
    .title {
      width: 123px;
      text-align: right;
      margin-right: 15px;
    }
  }
}
 .item {
    margin: 15px 0;
    width: 95%;
    .title {
      width: 128px;
      text-align: right;
      margin-right: 15px;
    }
  }
</style>
