<template>
  <div class="data-auth-approve">
    <el-form v-model="formData" class="projectForm" label-width="110px">
      <el-form-item label="权限类型" prop="event">
        <el-select v-model="formData.event" clearable>
          <el-option
            v-for="(item, index) in eventOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="权限归属" prop="target">
        <el-select v-model="formData.target" clearable>
          <el-option
            v-for="(item, index) in targetOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="审批结果">
        <el-select v-model="formData.status" clearable>
          <el-option
            v-for="(item, index) in statusOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
            <span :class="'approve-status' + item.value">{{ item.label }}</span>
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <el-row>
      <el-col :span="24" style="text-align: center; margin: 0 0 20px 0">
        <el-button type="primary" @click="getDataAuthApplyList">查询</el-button>
      </el-col>
    </el-row>

    <el-table
      border
      row-key="id"
      style="width: 100%"
      :data="tableData"
      v-loading="tableLoading"
    >
      <el-table-column label="权限类型" prop="event">
        <template slot-scope="scope">
          <span>{{ scope.row.event | eventFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column label="权限归属" prop="target">
        <template slot-scope="scope">
          <span>{{ scope.row.target | targetFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column label="权限名称" prop="targetName"> </el-table-column>
      <el-table-column label="申请人" prop="applyUserName"> </el-table-column>

      <el-table-column label="审批人" prop="approveUserName"> </el-table-column>
      <el-table-column label="申请日期" prop="createDate" width="155">
        <template slot-scope="scope">
          <span>{{ scope.row.createDate | formatDateFilter }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="审批日期" prop="updateDate" width="155">
        <template slot-scope="scope">
          <span>{{ scope.row.updateDate | formatDateFilter }}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="审批结果" prop="status">
        <template slot-scope="scope">
          <el-tooltip :content="scope.row.updateDate | formatDateFilter " placement="bottom" effect="light" v-if="scope.row.status != 0">
            <span :class="'approve-status' + scope.row.status">{{
              scope.row.status | statusFilter
            }}</span>
          </el-tooltip>
           <span v-else :class="'approve-status' + scope.row.status">{{
              scope.row.status | statusFilter
            }}</span>
        </template>
      </el-table-column>

      <el-table-column label="操作" fixed="right" width="150">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status === 0"
            type="primary"
            size="mini"
            v-permission="['data_auth:approve']"
            @click="approveDataAuth(1, scope.row)"
            >同意
          </el-button>
          <el-button
            v-if="scope.row.status === 0"
            type="danger"
            size="mini"
            v-permission="['data_auth:approve']"
            @click="approveDataAuth(2, scope.row)"
            >拒绝
          </el-button>
          <el-button
            v-if="scope.row.status === 1"
            type="default"
            size="mini"
            v-permission="['data_auth:approve']"
            @click="revokeDataAuth(scope.row)"
            >收回</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="page-info-class">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="this.pageInfo.pageNum"
        :page-sizes="[10, 20]"
        :page-size="this.pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="this.pageInfo.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {
  approveDataAuth,
  getDataAuthApplyList,
  revokeDataAuth,
} from "@/api/dataAuthApply";
import { formatDate } from "@/common/date";

let that;
export default {
  name: "dataAuthApprove",
  data() {
    return {
      tableData: [],
      tableLoading: false,
      formData: {
        event: null,
        target: null,
        status: null,
      },
      pageCount: 1,
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      statusOptions: [
        { value: 0, label: "待审批" },
        { value: 1, label: "同意" },
        { value: 2, label: "拒绝" },
        { value: 3, label: "收回" },
      ],
      eventOptions: [
        { value: "document_file", label: "项目文件" },
        { value: "ding_file", label: "网盘文件" },
        { value: "base_info", label: "项目基本信息" },
        { value: "principal_reg_info", label: "项目工商信息" },
        { value: "business_info", label: "项目经营信息" },
        { value: "contact_info", label: "项目联系人信息" },
        { value: "evaluation_info", label: "项目评估历史" },
        { value: "meeting_list", label: "项目会议历史" },
        { value: "task_info", label: "项目任务信息" },
      ],
      targetOptions: [
        { value: "t_schedule", label: "日程" },
        { value: "t_project", label: "项目" },
        { value: "t_project_workflow", label: "项目流程" },
        { value: "t_evaluation_record", label: "评估记录" },
        { value: "t_meeting", label: "会议记录" },
        { value: "t_task", label: "任务记录" },
      ],
    };
  },
  beforeCreate() {
    that = this;
  },
  mounted() {
    this.getDataAuthApplyList();
  },
  filters: {
    statusFilter(val) {
      let item = that.statusOptions.find((x) => x.value === val * 1);
      return (item && item.label) || "未知";
    },
    eventFilter(val) {
      let item = that.eventOptions.find((x) => x.value === val);
      return (item && item.label) || "未知";
    },
    targetFilter(val) {
      let item = that.targetOptions.find((x) => x.value === val);
      return (item && item.label) || "未知";
    },
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  methods: {
    getDataAuthApplyList() {
      let params = {
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        event: this.formData.event,
        target: this.formData.target,
        status: this.formData.status,
      };

      getDataAuthApplyList(params).then((res) => {
        if (res.success) {
          this.tableData = res.data.data;
          this.pageCount = res.data.pageCount;
          this.pageInfo.total = res.data.total;
        }
      });
    },

    approveDataAuth(status, row) {
      let params = {
        id: row.id,
        status: status,
        ability: row.ability,
      };

      approveDataAuth(params).then((res) => {
        if (res.success) {
          this.$message({
            type: "success",
            message: "审批成功",
          });
          this.getDataAuthApplyList();
        }
      });
    },

    revokeDataAuth(row) {
      let params = {
        id: row.id,
      };
      revokeDataAuth(params).then((res) => {
        if (res.success) {
          this.$message({
            type: "success",
            message: "收回权限成功",
          });
          this.getDataAuthApplyList();
        }
      });
    },

    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.getDataAuthApplyList();
    },
    handleCurrentChange(val) {
      this.pageInfo.pageNum = val;
      if (val > this.pageCount) {
        val = this.pageCount;
      }
      this.getDataAuthApplyList();
    },
  },
};
</script>

<style scoped lang="scss">
.approve-status0 {
  color: #409eff;
}

.approve-status1 {
  color: #67c23a;
}

.approve-status2 {
  color: #f56c6c;
}

.approve-status3 {
  color: #f56c6c;
}

.projectForm {
  margin: 15px 0 0;
  display: flex;
  flex-wrap: wrap;

  .el-form-item {
    text-align: left;
    width: 50% !important;
    min-height: 1rem;

    .el-select,
    .el-cascader {
      width: 100% !important;
    }

    .el-input-number {
      width: 100% !important;
      text-align: left !important;

      .el-input__inner {
        text-align: left !important;
      }
    }
  }
}

.bar {
  overflow: hidden;
  padding: 10px;

  li {
    min-height: 50px;
    line-height: 50px;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5px 0;

    span {
      display: inline-block;
      width: 120px;
      text-align: right;
    }

    .childWidth {
      width: calc(100% - 150px);
    }
  }

  .width50 {
    width: 50%;
  }

  .width100 {
    width: 100%;

    p {
      width: calc(100% - 120px);
      float: right;

      span {
        display: inline;
        padding: 0 10px;
      }
    }
  }
}
</style>
