<template>
  <el-main
    class="scrollbar"
    v-infinite-scroll="load"
    infinite-scroll-immediate="false"
    infinite-scroll-disabled="loading"
    infinite-scroll-distance="5"
  >
    <div v-for="(item, index) in latestList" :key="index">
      <div class="flexJfAc itemOne" @click="jumpLink(item)">
        <div class="time">
          <el-popover
            v-if="item.tip"
            placement="top-start"
            title=""
            width="300"
            trigger="hover"
            :content="item.tip"
          >
            <span slot="reference"
              >{{ item.releaseTime | formatDateFilter }} &nbsp;&nbsp; <span>{{
                item.content
              }}</span></span
            >
          </el-popover>
          <span v-else>{{ item.releaseTime | formatDateFilter }} &nbsp;&nbsp; <span>{{
                item.content
              }}</span></span>
        </div>
      </div>
    </div>
    <el-row class="flexJA">
      <!-- <div class="workflowVersion" v-show="loading">加载中···</div> -->
      <el-divider v-if="noMore" content-position="center">
        <span class="workflowVersion">我是有底线的</span>
      </el-divider>
      <el-empty
        v-if="noData"
        :image-size="100"
        description="暂无动态"
      ></el-empty>
    </el-row>
    <el-dialog
      title="详情"
      :visible.sync="dialogVisibleEdit"
      v-if="dialogVisibleEdit"
      width="72%"
      class="editDialogs"
      :close-on-click-modal="false"
    >
      <detailsDialog :msPd="msPd"></detailsDialog>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleEdit = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisibleEdit = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import { getLatestList } from "@/api/home";
import { findProjectAllDetail } from "@/api/projectManage";
import { formatDate } from "@/common/date";
import detailsDialog from "@/components/project/detailsDialog";

export default {
  name: "latestNews",
  data() {
    return {
      latestList: [],
      routeNameList: {
        five: "home",
        six: "minutesOfMeeting",
        seven: "project",
        eight: "myMission",
        nine: "account",
      },
      dialogVisibleEdit: false,
      msPd: null,
      pageNum: 0,
      pageSize: 20,
      total: null,
      pageCount: 0,
      loading: false,
      noMore: false,
      noData: false,
    };
  },
  props: {
    dynamic: Number,
  },
  components: {
    detailsDialog,
  },
  computed: {},
  watch: {
    dynamic(newVal, oldVal) {
      console.log(newVal);
      this.loading = false;
      this.noData = false;
      this.noMore = false;
      this.latestList = [];
      this.pageNum = 1;
      this.getLatestList(1, this.pageSize);
    },
  },
  created() {},
  mounted() {},
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "MM-dd hh:mm");
    },
  },
  activated() {
    console.log("home--------actived2");
    this.latestList = [];
    this.getLatestList(1, this.pageSize);
  },
  methods: {
    load() {
      console.log("load");

      this.pageNum++;
      this.getLatestList(this.pageNum, this.pageSize);
    },
    disabled() {
      return this.loading || this.noMore;
    },
    //
    jumpLink(item) {
      var routeName;
      var businessType = item.businessType;
      console.log(businessType);

      switch (businessType) {
        case 5: //日程相关
          routeName = this.routeNameList.five;
          this.jumpRouting5(routeName, item);
          break;
        case 6: //6 会议跟进事项
          routeName = this.routeNameList.six;
          this.jumpRouting6(routeName, item);
          break;
        case 7: //7 项目
          routeName = this.routeNameList.seven;
          this.jumpRouting7(routeName, item);
          break;
        case 8: //8 任务
          routeName = this.routeNameList.eight;
          this.jumpRouting8(routeName, item);
          break;
        case 9: //9 系统用户
          routeName = this.routeNameList.nine;
          this.jumpRouting9(routeName, item);
          break;
      }
    },

    jumpRouting5(routeName, item) {
      console.log(item);
    },
    jumpRouting6(routeName, item) {
      console.log(item);
    },
    jumpRouting7(routeName, item) {
      console.log(item);
      let info = { id: item.businessId };
      this.detailDialog(info);
    },
    jumpRouting8(routeName, item) {
      this.routeJump(routeName, item);
    },
    jumpRouting9(routeName, item) {
      this.routeJump(routeName, item);
    },
    routeJump(routeName, item) {
      const { href } = this.$router.resolve({
        name: routeName,
        query: {},
      });
      window.open(href);
    },
    detailDialog(row) {
      //详情id
      // findProjectAllDetail(row.id).then((res) => {
      //   if (res.success) {
      //     this.msPd = res.data;
      //     this.dialogVisibleEdit = true;
      //   }
      // });
    },

    getLatestList(pageNum, pageSize) {
      let that = this;
      that.loading = true;
      let info = {
        businessType: this.dynamic,
        pageNum: pageNum,
        pageSize: pageSize,
      };
      getLatestList(info)
        .then((response) => {
          if (response.success) {
            let data = response.data;
            let list = response.data.data;
            if (list.length > 0) {
              that.latestList = that.latestList.concat(list);
              that.loading = false;
              that.pageNum = data.pageNum;
              that.pageSize = data.pageSize;
              that.total = data.total;
              that.pageCount = data.pageCount;
              if (that.pageNum >= data.pageCount) {
                that.noMore = true;
                that.loading = true;
              }
            } else {
              that.loading = false;
              that.noData = true;
              that.noMore = false;
            }
          } else {
            that.loading = false;
            that.noData = true;
          }
        })
        .catch(function () {
          that.loading = false;
          that.noMore = false;
          that.noData = true;
        });
    },
    //
  },
};
</script>

<style lang="scss" scoped>
.el-main {
  // padding: 8px !important;
  height: 20rem;
  // overflow-y: auto;
  // overflow-x: hidden;
  .el-row {
    // height: 100%;
  }
}
.itemOne {
  .time {
    // align-self: baseline;
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    span {
      color: #409eff;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
    }
    span:hover {
      color: #66b1ff;
    }
  }
}
</style>
