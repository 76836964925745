<!--
 * @Description: 
 * @Author: transparent
 * @Date: 2021-12-14 14:45:51
 * @LastEditTime: 2021-12-28 09:35:10
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/components/home/userPreferences.vue
-->

<template>
  <div class="userPreferences">
    <div class="">
      <div v-if="userPreferencesStatus == 1" class="setting-content">
        <calendar-search-scope-preference :info="options" />
      </div>

      <div v-if="userPreferencesStatus == 2" class="setting-content">
        <my-mission-task-status-preference :info="options" />
        <myMissionProjectStatusPreference :info="options" />
      </div>
      <div v-if="userPreferencesStatus == 3" class="setting-content">
        <task-management-view-preference :info="options" />
      </div>
    </div>
  </div>
</template>

<script>
import { getAllOptionsUserInfo } from "@/api/calendar";
import CalendarSearchScopePreference from "@/components/home/userPreferences/calendarSearchPreference";
import TaskManagementViewPreference from "@/components/userPreference/taskManagementViewPreference";
import MyMissionTaskStatusPreference from "@/components/userPreference/myMissionTaskStatusPreference";
import MyMissionProjectStatusPreference from "@/components/userPreference/myMissionProjectStatusPreference";

export default {
  name: "userPreferences",
  components: {
    MyMissionTaskStatusPreference,
    TaskManagementViewPreference,
    CalendarSearchScopePreference,
    MyMissionProjectStatusPreference,
  },
  data() {
    return {
      activeMenu: "1-1",
      options: {},
      currId: null,
    };
  },
  props: {
    userPreferencesStatus: Number, //1日历，2我的任务，3任务管理
  },
  mounted() {
    this.getAllOptionsUserInfo();
  },
  watch: {
    currId(val, oldVal) {
      if (oldVal) {
        let el = document.getElementById(oldVal);
        console.log("oldElement", val, el);
        el.style.borderColor = "#CCCCCC";
      }
      if (val) {
        let el = document.getElementById(val);
        console.log("newElement", val, el);
        el.style.borderColor = "#409EFF";
        setTimeout(() => {
          el.style.borderColor = "#CCCCCC";
        }, 1000);
      }
    },
  },
  methods: {
    getAllOptionsUserInfo() {
      getAllOptionsUserInfo().then((res) => {
        if (res.success) {
          this.options = res.data;
        }
      });
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleScroll(e) {
      console.log("handleScroll", e);
    },
    scrollToId(id) {
      this.currId = id;
      let el = document.getElementById(id);
      let rect = el.getBoundingClientRect();
      this.$refs.scrollRef.$el.scrollTo(0, rect.top - 100);
    },
  },
};
</script>

<style scoped lang="scss">
.userPreferences{
    overflow-y: hidden;
}
 .setting-content {
    font-size: 14px;
    color: #333;

    >div:not(:last-of-type){
      margin-bottom: 20px;
    }
  }
</style>
