<template>
  <el-dialog
    class="edit"
    :title="title"
    :visible="detailsDialog"
    center
    @close="dialogClose"
    width="900px"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
  >
    <div class="bar">
      <el-divider content-position="center">基本信息</el-divider>
      <ul style="display: flex; flex-wrap: wrap">
        <li class="width50">
          <span>会议主题：</span>
          <div class="childWidth">{{ meetingInfo.subject }}</div>
        </li>
        <li class="width50">
          <span>会议日期：</span>
          <div class="childWidth">
            {{ meetingInfo.beginTime | formatDateFilter }}
          </div>
        </li>
        <li class="width50">
          <span>会议地点：</span>
          <div class="childWidth">{{ meetingInfo.place }}</div>
        </li>
        <li class="width50">
          <span class="cotent">与会人员(内部)：</span>
          <div class="flexJfAc">
            <div
              v-for="(item, index) in meetingInfo.internalUserList"
              :key="index"
              style="padding-right: 5px"
            >
              {{ index + 1 }}.{{ item.realName }}
            </div>
          </div>
        </li>
        <li class="width50">
          <span>与会人员(外部)：</span>
          <div class="childWidth">{{ meetingInfo.externalUsers }}</div>
        </li>
         <li class="width50">
          <span>创建人：</span>
          <div class="childWidth">{{ meetingInfo.creatorName }}</div>
        </li>
         <li class="width50">
          <span>更新时间：</span>
          <div class="childWidth">{{ meetingInfo.updateDate | formatDateFilter  }}</div>
        </li>
      </ul>
      <el-divider content-position="center">关联事项</el-divider>
      <ul style="display: flex; flex-wrap: wrap">
        <li class="width50" v-if="meetingInfo.meetingType">
          <span>会议类型：</span>
          <div class="childWidth">
            {{ meetingInfo.meetingType | transMeetingType }}
          </div>
        </li>
        <li class="width50" v-if="meetingInfo.relatedScheduleTitle">
          <span>关联日程：</span>
          <div class="childWidth">{{ meetingInfo.relatedScheduleTitle }}</div>
        </li>
        <template v-if="meetingInfo.meetingType === 'project'">
          <li class="width50">
            <span>项目：</span>
            <div class="childWidth">{{ meetingInfo.projectName }}</div>
          </li>
          <li class="width50">
            <span>业务流程节点：</span>
            <div class="childWidth">
              {{ meetingInfo.projectWorkflowName }}
              <a style="color: #aaaaaa">{{ meetingInfo.nodeName }}</a>
            </div>
          </li>
          <li class="width50">
            <span>任务：</span>
            <div class="childWidth">{{ meetingInfo.taskName }}</div>
          </li>
        </template>
        <template v-if="meetingInfo.meetingType === 'invest'">
          <li class="width50">
            <span>投资机构：</span>
            <div class="childWidth">{{ meetingInfo.institutionName }}</div>
          </li>
          <li class="width50">
            <span>投资人：</span>
            <div class="childWidth">{{ meetingInfo.investorName }}</div>
          </li>
          <li class="width50" v-if="meetingInfo.investorTaskName">
            <span>任务：</span>
            <div class="childWidth">{{ meetingInfo.investorTaskName }}</div>
          </li>
        </template>
        <template v-if="meetingInfo.meetingType === 'other'">
          <li class="width50">
            <span>任务：</span>
            <div class="childWidth">{{ meetingInfo.taskName }}</div>
          </li>
        </template>
      </ul>

      <li class="width100">
        <span class="contentLeft">会议内容：</span>
        <div class="childWidth content scrollbarX" v-html="meetingInfo.content">
          {{ meetingInfo.content }} 
        </div>
      </li>
      <li class="width50 flexJfAc">
        <span class="cotent attachmentListText">附件：</span>
        <div class="attachmentList">
          <div
            v-for="(item, index) in meetingInfo.attachmentList"
            :key="index"
            class="viewFile"
            @click="viewFile(item, item.url)"
          >
            {{ index + 1 }}.{{ item.name }}
          </div>
        </div>
      </li>

      <li class="width100">
        <span>跟进事项：</span>
        <div class="childWidth">
          <!-- 表格 -->
          <el-table
            v-loading="loadingTable"
            border
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="#DCDFE6"
            :data="meetingInfo.matterList"
            style="width: 100%"
          >
            <el-table-column label="事项" fixed="left" prop="content">
              <template slot-scope="scope">
                {{ scope.row.content }}
              </template>
            </el-table-column>
            <el-table-column label="负责人" prop="ownerName" width="155">
              <template slot-scope="scope">
                {{ scope.row.ownerName }}
              </template>
            </el-table-column>
            <el-table-column label="目标完成日期" prop="endTime" width="165">
              <template slot-scope="scope">
                <div>{{ scope.row.endTime | formatDateFilter }}</div>
              </template>
            </el-table-column>
            <el-table-column label="状态" prop="status" width="65">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.status == 0
                      ? "未开始"
                      : scope.row.status == 1
                      ? "进行中"
                      : scope.row.status == 2
                      ? "已完成"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="备注" prop="remark">
              <template slot-scope="scope"> {{ scope.row.remark }}</template>
            </el-table-column>
          </el-table>
        </div>
      </li>
    </div>
    <el-divider content-position="center">一般任务</el-divider>

    <div class="top flexAcJcfe" style="margin-bottom: 10px">
      <el-button
        v-permission="['admin:task:add_instruction']"
        type="primary"
        @click="addTask()"
        >新增批示任务</el-button
      >
    </div>
    <el-table
      border
      row-key="id"
      style="width: 100%"
      :data="meetingInfo.taskList"
    >
      <el-table-column label="任务" fixed="left" prop="name"></el-table-column>
      <el-table-column label="任务描述" prop="description">
        <template slot-scope="scope">
          <el-popover
            placement="top-start"
            title="任务描述"
            width="200"
            trigger="hover"
            :content="scope.row.description"
          >
            <span slot="reference" class="beyondDisplay1">{{
              scope.row.description
            }}</span>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="负责人" prop="ownerName"></el-table-column>
      <el-table-column label="指派人" prop="designatorName"></el-table-column>
      <el-table-column label="任务状态" prop="status" width="65">
        <template slot-scope="scope">
          <div>
            {{
              scope.row.status == 1
                ? "待启动"
                : scope.row.status == 2
                ? "进行中"
                : scope.row.status == 3
                ? "已完成"
                : scope.row.status == 4
                ? "已取消"
                : scope.row.status == 5
                ? "已暂停"
                : "未知"
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="fileList" align="center" label="文件">
        <template slot-scope="scope">
          <a
            href="#"
            style="cursor: pointer"
            v-for="(item, index) in scope.row.fileList"
            :key="index"
            @click="handleExport(item)"
          >
            <p v-if="item.originalFileName">
              {{ index + 1 }}.{{ item.originalFileName }}
            </p>
            <p v-else>{{ index + 1 }}.{{ item.name }}</p>
          </a>
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <el-button
              type="primary"
              size="mini"
              @click="addTask(scope.row)"
              >添加其他任务</el-button
            >
        </template>
      </el-table-column> -->
      <el-table-column label="风险及应对" prop="remark"></el-table-column>
      <el-table-column label="计划开始日期" prop="createDate" width="165">
        <template slot-scope="scope">
          <span v-show="scope.row.planBeginTime">{{
            scope.row.planBeginTime | formatDateFilter
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="计划结束日期" prop="createDate" width="165">
        <template slot-scope="scope">
          <span v-show="scope.row.planEndTime">{{
            scope.row.planEndTime | formatDateFilter
          }}</span>
        </template>
      </el-table-column>
    </el-table>

    <!-- 评论 -->
    <el-divider content-position="center">评论</el-divider>
    <div class="comment" v-if="id">
      <comment :subjectType="subjectType" :subjectId="id"></comment>
    </div>
    <el-dialog
      :title="contentDialogTitle"
      :visible.sync="contentDialog"
      width="60%"
      :before-close="handleContentClose"
      append-to-body
      center
    >
      <span v-show="contentDialogType == 2" v-html="content">{{
        content
      }}</span>
      <div v-show="contentDialogType == 1">
        <div class="bar" style="text-align: left; margin-top: 20px">
          <li>
            <b>项目：</b>
            <span>{{ evaluateRecordIfno.projectName }}</span>
          </li>
          <li>
            <b>评估时点：</b>
            <span>{{ moduleScoreHeaderName }}</span>
          </li>
          <li>
            <b>流程：</b>
            <span>{{ evaluateRecordIfno.projectWorkflowName }}</span>
          </li>
          <li>
            <b>评估人：</b>
            <span>{{ evaluateRecordIfno.evaluatorName }}</span>
          </li>
          <li>
            <b>记录人：</b>
            <span>{{ evaluateRecordIfno.recorderName }}</span>
          </li>
          <li>
            <b>时间：</b>
            <span>{{ evaluateRecordIfno.beginTime | formatDateFilter }}</span>
          </li>
        </div>
        <!-- <h4 v-show="evaluateRecordIfno.projectName">
          {{ evaluateRecordIfno.projectName }}：{{
            evaluateRecordIfno.projectWorkflowName
          }}-{{ evaluateRecordIfno.templateName }}
        </h4>
        <h4 style="text-align: left; margin-top: 20px">
          {{ moduleScoreHeaderName }}
        </h4>
        <h4 style="text-align: left; margin-top: 20px">
          时间：{{ evaluateRecordIfno.beginTime | formatDateFilter }}
        </h4> -->

        <h4 style="text-align: left; margin-top: 20px">评估记录：</h4>
        <el-table
          v-loading="tableLoading"
          ref="multipleTable"
          width="100%"
          border
          :span-method="objectSpanMethod"
          :data="evaluateRecordTableData"
          style="width: 100%; margin-top: 10px"
        >
          <el-table-column label="模块" align="center" fixed="left">
            <template slot-scope="scope" width="160">
              <p>
                {{ scope.row.module }}
              </p>
              <!-- <div>{{ scope.row.subModule }}</div> -->
            </template>
          </el-table-column>
          <el-table-column
            label="评估要素"
            width="180"
            align="center"
            fixed="left"
          >
            <template slot-scope="scope">
              <div style="color: #ccc">
                {{ scope.row.subModule
                }}<span v-show="scope.row.subModule">:</span>
              </div>

              <p>{{ scope.row.question }}</p>
            </template>
          </el-table-column>
          <el-table-column label="评估得分" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.score }}</p>
            </template>
          </el-table-column>
          <el-table-column label="模块评估得分" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.moduleScore }}</p>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="meetingContentDialog = false">取 消</el-button> -->
        <el-button type="primary" @click="contentDialog = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <addTask1
      v-if="add1TaskDalog"
      :add1TaskDalog="add1TaskDalog"
      :type="add1TaskType"
      :relationList="relationList"
      v-on:listenAdd1TaskClose="listenAdd1TaskClose"
    ></addTask1>
    <!-- 文件预览 -->
    <el-dialog
      title="文件预览"
      center
      :visible.sync="previewDialogVisible"
      @close="closePreviewDialog"
      :close-on-click-modal="false"
      style="margin-top: -5vh"
      width="70%"
      append-to-body
    >
      <div class="imgUrl" v-if="previewImgUrl">
        <el-image
          style="width: 100%; height: auto"
          :src="previewImgUrl"
          :preview-src-list="[]"
        >
        </el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closePreviewDialog">确 定</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
// import Vue from "vue";
import {
  queryMeetingDetail,
  addMeeting,
  editMeeting,
  selectListMeetingProject,
  selectListWorkflow,
  selectListInvestor,
} from "@/api/minutesOfMeeting";
import { projectTeam, getEvaluationRecordDetail } from "@/api/projectManage";
import { formatDate } from "@/common/date";
import addTask1 from "@/components/myMission/addTask/addTask1";
import comment from "@/components/comment/comment.vue";

export default {
  name: "meetingDetails",

  components: {
    addTask1,
    comment,
  },

  props: {
    detailsDialog: Boolean,
    id: String,
    type: String,
  },

  data() {
    return {
      title: "详情",
      token: null,
      meetingInfo: {
        projectName: null,
        matterList: [],
        businessType: null,
      },
      searchPorjectListLoading: false,
      searchWorkflowListLoading: false,
      porjectListOptions: null,
      workflowListOptions: null,
      investorwListOptions: null,
      internalUseroptionsList: null,
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      pageNum: 1,
      pageSize: 10,
      total: null,
      pageCount: null,
      loadingTable: false,
      statusList: [
        { key: "未开始", value: 0 },
        { key: "进行中", value: 1 },
        { key: "已完成", value: 2 },
      ],
      queryInfo: {
        id: null,
        meetingMatterId: null,
      },
      contentDialog: false,
      contentDialogTitle: null,
      contentDialogType: null,
      evaluateRecordIfno: {
        projectName: null,
      },
      add1TaskDalog: false,
      add1TaskType: null,
      relationList: null,
      evaluateRecordTableData: null,
      tableLoading: false,
      scoreHeaderName: null,
      moduleScoreHeaderName: null,
      content: null,
      subjectType: "MEETING_DETAIL",
      previewDialogVisible: false,
      previewImgUrl: null,
    };
  },

  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },

  created() {
    if (this.type == "homeClick") {
      this.queryInfo.id = null;
      this.queryInfo.meetingMatterId = this.id;
      this.queryMeetingDetail();
    } else {
      this.queryInfo.id = this.id;
      this.queryMeetingDetail();
    }
  },
  methods: {
    closePreviewDialog() {
      this.previewImgUrl = null;
      this.previewDialogVisible = false;
    },
    viewFile(item, downloadUri) {
      let name = item.originalFileName
      let fileType = name.split(".")[name.split(".").length - 1];
        this.$router.push({
        name: "officeOnLine",
        params: { file: item, url: downloadUri,fileType:fileType },
      });
      return false;
      if (
        fileType == "docx" ||
        fileType == "doc" ||
        fileType == "xlsx" ||
        fileType == "xls" ||
        fileType == "ppt" ||
        fileType == "pptx"
      ) {
        // let url = encodeURIComponent(downloadUri);
        window.open(
          "https://view.officeapps.live.com/op/view.aspx?src=" +
            encodeURI(downloadUri)
        );
      } else if (fileType == "pdf") {
        let origin = window.location.origin;
        let url = origin + "/pdf/web/viewer.html?file=" + downloadUri;
        window.open(encodeURI(url));
      } else if (
        fileType == "jpg" ||
        fileType == "jpeg" ||
        fileType == "png" ||
        fileType == "gif"
      ) {
        this.previewImgUrl = downloadUri;
        this.previewDialogVisible = true;
      } else {
        this.$message({ type: "warning", message: "请下载后查看!" });
        window.open(encodeURI(downloadUri));
      }
    },
    handleExport(item) {
      //下载文件
      var elemIF = document.createElement("iframe");
      elemIF.src = item.url;
      elemIF.style.display = "none";
      document.body.appendChild(elemIF);
    },
    handleContentClose() {
      this.contentDialog = false;
    },
    openContent(title, val, type) {
      this.contentDialogTitle = title;
      this.contentDialogType = type;
      console.log(val);
      if (type == "1") {
        this.evaluateRecordIfno = val;
        this.moduleScoreHeaderName =
          "V" +
          val.workflowVersion +
          val.projectWorkflowName +
          "_" +
          val.workflowNodeName +
          "_" +
          val.taskName;
        let info = {};
        // info.projectId = val.projectId;
        // // info.projectWorkflowId = val.projectWorkflowId;
        // info.taskId = val.taskId;
        // info.templateId = val.templateId;
        info.id = val.id;

        this.getEvaluationRecordDetail(info);
      } else {
        this.content = val;
        this.contentDialog = true;
      }
    },
    getEvaluationRecordDetail(info) {
      var that = this;
      this.tableLoading = true;
      that.evaluateRecordTableData = [];

      getEvaluationRecordDetail(info).then((res) => {
        if (res.success) {
          let info = res.data;
          that.evaluateRecordTableData = info.detailList;
          that.getSpanArr(that.evaluateRecordTableData);
        }
      });
    },
    getSpanArr(data) {
      console.log("data");
      this.spanArr = [];
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          // 如果是第一条记录（即索引是0的时候），向数组中加入１
          this.spanArr.push(1);
          this.pos = 0;
        } else {
          if (data[i].module === data[i - 1].module) {
            // 如果module相等就累加，并且push 0  这里是根据一样的module匹配
            console.log(data[i].module);
            this.spanArr[this.pos] += 1;
            this.spanArr.push(0);
          } else {
            // 不相等push 1
            this.spanArr.push(1);
            this.pos = i;
          }
        }
      }
      this.contentDialog = true;
      this.tableLoading = false;

      this.$forceUpdate();
    },
    // 3、合并行数
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      for (let i = 0; i < this.indexOf.length; i++) {
        if (columnIndex === this.indexOf[i]) {
          const _row = this.spanArr[rowIndex];
          const _col = _row > 0 ? 1 : 0;
          return {
            rowspan: _row,
            colspan: _col,
          };
        }
      }
    },
    addTask() {
      if (this.meetingInfo.businessType == 2) {
        this.relationList = [
          {
            type: 5,
            targetId: this.meetingInfo.id,
          },
          {
            type: 7,
            targetId: this.meetingInfo.businessId,
          },
        ];
      } else if (this.meetingInfo.businessType == 3) {
        this.relationList = [
          {
            type: 1,
            targetId: this.meetingInfo.projectWorkflowDetailId,
          },
          {
            type: 5,
            targetId: this.meetingInfo.id,
          },
        ];
      }

      this.add1TaskType = "add";
      this.add1TaskDalog = true;
    },
    listenAdd1TaskClose(params) {
      console.log(params);
      const { type, isClose, info } = params;
      this.add1TaskDalog = !isClose;
      if (type == "sure") {
        this.queryMeetingDetail();
      }
    },
    handleSizeChange(val) {
      //   this.queryMeetingList(1, val);
    },
    handleCurrentChange(val) {
      if (val > this.pageCount) {
        val = this.pageCount;
      }
      //   this.queryMeetingList(val, this.pageInfo.pageSize);
    },
    //
    projectTeam(cb) {
      projectTeam({}).then((response) => {
        if (response.success) {
          this.internalUseroptionsList = response.data;
        }
      });
      if (cb) {
      }
    },
    //
    choosePorject(id) {
      var that = this;
      console.log(id);
      that.porjectListOptions.forEach(function (item, index) {
        if (id == item.id) {
          that.meetingInfo.projectId = item.id;
          that.meetingInfo.projectName = item.projectName;
        }
      });
    },
    //
    searchPorjectList(cb) {
      console.log(cb);
      selectListMeetingProject({}).then((response) => {
        if (response.success) {
          this.porjectListOptions = response.data;
        }
      });
    },
    selectListInvestor(cb) {
      let name = null;
      selectListInvestor(name).then((response) => {
        if (response.success) {
          this.investorwListOptions = response.data;
        }
      });
    },
    //
    searchWorkflowList(cb) {
      let param = {};
      param.nodeName = null;
      param.projectId = this.meetingInfo.projectId;
      param.projectName = this.meetingInfo.projectName;
      selectListWorkflow(param).then((response) => {
        if (response.success) {
          this.workflowListOptions = response.data;
        }
      });
    },

    queryMeetingDetail() {
      this.loadingTable = true;

      queryMeetingDetail(this.queryInfo).then((res) => {
        if (res.success) {
          let info = res.data;
          this.meetingInfo = info;
          //跟进事项
          this.loadingTable = false;
        }
      });
    },

    //弹窗关闭
    dialogClose() {
      this.formData = {};
      this.$emit("listenDetailsClose", { type: "cancel", isClose: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.bigTitle {
  font-size: 20px;
  margin-bottom: 15px;
}

.bar {
  overflow: hidden;
  padding: 10px;

  li {
    min-height: 30px;
    line-height: 30px;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5px 0;

    .cotent {
      width: 130px !important;
    }

    span {
      display: inline-block;
      width: 120px;
      text-align: right;
    }

    .childWidth {
      width: calc(100% - 130px);
    }
  }

  .width50 {
    width: 50%;
  }

  .width100 {
    width: 100%;

    .contentLeft {
      align-self: baseline;
    }
    .content{
      overflow-x: auto;
    }

    p {
      width: calc(100% - 120px);
      float: right;

      span {
        display: inline;
        padding: 0 10px;
      }
    }
  }
}

.buttonGrop {
  margin-top: 20px;
  display: flex;
  justify-content: center;

  button {
    margin-right: 100px;
  }
}

.viewFile{
  cursor: pointer;
  z-index: 10000;
}
.attachmentListText {
  align-self: flex-start;
}
</style>
