<!--选择钉钉用户-->
<template>
  <div>
    <div class="process-user">
      <div
        v-for="(item, index) in selectedList"
        :key="index"
        class="selected-user"
      >
        <el-avatar
          shape="square"
          size="small"
          :src="item.avatar || defaultAvatar"
          class="avatar"
        />
        <span class="name">{{ item.realName }}</span>
        <i
          v-if="item.uid !== loginId"
          class="el-icon-close delete"
          @click="handleDeleteUser(item, index)"
        ></i>
      </div>

      <el-button
        v-if="allowAdd"
        class="add-button"
        size="normal"
        round
        icon="el-icon-plus"
        @click="openTransferDialog"
        >添加</el-button
      >
    </div>

    <el-dialog
      :visible="transferDialogVisible"
      @close="transferDialogVisible = false"
      append-to-body
    >
      <el-transfer
        v-model="transferValue"
        filterable
        :filter-method="transferFilterMethod"
        :data="transferData"
        :titles="['可选人员', '已选人员']"
        :props="{ key: 'uid', label: 'label', disabled: 'disabled' }"
      >
      </el-transfer>

      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancelTransferDialog">取 消</el-button>
        <el-button type="primary" @click="onConfirmTransferDialog"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { projectTeam } from "@/api/projectManage";

export default {
  name: "selectProcessUsers",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    allowAdd: {
      type: Boolean,
      default: true,
    },
    editUserList: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      loginId: localStorage.getItem("userId"),
      defaultAvatar:
        "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
      selectedList: [],
      userList: [],
      transferDialogVisible: false,
      transferValue: [],
      transferData: [],
      userIdList: [],
    };
  },
  watch: {
    editUserList: {
      handler(newVal, oldVal) {
        if (!newVal) return;
        if (this.type == "edit") {
          let editUserList = JSON.parse(JSON.stringify(newVal));
          console.log(editUserList);
          this.userIdList = editUserList.map((x) => x.userId);
        }
        // console.log(userIdList)
        // this.loadDefaultUserIdList(userIdList);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // if (this.editUserList) {
    //   let editUserList = JSON.parse(JSON.stringify(this.editUserList));
    //   let userIdList = editUserList.map((x) => x.userId);
    //   this.loadDefaultUserIdList(userIdList);
    // } else {
    //   this.loadDefaultUserIdList();
    // }
    if (this.type == "add") {
      this.loadDefaultUserIdList();
    }
    this.$emit("input", this.userIdList);
    this.getProjectTeam();
    // this.userIdList = this.editUserList.map((x) => x.userId);
  },
  methods: {
    loadDefaultUserIdList(userIdList) {
      if (!userIdList || userIdList.length === 0) {
        this.userIdList = [this.loginId];
      } else {
        if (this.userIdList.indexOf(this.loginId) < 0) {
          this.userIdList.unshift(this.loginId);
        } else if (this.userIdList.indexOf(this.loginId) > 0) {
          this.userIdList.splice(this.userIdList.indexOf(this.loginId), 1);
          this.userIdList.unshift(this.loginId);
        }
      }

      if (this.userList && this.userList.length > 0) {
        console.log("userIdList", this.userIdList);
        console.log("userList", this.userList);
        this.selectedList = this.userList.filter(
          (x) => this.userIdList.indexOf(x.uid) >= 0
        );
        console.log("selectedList", this.selectedList);
      } else {
        this.userIdList.forEach((x) =>
          this.selectedList.push({ uid: x, realName: x })
        );
      }
    },
    getProjectTeam() {
      projectTeam({}).then((res) => {
        if (res.success) {
          this.userList = res.data || [];
          this.selectedList = this.userList.filter(
            (x) => this.userIdList.indexOf(x.uid) >= 0
          );
          this.emitValue();
        }
      });
    },
    openTransferDialog() {
      this.transferData = [...this.userList];
      //去除自己
      this.transferData.splice(
        this.transferData.findIndex((x) => x.uid === this.loginId),
        1
      );
      this.transferData.forEach((x) => {
        x.label = x.dingTalkUnionId
          ? x.realName
          : x.realName + "（未绑定钉钉）";
        x.disabled = !x.dingTalkUnionId;
      });

      this.transferDialogVisible = true;
    },
    transferFilterMethod(query, item) {
      return item.realName.indexOf(query) > -1 || item.uid.indexOf(query) > -1;
    },
    onCancelTransferDialog() {
      this.transferDialogVisible = false;
    },
    onConfirmTransferDialog() {
      this.selectedList.splice(1);
      this.transferValue.forEach((x) => {
        let user = this.userList.find((y) => y.uid === x);
        console.log(JSON.stringify(user));
        this.selectedList.push(user);
      });
      this.transferDialogVisible = false;

      this.emitValue();
    },
    handleDeleteUser(user, index) {
      //删除已选
      this.selectedList.splice(index, 1);
      //同时删除穿梭框选中项
      let transferValueIndex = this.transferValue.findIndex(
        (x) => x === user.uid
      );
      this.transferValue.splice(transferValueIndex, 1);

      this.emitValue();
    },
    emitValue() {
      let userIdList = this.selectedList.map((x) => x.uid);
      this.$emit("input", userIdList);
      this.$emit("change", this.selectedList);
    },
    reset() {
      this.loadDefaultUserIdList();
      this.transferValue = [];
    },
  },
};
</script>

<style scoped lang="scss">
.process-user {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .selected-user {
    display: inline-flex;
    align-items: center;
    background-color: #eee;
    padding: 0 5px;
    margin: 0 5px 5px 0;

    .avatar {
      margin-right: 5px;
    }

    .name {
      margin-right: 5px;
    }

    .delete {
      color: #409eff;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .add-button {
    margin-bottom: 5px;
  }
}

.el-transfer {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
