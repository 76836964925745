<template>
  <el-dialog
    :title="title"
    width="700px"
    :visible="dialogVisible"
    @close="closeDialog"
    append-to-body
  >
    <el-form
      :model="calendarForm"
      ref="inputInvestor"
      label-width="170px"
      :rules="rules"
    >
      <el-row>
        <el-col>
          <el-form-item label="标题" prop="title">
            <el-input type="text" v-model="calendarForm.title"></el-input>
          </el-form-item>
          <el-form-item label="日程类别" prop="officeMode">
            <el-select
              v-model="calendarForm.officeMode"
              @visible-change="selectListDict($event, 'office_mode', 2)"
              filterable
              placeholder="请选择"
              clearable
              no-data-text="加载中···"
              @change="onOfficeModeChange"
              disabled
            >
              <el-option
                v-for="item in dictList2"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="事由类型" prop="type">
            <el-select
              v-model="calendarForm.type"
              filterable
              placeholder="请选择"
              clearable
              no-data-text="加载中···"
              disabled
            >
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="calendarForm.officeModeText + '事由'"
            prop="reason"
          >
            <el-input type="text" v-model="calendarForm.reason"></el-input>
          </el-form-item>
          <!-- <div class="memberList">
            <span v-for="(item, index) in selectUserList" :key="index">
              {{ item.realName
              }}<i class="el-icon-delete-solid" @click="delectMember(item)"></i>
            </span>
          </div> -->
          <el-form-item label="相关人员" prop="userIdList">
            <select-process-users
              ref="selectProcessUsers"
              v-model="calendarForm.userIdList"
              :allow-add="currOfficeMode.code !== 'ask_for_leave'"
              @change="onProcessUsersChange"
              :editUserList="calendarForm.userList"
              :type="type"
            />
          </el-form-item>
          <el-form-item
            label="时间"
            prop="startAendTime"
            v-if="currOfficeMode.code !== 'business_trip'"
          >
            <el-date-picker
              v-model="calendarForm.startAendTime"
              type="datetimerange"
              placeholder="结束日期"
              value-format="timestamp"
              format="yyyy-MM-dd HH:mm"
              :picker-options="pickerOptionsend"
              align="right"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['9:00:00', '18:00:00']"
              @change="onStartEndTimeChange"
            >
            </el-date-picker>
          </el-form-item>
          <!-- 日程类型为出差时 -->
          <el-form-item label="行程">
            <el-collapse
              v-model="collapseActiveNames"
              @change="collapseHandleChange"
            >
              <el-collapse-item title="" name="1">
                <template v-if="currOfficeMode.code === 'business_trip'">
                  <div
                    v-for="(item, index) in tripList"
                    :key="index"
                    class="business-trip"
                  >
                    <div class="flexJsbAc">
                      <span style="font-weight: 500"
                        >行程({{ index + 1 }})</span
                      >
                      <!-- <div style="text-align: right">
                        <el-button
                          v-if="index >= 1"
                          size="mini"
                          type="danger"
                          @click="deleteBusinessTrip(index)"
                          >删除行程</el-button
                        >
                      </div> -->
                    </div>

                    <div class="flexJfAc item" label="交通工具">
                      <span class="title">交通工具</span>
                      <el-select
                        v-model="item.transport"
                        @change="onBusinessTripTransportChange(item)"
                        disabled
                      >
                        <el-option
                          v-for="(v, i) in transportOptions"
                          :key="i"
                          :label="v"
                          :value="v"
                        ></el-option>
                      </el-select>
                    </div>
                    <div class="flexJfAc item" label="单程往返">
                      <span class="title">单程往返</span>
                      <el-select v-model="item.oneWayOrRoundTrip" disabled>
                        <el-option
                          v-for="(v, i) in oneWayOrRoundTripOptions"
                          :key="i"
                          :label="v"
                          :value="v"
                        ></el-option>
                      </el-select>
                    </div>
                    <div class="flexJfAc item" label="出发城市">
                      <span class="title">出发城市</span>
                      <train-city-suggest
                        v-if="item.transport === '火车'"
                        v-model="item.fromCity"
                        :fromSelectCity="item.fromCity"
                        type="edit"
                      />
                      <flight-city-suggest
                        v-else-if="item.transport === '飞机'"
                        v-model="item.fromCity"
                        :fromSelectCity="item.fromCity"
                        type="edit"
                      />
                      <el-input
                        v-else
                        v-model="item.fromCity"
                        placeholder="请输入出发城市"
                      />
                    </div>
                    <div class="flexJfAc item" label="目的城市">
                      <span class="title">目的城市</span>

                      <train-city-suggest
                        v-if="item.transport === '火车'"
                        v-model="item.toCity"
                        :fromSelectCity="item.toCity"
                        type="edit"
                      />
                      <flight-city-suggest
                        v-else-if="item.transport === '飞机'"
                        v-model="item.toCity"
                        :fromSelectCity="item.toCity"
                        type="edit"
                      />
                      <el-input
                        v-else
                        v-model="item.toCity"
                        placeholder="请输入目的城市"
                      />
                    </div>
                    <div class="flexJfAc item" label="开始时间">
                      <span class="title">开始时间</span>
                      <el-date-picker
                        v-model="item.startTime"
                        type="datetime"
                        placeholder="选择日期"
                        format="yyyy-MM-dd HH:mm"
                        value-format="timestamp"
                        default-time="09:00:00"
                        style="width: 100% !important"
                        @change="onBusinessTripTimeChange(item)"
                        disabled
                      />
                      <!--                <el-select v-model="item.startTimeAM" style="width: 49%!important;" @change="onBusinessTripTimeChange(item)">-->
                      <!--                  <el-option label="上午" value="AM"></el-option>-->
                      <!--                  <el-option label="下午" value="PM"></el-option>-->
                      <!--                </el-select>-->
                    </div>
                    <div class="flexJfAc item" label="结束时间">
                      <span class="title">开始时间</span>

                      <el-date-picker
                        v-model="item.endTime"
                        type="datetime"
                        placeholder="选择日期"
                        format="yyyy-MM-dd HH:mm"
                        value-format="timestamp"
                        default-time="18:00:00"
                        style="width: 100% !important"
                        @change="onBusinessTripTimeChange(item)"
                        disabled
                      />
                      <!--                <el-select v-model="item.endTimeAM" style="width: 49%!important;" @change="onBusinessTripTimeChange(item)">-->
                      <!--                  <el-option label="上午" value="AM"></el-option>-->
                      <!--                  <el-option label="下午" value="PM"></el-option>-->
                      <!--                </el-select>-->
                    </div>
                    <div class="flexJfAc item" label="时长（小时）">
                      <span class="title">时长(小时)</span>
                      <el-input
                        v-model="item.duration"
                        placeholder="根据排班自动计算时长"
                        disabled
                      />
                    </div>
                  </div>

                  <!-- <div class="flexAcJcfe">
                    <el-button
                      icon="el-icon-plus"
                      size="mini"
                      style="margin: 0  0 20px 0"
                      @click="addBusinessTrip"
                      >增加行程</el-button
                    >
                  </div> -->

                  <div class="flexJfAc item" label="出差时长(小时)" prop="duration">
                    <span class="title">出差时长(小时)</span>
                    <el-input
                      v-model="calendarForm.duration"
                      placeholder="自动计算"
                      disabled
                    ></el-input>
                  </div>
                </template>
              </el-collapse-item>
            </el-collapse>
          </el-form-item>
          <el-form-item label="关联的项目或投资机构" prop="businessList">
            <div class="flexJsbAc">
              <el-select
                v-model="calendarForm.businessList"
                @visible-change="searchPorjectList($event)"
                @change="businessSelectChange"
                filterable
                placeholder="请选择项目或投资机构"
                clearable
                multiple
                no-data-text="加载中···"
              >
                <el-option
                  v-for="item in businessSelectList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="预期产出" prop="output">
            <el-select
              v-model="calendarForm.output"
              @visible-change="selectListDict($event, 'schedule_output', 3)"
              filterable
              placeholder="请选择"
              clearable
              no-data-text="加载中···"
            >
              <el-option
                v-for="item in dictListScheduleOutput"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="地点" prop="place">
            <el-input type="text" v-model="calendarForm.place"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="note">
            <el-input type="text" v-model="calendarForm.note"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-alert
      title="提示"
      type="info"
      description="日程创建后，出差/外出/请假类型将同步创建钉钉申请流程，且钉钉流程一经创建，后续即便修改日程信息也将无法更改。"
      show-icon
      :closable="false"
    >
    </el-alert>
    <div class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button @click="addCalendarThing" type="primary">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { projectTeam } from "@/api/projectManage";
import { selectListDict } from "@/api/dataManagement";
import { getTaskSelectList } from "@/api/myMission";

import addTaskEdit from "@/components/myMission/addTask/addTask";

import {
  addCalendarThing,
  editCalendarThing,
  getBusinessSelectList,
  calculateApproveDuration,
} from "@/api/calendar.js";
import {
  formatDateYMD,
  formatTime,
  formatdatetime,
  formatDate,
} from "@/common/date.js";
import { selectListMeetingProject } from "@/api/minutesOfMeeting";
import SelectProcessUsers from "@/components/officeAutomation/selectProcessUsers";
import TrainCitySuggest from "@/components/officeAutomation/trainCitySuggest";
import FlightCitySuggest from "@/components/officeAutomation/flightCitySuggest";
export default {
  name: "calendarEdit",

  data() {
    var validateOutput = (rule, value, callback) => {
      if (
        !value &&
        (this.calendarForm.officeMode === "3" ||
          this.calendarForm.officeMode === "4")
      ) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    };
    return {
      title: "新增",
      type: null,
      calendarForm: {
        userIdList: [],
        businessList: [],
      },
      searchName: {},
      searchMemberList: [],
      beginDate: "",
      begintime: "",
      pickerStartOptionsend: {
        disabledDate: (time) => {
          return time.getTime() < new Date().getTime() - 24 * 60 * 60 * 1000;
        },
      },
      pickerOptionsend: {
        disabledDate: (time) => {
          return time.getTime() < this.calendarForm.beginTime - 8.64e7;
        },
      },
      dictList: [],
      dictList2: [],
      typeList: [],
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        type: [
          { required: true, message: "请选择日程类别", trigger: "change" },
        ],
        officeMode: [
          { required: true, message: "请选择办公形式", trigger: "change" },
        ],
        businessList: [
          {
            required: false,
            message: "请选择关联项目或投资机构",
            trigger: "change",
          },
        ],
        output: [
          {
            required: false,
            validator: validateOutput,
            message: "请选择预期产出",
            trigger: "change",
          },
        ],
        startAendTime: [
          { required: true, message: "请选择日期", trigger: "change" },
        ],
        reason: [{ required: true, message: "请输入事由", trigger: "blur" }],

        userIdList: [
          { required: true, message: "请选择相关人员", trigger: "change" },
        ],
      },
      taskSelectList: [],
      addTaskEditType: "other2",
      internalUseroptionsList: null,
      businessSelectList: [],
      dictListScheduleOutput: [],
      typeTitle: null,
      currOfficeMode: {},
      tripList: [],
      transportOptions: ["飞机", "火车", "汽车", "其他"],
      oneWayOrRoundTripOptions: ["单程", "往返"],
      collapseActiveNames: [],
    };
  },
  components: {
    addTaskEdit,
    SelectProcessUsers,
    FlightCitySuggest,
    TrainCitySuggest,
  },
  props: {
    dialogVisible: Boolean,
    calendarFormBefore: Object,
    selectUserList: Array,
  },
  created() {},
  activated() {},
  mounted() {
    let that = this;
    this.selectListDict(true, "schedule_type", 1);
    this.selectListDict(true, "office_mode", 2);
    this.selectListDict(true, "schedule_output", 3);
    this.searchPorjectList(true);
    this.projectTeam(true);

    this.calendarForm = JSON.parse(JSON.stringify(this.calendarFormBefore));

    if (this.calendarFormBefore && this.calendarFormBefore.id) {
      this.type = "edit";
      this.title = "编辑";
      this.calendarForm.userIdList = JSON.parse(
        JSON.stringify(this.selectUserList)
      ).map((i) => i.userId);
      if (this.calendarForm.businessList) {
        this.calendarForm.businessList = JSON.parse(
          JSON.stringify(this.calendarFormBefore.businessList)
        ).map((i) => i.businessId);
      }
      this.calendarForm.startAendTime = [
        this.calendarForm.beginTime,
        this.calendarForm.endTime,
      ];

      let formComponentValues = JSON.parse(
        JSON.stringify(this.calendarFormBefore.tripList)
      );
      console.log(formComponentValues);
      that.tripList = [];
      if (formComponentValues) {
        formComponentValues.map(function (item, index) {
          console.log(item);
          let one = {};
          if (item) {
            one.transport = item.交通工具;
            one.oneWayOrRoundTrip = item.单程往返;
            one.fromCity = item.出差城市;
            one.toCity = item.目的城市;
            one.startTime = formatdatetime(new Date(item.开始时间));
            one.endTime = formatdatetime(new Date(item.结束时间));
            one.duration = item[`时长(小时)`];
            that.tripList.push(one);
            that.$forceUpdate();
          }
        });
      }
    } else {
      this.type = "add";
      this.title = "新增";
    }
  },

  watch: {
    "calendarForm.officeMode": {
      handler(newVal) {
        if (newVal === "3" || newVal === "4") {
          this.rules.output[0].required = true;
        } else {
          this.rules.output[0].required = false;
        }
      },
    },
  },

  methods: {
    collapseHandleChange(val) {
      console.log(val);
    },
    async searchPorjectList(cb) {
      if (cb) {
        let info = {};
        const response = await selectListMeetingProject(info);
        if (response.success) {
          this.businessSelectList = response.data;
        }
      }
    },
    addBusinessTrip() {
      this.tripList = [
        ...this.tripList,
        {
          transport: null, //交通工具
          oneWayOrRoundTrip: null, //单程往返
          fromCity: null, //出发城市
          toCity: null, //目的城市
          startTime: null, //开始时间
          endTime: null, //结束时间
          startTimeAM: "AM", //上午或下午
          endTimeAM: "AM", //上午或下午
          duration: null, //时长（小时）
        },
      ];
    },
    deleteBusinessTrip(index) {
      this.$confirm("确定删除行程" + (index + 1) + "吗？").then(() => {
        this.tripList.splice(index, 1);
      });
    },
    onBusinessTripTransportChange(item) {
      console.log(item);
      item.fromCity = null;
      item.toCity = null;
    },
    onBusinessTripTimeChange(item) {
      console.log(item);
      if (!item.startTime || !item.endTime) {
        return;
      }

      this.preCalcDuration(
        item.startTime,
        item.endTime,
        item.startTimeAM,
        item.endTimeAM,
        (duration) => {
          item.duration = duration;
          this.calendarForm.duration = this.tripList.reduce(
            (sum, item) => sum + item.duration * 1,
            0
          );
          console.log(this.calendarForm.duration);
        }
      );
    },
    //监听上传文件弹窗关闭
    listenAddFileClose(params) {
      const { type, isClose, mold } = params;
      if (type === "sure") {
        let fileUrls = [];
        let file = JSON.parse(JSON.stringify(params.files));
        file.forEach((item, index) => {
          fileUrls.push(item.url);
        });
        console.log(fileUrls);
        this.calendarForm.pictures = fileUrls;
      }
    },
    onProcessUsersChange(users) {
      console.log("onProcessUsersChange", users);
      this.calendarForm.dingTalkUserIdList = users.map((x) => x.dingTalkUserId);
    },
    onOfficeModeChange(value) {
      let that = this;
      this.calendarForm.type = null;
      // console.log("onOfficeModeChange", JSON.stringify(value));
      this.currOfficeMode = this.dictList2.find((x) => x.value === value) || {};

      that.dictList2.map(function (item, index) {
        if (value == item.value) {
          that.typeTitle = item.name;
          that.typeList = item.children;
        }
      });
      console.log("currOfficeMode", this.currOfficeMode);

      this.$refs.selectProcessUsers.reset();

      if (this.currOfficeMode.code === "ask_for_leave") {
        //请假
        this.rules.startAendTime.required = true;
        this.rules.note.message = "请在备注中输入请假事由";
        this.addFileInfo.relevantFile = "请假图片";
      } else if (this.currOfficeMode.code === "business_trip") {
        //出差
        this.rules.startAendTime.required = false;
        this.rules.note.message = "请在备注中输入出差事由";
        this.addFileInfo.relevantFile = "出差图片";
      } else if (this.currOfficeMode.code === "business_out") {
        //外出
        this.rules.startAendTime.required = true;
        this.rules.note.message = "请在备注中输入外出事由";
        this.addFileInfo.relevantFile = "外出图片";
      } else {
        this.rules.startAendTime.required = true;
        this.rules.note.message = "请输入备注";
        this.addFileInfo.relevantFile = "图片";
      }
    },
    onStartEndTimeChange(dateTime) {
      console.log("dateTime", dateTime);
      if (dateTime) {
        let startTime = dateTime[0];
        let endTime = dateTime[1];
        this.preCalcDuration(startTime, endTime, null, null, (duration) => {
          this.calendarForm.duration = duration;
        });
      }
    },

    /**
     * 预计算时长
     * 参考文档：https://open.dingtalk.com/document/orgapp-server/calculate-duration-based-on-attendance-scheduling
     * @param fromTime 开始时间戳，开始时间不能早于当前时间前31天。
     * @param toTime 结束时间戳，结束时间减去开始时间的天数不能超过31天。
     * @param fromTimeAm AM/PM
     * @param toTimeAm AM/PM
     * @param cb 回调函数，参数为时长
     */
    preCalcDuration(fromTime, toTime, fromTimeAm, toTimeAm, cb) {
      console.log("startTime: ", fromTime, "endTime: ", toTime);

      //审批单类型1：加班，2：出差，3：请假
      //fromTime: 开始时间，支持的时间格式 2019-08-15/2019-08-15 AM/2019-08-15 12:43。开始时间不能早于当前时间前31天
      //toTime: 结束时间，支持的时间格式 2019-08-15/2019-08-15 AM/2019-08-15 12:43。结束时间减去开始时间的天数不能超过31天。biz_type为1时结束时间减去开始时间不能超过1天
      //durationUnit: 时长单位，支持的day,halfDay,hour，biz_type为1时仅支持hour。时间格式必须与时长单位对应，2019-08-15对应day，2019-08-15 AM对应halfDay，2019-08-15 12:43对应hour
      //calculateMode: 计算方法，0：按自然日计算，1：按工作日计算
      let bizType, durationUnit, calculateMode;
      let code = this.currOfficeMode.code;
      if (code === "ask_for_leave") {
        //请假
        bizType = 3;
        durationUnit = "hour";
        fromTime = formatDate(new Date(fromTime), "yyyy-MM-dd hh:mm");
        toTime = formatDate(new Date(toTime), "yyyy-MM-dd hh:mm");
        calculateMode = 1;
      } else if (code === "business_trip") {
        //出差
        bizType = 2;
        durationUnit = "hour";
        fromTime = formatDate(new Date(fromTime), "yyyy-MM-dd hh:mm");
        toTime = formatDate(new Date(toTime), "yyyy-MM-dd hh:mm");
        calculateMode = 1;
      } else if (code === "business_out") {
        //外出
        bizType = 3;
        durationUnit = "hour";
        fromTime = formatDate(new Date(fromTime), "yyyy-MM-dd hh:mm");
        toTime = formatDate(new Date(toTime), "yyyy-MM-dd hh:mm");
        calculateMode = 1;
      } else {
        return;
      }

      let params = {
        bizType,
        fromTime,
        toTime,
        durationUnit,
        calculateMode,
      };

      calculateApproveDuration(params).then((res) => {
        if (res.success) {
          cb && cb(res.data.duration);
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    projectTeam(cb) {
      if (cb) {
        projectTeam({}).then((response) => {
          if (response.success) {
            this.internalUseroptionsList = response.data;
          }
        });
      }
    },

    chooseInvestor(id) {
      this.$forceUpdate();
    },

    getTaskSelectList(cb) {
      if (cb) {
        let info = { mode: 2 };

        getTaskSelectList(info).then((response) => {
          if (response.success) {
            let list = response.data;

            list.map(function (item, index) {
              item.name = item.name + "——" + item.projectName;
            });
            this.taskSelectList = list;
          }
        });
      }
    },
    async selectListDict(cb, type, listType) {
      var that = this;
      if (cb) {
        let info = { group: type };
        const list = await selectListDict(info);
        if (listType === 1) {
          this.dictList = list.data;
        } else if (listType === 2) {
          this.dictList2 = list.data;
          that.dictList2.map(function (item, index) {
            if (that.calendarForm.officeMode == item.value) {
              that.typeList = item.children;
              that.currOfficeMode.code = item.code;
              that.calendarForm.officeModeCode = item.code;
              that.calendarForm.officeModeName = item.name;
            }
          });
        } else if (listType === 3) {
          this.dictListScheduleOutput = list.data;
        }
      }
    },
    //选择了开始时间
    changeBeginTime() {
      this.calendarForm.beginTime = formatdatetime(
        formatDateYMD(this.beginDate) + " " + formatTime(this.begintime)
      );
    },

    //新增事件
    addCalendarThing() {
      let that = this;
      this.$refs["inputInvestor"].validate((valid) => {
        if (valid) {
          if (that.calendarForm.businessList.length == 0) {
            if (!that.calendarForm.note) {
              that.$message({
                type: "warning",
                message:
                  "您未选择【关联的项目或投资机构】，请在备注中填写原因！",
              });
              return false;
            }
          }
          // if (this.calendarForm.beginTime >= this.calendarForm.endTime) {
          //   this.$message.error("开始日期不能大于结束日期，请重新选择日期");
          // } else {
          //   this.calendarForm.calendarDate = this.calendarForm.beginTime;
          //   this.calendarForm.title = this.calendarForm.title.replace(
          //     /\s*/g,
          //     ""
          //   );
          // }

          let scheduleType = this.typeList.find(
            (x) => x.value === this.calendarForm.type
          );

          var calendarForm = JSON.parse(JSON.stringify(this.calendarForm));
          var calendarForm = { ...calendarForm };
          calendarForm.beginTime = calendarForm.startAendTime[0];
          calendarForm.endTime = calendarForm.startAendTime[1];
          calendarForm.title = calendarForm.title.replace(/\s*/g, "");
          calendarForm.officeModeCode = that.currOfficeMode.code;
          calendarForm.officeModeName = that.currOfficeMode.name;
          calendarForm.typeCode = scheduleType.code;
          calendarForm.typeName = scheduleType.name;
          calendarForm.tripList = that.tripList;

          let businessIdList = JSON.parse(
            JSON.stringify(calendarForm.businessList)
          );
          calendarForm.businessList = [];
          if (businessIdList.length > 0) {
            businessIdList.map(function (item, index) {
              that.businessSelectList.map(function (item2, index) {
                if (item == item2.id) {
                  calendarForm.businessList.push({
                    businessId: item2.id,
                    businessType: 2,
                    // businessType: item2.type == "0" ? "3" : item2.type == "1" ? "2" : item2.type == "2" ? "2": item2.type == "11" ? "2" :"" ,
                  });
                }
              });
            });
          } else {
            calendarForm.businessId = null;
            calendarForm.businessName = null;
          }

          console.log(calendarForm);
          let formComponentsValues = [];
          let scheduleName = scheduleType.name;
          let startTime = formatDate(
            new Date(calendarForm.beginTime),
            "yyyy-MM-dd hh:mm"
          );
          let endTime = formatDate(
            new Date(calendarForm.endTime),
            "yyyy-MM-dd hh:mm"
          );

          if (calendarForm.officeModeCode === "ask_for_leave") {
            formComponentsValues.push({
              name: JSON.stringify(["开始时间", "结束时间"]),
              value: JSON.stringify([
                startTime,
                endTime,
                calendarForm.duration,
                "hour",
                scheduleName,
                "请假类型",
              ]),
            });
            formComponentsValues.push({
              name: "请假事由",
              value: calendarForm.reason,
            });
            formComponentsValues.push({
              name: "图片",
              value: JSON.stringify(calendarForm.pictures),
            });
          } else if (calendarForm.officeModeCode === "business_out") {
            formComponentsValues.push({ name: "开始时间", value: startTime });
            formComponentsValues.push({ name: "结束时间", value: endTime });
            formComponentsValues.push({
              name: "时长(小时)",
              value: calendarForm.duration,
            });
            formComponentsValues.push({
              name: "外出事由",
              value: calendarForm.reason,
            });
            formComponentsValues.push({
              name: "同行人",
              value: JSON.stringify(calendarForm.dingTalkUserIdList),
            });
            formComponentsValues.push({
              name: "图片",
              value: JSON.stringify(calendarForm.pictures),
            });
          } else if (calendarForm.officeModeCode === "business_trip") {
            let tripValues = []; //出差行程
            let minTripStartTime = null,
              maxTripEndTime = null;

            for (let i = 0; i < this.tripList.length; i++) {
              let trip = this.tripList[i];
              let tripStartTime = formatDate(
                new Date(trip.startTime),
                "yyyy-MM-dd hh:mm"
              );
              let tripEndTime = formatDate(
                new Date(trip.endTime),
                "yyyy-MM-dd hh:mm"
              );

              if (!minTripStartTime || trip.startTime < minTripStartTime) {
                minTripStartTime = trip.startTime;
              }
              if (!maxTripEndTime || trip.endTime > maxTripEndTime) {
                maxTripEndTime = trip.endTime;
              }

              let rowValue = []; //单个出差行程
              rowValue.push({ name: "交通工具", value: trip.transport });
              rowValue.push({
                name: "单程往返",
                value: trip.oneWayOrRoundTrip,
              });
              rowValue.push({ name: "出差城市", value: trip.fromCity });
              rowValue.push({ name: "目的城市", value: trip.toCity });
              rowValue.push({ name: "开始时间", value: tripStartTime });
              rowValue.push({ name: "结束时间", value: tripEndTime });
              rowValue.push({ name: "时长(小时)", value: trip.duration });

              // tripValues.push({rowValue: rowValue});
              tripValues.push(rowValue);
            }

            // calendarForm.beginTime = formatDate(
            //   new Date(minTripStartTime),
            //   "yyyy-MM-dd"
            // );
            // calendarForm.endTime = formatDate(
            //   new Date(maxTripEndTime),
            //   "yyyy-MM-dd"
            // );
            calendarForm.beginTime = minTripStartTime;
            calendarForm.endTime = maxTripEndTime;

            let formValues = [];
            formValues.push({ name: "出差事由", value: calendarForm.reason }); //出差事由
            formValues.push({
              name: "行程",
              value: JSON.stringify(tripValues),
            }); //行程
            formValues.push({
              name: "出差时长(小时)",
              value: calendarForm.duration,
            }); //出差天数
            formValues.push({
              name: "出差备注",
              value: calendarForm.note || "",
            }); //出差备注
            formValues.push({
              name: "同行人",
              value: JSON.stringify(calendarForm.dingTalkUserIdList),
            }); //同行人, value示例："张三,李四"，extValue示例："[{\"emplId\":\"013955675313784319\",\"name\":\"张三\",\"avatar\":\"\"},{\"emplId\":\"013955675313784320\",\"name\":\"李四\",\"avatar\":\"\"}]"

            // formComponentsValues.push({name: "商旅出差", value: JSON.stringify(formValues)});
            formComponentsValues = formValues;
          }

          calendarForm.formComponentsValues = formComponentsValues;
          if (this.calendarForm.id) {
            editCalendarThing(calendarForm).then((response) => {
              this.$message.success("编辑成功");
              // this.calendarForm = {};
              this.$refs["inputInvestor"].resetFields();
              this.$emit("listenEditClose", {
                close: true,
                editType: "edit",
              });
            });
          } else {
            addCalendarThing(this.calendarForm).then((response) => {
              this.$message.success("添加成功");
              // this.calendarForm = {};
              this.$refs["inputInvestor"].resetFields();
              this.$emit("listenEditClose", { close: true, editType: "add" });
            });
          }
        }
      });
    },

    //搜索人员
    searchMember(v) {
      if (v.length > 0) {
        projectTeam({ realName: v }).then((response) => {
          this.searchMemberList = response.data;
        });
      } else {
        this.searchMemberList = [];
      }
    },

    //关闭弹窗
    closeDialog() {
      this.$emit("listenEditClose", { close: true });
    },

    // 获取关联项目或投资机构列表
    async getBusinessSelectList(e) {
      if (e) {
        const businessList = await getBusinessSelectList();
        this.businessSelectList = businessList;
      }
    },

    // 关联项目改变
    businessSelectChange(value) {
      for (const business of this.businessSelectList) {
        if (business.id === value) {
          this.calendarForm.businessType = business.businessType;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.memberList {
  padding: 5px;

  i {
    margin-left: 3px;
  }
}

.el-form {
  .el-form-item {
    width: 100%;

    .el-input {
      width: 100% !important;
    }

    .el-select {
      width: 100%;
    }

    .el-date-picker {
      width: 100% !important;
    }
  }
}
.business-trip {
  margin-bottom: 40px;

  .item {
    margin: 15px 0;
    width: 95%;
    .title {
      width: 128px;
      text-align: right;
      margin-right: 15px;
    }
  }
}
 .item {
    margin: 15px 0;
    width: 95%;
    .title {
      width: 128px;
      text-align: right;
      margin-right: 15px;
    }
  }
.dialog-footer {
  margin-top: 20px;
}
</style>
