<template>
  <div>
    <div class="bar" v-if="formData != null">
      <!--      <li class="width100">
        <span class="lableTitle">查询范围：</span>
        <div class="flexJfAc childWidth">
          <el-radio
            @change="userScopeChange"
            v-model="userSet.value.scope"
            v-for="(item, index) in typeList"
            :key="index"
            :label="item.lable"
          >{{ item.name }}
          </el-radio
          >
          <div class="" style="width: 62%" v-show="userSet.value.scope === 3">
            <span> </span>
            <el-cascader
              filterable
              clearable
              v-model="userSet.value.userIdList"
              :options="useDeptList"
              :show-all-levels="false"
              :props="{
                multiple: true,
                value: 'uid',
                label: 'realName',
                children: 'userList',
              }"
              @visible-change="getUseDeptList($event)"
              @change="userIdListChange"
              style="width: 100%"
            >
              &lt;!&ndash; <template slot-scope="{ node, data }">
                <span>{{ data.realName }}</span>
                <span> ({{ data.children.length }}) </span>
              </template> &ndash;&gt;
            </el-cascader>
          </div>
          <el-button
            style="margin-left: 20px"
            type="primary"
            @click="setOptionUserInfo"
          >保存偏好
          </el-button
          >
        </div>
      </li>-->
    </div>
    <div
      style="
        width: 100%;
        height: 60%;
        margin-left: 0px;
        margin-top: 20px;
        position: relative;
      "
    >
      <el-row type="flex" justify="space-between" style="margin: 0 0 20px 0">
        <div style="padding-left: 0px">
          <el-radio-group
            v-model="calendarMode"
            @change="calendarModeChange"
            size="mini"
          >
            <el-radio-button label="day">日</el-radio-button>
            <el-radio-button label="week">周</el-radio-button>
            <el-radio-button label="month">月</el-radio-button>
          </el-radio-group>

          <el-button-group
            class="date-ctrl"
            style="margin-left: 30px"
            v-if="calendarMode === 'week' || calendarMode === 'day'"
          >
            <el-button
              icon="el-icon-arrow-left"
              size="mini"
              @click="prevWeek"
              round
              >上一周</el-button
            >
            <el-button size="mini" @click="thisWeek">今天</el-button>
            <el-button size="mini" @click="nextWeek" round
              >下一周<i class="el-icon-arrow-right el-icon--right"></i>
            </el-button>
          </el-button-group>

          <el-button-group
            class="date-ctrl"
            style="margin-left: 30px"
            v-if="calendarMode === 'month'"
          >
            <el-button
              icon="el-icon-arrow-left"
              size="mini"
              @click="prevMonth"
              round
              >上个月</el-button
            >
            <el-button size="mini" @click="today">今天</el-button>
            <el-button size="mini" round @click="nextMonth"
              >下个月<i class="el-icon-arrow-right el-icon--right"></i>
            </el-button>
          </el-button-group>
        </div>
        <div>
          <el-button
            v-permission="['admin:schedule:new_schedule']"
            style="margin-right: 5px"
            type="primary"
            size="mini"
            @click="addSchedule"
            >新增
          </el-button>
          <el-button
            style="margin-right: 20px"
            type="primary"
            size="mini"
            @click="exportExcel"
            >导出
          </el-button>
        </div>
      </el-row>

      <el-switch
        v-model="scheduleGroupByUser"
        v-if="showScheduleGroupByUserSwitch"
        style="position: absolute; left: 160px; top: 62px"
        active-text="以人分区列示"
        inactive-text="以日程列表列示"
      >
      </el-switch>

      <el-calendar
        v-if="
          ((calendarMode === 'day' && showTimeCalendar) || !showTimeCalendar) &&
          show
        "
        v-model="currentDate"
        id="calendar"
        :range="calendarRange"
        v-loading="loading"
        :data-st="calendarMode"
      >
        <template slot="dateCell" slot-scope="{ date, data }">
          <!--自定义内容-->
          <div class="div_ca" @click="oneDay(data, date)" slot="reference">
            <div class="calendar-day">
              {{ data.day.split("-").slice(2).join("-") }}
            </div>

            <template v-if="calendarMode === 'month'">
              <div
                class="div_ca_b"
                v-for="(item, index) in calendarDataList"
                :key="index"
              >
                <div v-if="item.date.includes(data.day)">
                  <div v-if="item.date.includes(data.day)">
                    <div class="no_users scrollbar" v-if="!item.users">
                      <div
                        v-for="(item2, index2) in item.schedules"
                        :key="item2.id"
                        @click.stop="showThingsDetails(item2.id, data)"
                        v-permission="['admin:schedule:thing']"
                        class="thingsTitle"
                      >
                        {{ index2 + 1 }}.<span :class="dot(item2)"></span
                        >{{ item2.title }}
                      </div>
                    </div>
                    <div class="have_users scrollbar" v-else>
                      <div
                        v-for="(item2, index2) in item.users"
                        :key="item2.businessId"
                        @click.stop="showUsersDetails(item2.businessId, data)"
                        v-permission="['admin:schedule:thing']"
                        class="thingsTitle"
                      >
                        {{ index2 + 1 }}.{{ item2.title }}
                        <el-badge class="mark" :value="item2.count" />
                      </div>
                    </div>
                  </div>
                  <div v-else></div>
                </div>
                <div v-else></div>
              </div>
            </template>

            <template
              v-if="calendarMode === 'week' && scheduleGroupByUser === true"
            >
              <div
                class="div_ca_b week"
                v-for="(item, index) in calendarDataList"
                :key="index"
              >
                <div v-if="item.date.includes(data.day)">
                  <div v-if="item.date.includes(data.day)">
                    <div class="scrollbar" v-if="!item.users">
                      <div
                        v-for="(item2, index2) in item.schedules"
                        :key="item2.id"
                        @click.stop="showThingsDetails(item2.id, data)"
                        v-permission="['admin:schedule:thing']"
                        class="thingsTitle"
                      >
                        {{ index2 + 1 }}.{{ item2.title }}
                      </div>
                    </div>
                    <div class="scrollbar" v-else>
                      <div
                        v-for="(item2, index2) in item.users"
                        :key="item2.businessId"
                        @click.stop="showUsersDetails(item2.businessId, data)"
                        v-permission="['admin:schedule:thing']"
                        class="thingsTitle"
                      >
                        {{ index2 + 1 }}.{{ item2.title }}
                        <ul
                          v-for="(
                            scheduleId, scheduleIndex
                          ) in item2.scheduleIdList"
                          :key="scheduleIndex"
                        >
                          <li
                            @click.stop="showThingsDetails2(scheduleId)"
                            v-permission="['admin:schedule:thing']"
                          >
                            <span :class="dot(scheduleId)"></span>
                            {{ getScheduleInfo(scheduleId) }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div v-else></div>
                </div>
                <div v-else></div>
              </div>
            </template>

            <template
              v-if="calendarMode === 'week' && scheduleGroupByUser === false"
            >
              <div
                class="div_ca_b week"
                v-for="(item, index) in calendarDataList"
                :key="index"
              >
                <div v-if="item.date.includes(data.day)">
                  <div v-if="item.date.includes(data.day)">
                    <div class="scrollbar">
                      <div
                        v-for="(item2, index2) in item.schedules"
                        :key="item2.id"
                        @click.stop="showThingsDetails(item2.id, data)"
                        v-permission="['admin:schedule:thing']"
                        class="thingsTitle"
                      >
                        <span :title="scheduleUsersName(item2)"
                          >{{ index2 + 1 }}.
                          <span :class="dot(item2)"></span>
                          {{ item2.title }}（{{ item2.createUserName }}）</span
                        >
                      </div>
                    </div>
                  </div>
                  <div v-else></div>
                </div>
                <div v-else></div>
              </div>
            </template>
          </div>
        </template>
      </el-calendar>

      <time-calendar
        v-if="showTimeCalendar && show"
        :view-type="calendarMode"
        :schedule-list="scheduleList"
        :calendar-date="currentDateStr"
        @new-event="timeCalendarNewEvent"
        @change-event="timeCalendarChangeEvent"
        @click-event="timeCalendarClickEvent"
      ></time-calendar>

      <div class="day-panel" v-if="calendarMode === 'day' && !showTimeCalendar">
        <el-main>
          <el-row class="date">
            {{ currentDateStr }}
          </el-row>

          <el-row
            class="by-user"
            v-if="
              currentDayItem &&
              currentDayItem.users &&
              scheduleGroupByUser === true
            "
          >
            <el-col v-for="(user, index) in currentDayItem.users" :key="index">
              <span class="name">{{ user.title }}</span>

              <ul
                class="schedule"
                v-for="(scheduleId, scheduleIndex) in user.scheduleIdList"
                :key="scheduleIndex"
              >
                <li>
                  <span
                    @click.stop="showThingsDetails2(scheduleId)"
                    v-permission="['admin:schedule:thing']"
                  >
                    <span :class="dot(scheduleId)"></span>
                    {{ getScheduleInfo(scheduleId) }}
                    <span class="time">{{
                      getScheduleTimeStr(scheduleId * 1)
                    }}</span>
                  </span>
                </li>
              </ul>
            </el-col>
          </el-row>

          <el-row
            class="by-schedule"
            v-else-if="currentDayItem && currentDayItem.schedules"
          >
            <ul
              class="schedule"
              v-for="(schedule, index) in currentDayItem.schedules"
              :key="index"
            >
              <li>
                <span
                  @click.stop="showThingsDetails2(schedule.id * 1)"
                  v-permission="['admin:schedule:thing']"
                >
                  <span :class="dot(schedule.id * 1)"></span>
                  {{ getScheduleInfo(schedule.id * 1) }}（{{
                    scheduleUsersName(schedule)
                  }}）
                  <span class="time">{{
                    getScheduleTimeStr(schedule.id * 1)
                  }}</span>
                </span>
              </li>
            </ul>
          </el-row>

          <el-row v-else class="empty"> 暂无日程 </el-row>
        </el-main>
      </div>
    </div>

    <!-- 编辑 -->
    <calendarEdit
      v-if="editDialog"
      :dialogVisible="editDialog"
      :selectUserList="selectThingsDetails.userList"
      :calendarFormBefore="selectThingsDetails"
      v-on:listenEditClose="listenEditClose"
    ></calendarEdit>

    <!-- 详情 -->
    <calendarDetials
      v-if="detailsDialog"
      :detailsData="selectThingsDetails"
      :detailsDialog="detailsDialog"
      @openMeetingDetail="listenOpenMeetingDetail"
      @listenDetails="listenDetails"
    ></calendarDetials>

    <!-- 多用户日程信息详情 -->
    <calendarUserDetails
      v-if="calendarUserDetailsDialog"
      :calendarUserDetailsDialog="calendarUserDetailsDialog"
      :selectUserDetails="selectUserDetails"
      :info="info"
      v-on:listenCalendarUserDetails="listenCalendarUserDetails"
    ></calendarUserDetails>

    <meetingDetails
      v-if="showMeetingDetail"
      :detailsDialog="showMeetingDetail"
      :id="this.meetingId"
      @listenDetailsClose="showMeetingDetail = false"
    ></meetingDetails>

    <el-dialog
      title="新增日程"
      width="550px"
      :visible.sync="showCalendarSimpleEdit"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      append-to-body
    >
      <calendarSimpleEdit
        :info="calendarSimpleInfo"
        @close="simpleEditClose"
      ></calendarSimpleEdit>
    </el-dialog>

    <el-dialog
      v-permission="['admin:schedule:new_schedule']"
      title="新增日程"
      width="650px"
      :visible="newScheduleDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      @close="newScheduleDialog = false"
    >
      <new-schedule
        v-if="newScheduleDialog"
        :info="selectThingsDetails"
        v-on:listeNewSchedule="listeNewSchedule"
      />
    </el-dialog>
  </div>
</template>

<script>
import calendarSimpleEdit from "../calendar/simpleEdit";
import calendarEdit from "../calendar/edit.vue";
import calendarDetials from "../calendar/calendarDetails.vue";
import calendarUserDetails from "../calendar/calendarUserDetails.vue";
import timeCalendar from "../calendar/timeCalendar";
import meetingDetails from "@/components/minutesOfMeeting/meetingDetails";

import {
  getCalendarList,
  getCalendarThingDetails,
  setOptionUserInfo,
  getOptionUserInfo,
  getScheduleCalendarInfo,
  exportCalendarSchedule,
  editCalendarTime,
  getScheduleUseDeptList,
} from "../../api/calendar.js";
import NewSchedule from "@/components/home/newSchedule";

export default {
  name: "calendar",

  props: {
    scheduleId: Number,
    indexType: String,
  },

  components: {
    NewSchedule,
    calendarEdit,
    calendarDetials,
    calendarUserDetails,
    timeCalendar,
    calendarSimpleEdit,
    meetingDetails,
  },

  data() {
    return {
      editDialog: false,
      detailsDialog: false,
      calendarUserDetailsDialog: false,
      calendarDataList: [],
      scheduleList: [],
      currentDate: new Date(),
      selectDate: "",
      selectThingId: "",
      selectThingsDetails: {
        userList: [],
      },
      selectUserDetails: {},
      formData: {
        userIdList: [],
      },
      userSetDefault: {
        name: "schedule:preference:home_display",
        value: {
          scope: 2, // 1:所有人 2:我自己 3:指定成员
          userIdList: [],
          calendarMode: "month",
          scheduleGroupByUser: true,
        },
      },
      userSet: {
        name: "schedule:preference:home_display",
        value: {
          scope: 2, // 1:所有人 2:我自己 3:指定成员
          userIdList: [],
          calendarMode: "month",
          scheduleGroupByUser: true,
        },
      }, //设置用户
      useDeptList: [],
      typeList: [
        {
          lable: 1,
          name: "所有人",
        },
        {
          lable: 2,
          name: "我自己",
        },
        {
          lable: 3,
          name: "指定人员",
        },
      ],

      calendarMode: "month", // 日历模式，day, week, month
      calendarRange: null, //日历显示的日期范围，用于周和日模式
      currentDayItem: [], //当前日期的数据项，每个数据项为 一个用户 或 一个日程
      scheduleGroupByUser: true, // 周视图下，是否按人分组显示

      loading: false,
      showTimeCalendar: false, // 是否显示时间轴日历
      show: false, // 当前组件是否显示
      showCalendarSimpleEdit: false, // 是否显示极简日程编辑对话框
      calendarSimpleInfo: {}, // 极简日程编辑传入数据

      showMeetingDetail: false, //是否显示会议纪要弹窗
      meetingId: null,
      newScheduleDialog: false, //是否显示新增日程弹窗
    };
  },

  computed: {
    getYearAndMounth() {
      return `${this.currentDate.getFullYear()}-${
        this.currentDate.getMonth() + 1
      }`;
    },

    currentDateStr() {
      return jstool.dateStr(this.currentDate);
    },

    // 是否显示 scheduleGroupByUser 开关控件
    showScheduleGroupByUserSwitch() {
      if (this.calendarMode === "day" || this.calendarMode === "week") {
        if (this.userSet.value.scope === 1) {
          // 所有人
          return true;
        }

        // 指定人 且 不止1个人
        if (
          this.userSet.value.scope === 3 &&
          this.userSet.value.userIdList &&
          this.userSet.value.userIdList.length > 1
        ) {
          return true;
        }
      }
      return false;
    },
  },

  activated() {
    this.init();
  },

  watch: {
    scheduleId(newVal, old) {
      if (newVal) {
        this.showThingsDetails2(newVal);
      }
    },
    indexType(newVal, old) {
      if (newVal == 1) {
        this.init();
        this.show = true;
      } else {
        this.show = false;
      }
    },
    currentDate(nVal) {},
    detailsDialog(newVal, old) {
      if (newVal) {
        this.$store.dispatch("lockScroll", { value: true });
      } else {
        this.$store.dispatch("lockScroll", { value: false });
      }
    },
    "$store.state.calendarScope": function () { 
      console.log(this.$store.state.calendarScope); 
      if (this.$store.state.calendarScope != this.userSet.value.scope) {
        this.init();
        this.loadData();
      }
    },
  },

  created() {},

  mounted() {},

  methods: {
    listeNewSchedule(params) {
      let { close, type } = params;
      if (type == "sure") {
      }
      this.newScheduleDialog = !close;
      this.loadData();
    },
    async init() {
      await this.getOptionUserInfo();
      this.calendarModeChange(this.calendarMode);
    },

    loadData() {
      let showTimeCalendar = false;
      if (
        this.userSet.value.scope === 2 &&
        (this.calendarMode === "week" || this.calendarMode === "day")
      ) {
        showTimeCalendar = true;
      } else if (
        this.userSet.value.scope === 3 &&
        (this.calendarMode === "week" || this.calendarMode === "day")
      ) {
        if (
          this.userSet.value.userIdList &&
          this.userSet.value.userIdList.length === 1
        ) {
          showTimeCalendar = true;
        }
      }
      this.showTimeCalendar = showTimeCalendar;

      this.getCalendarList(
        this.getYearAndMounth,
        this.userSet.value.userIdList
      );
    },

    //导出
    exportExcel() {
      var userIdList = JSON.parse(
        JSON.stringify(this.userSet.value.userIdList)
      );
      if (userIdList) {
        userIdList.forEach(function (item, index) {
          userIdList[index] = item[1];
        });
      }
      let info = {
        timeToQuery: new Date(this.currentDate).getTime(),
        scope: this.userSet.value.scope,
        userIdList: userIdList,
      };

      exportCalendarSchedule(info)
        .then((res) => {
          const link = document.createElement("a");
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel,charset=gb2312",
          });
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);

          console.log(res.headers);
          console.log(res);
          console.log(link);
          link.setAttribute(
            "download",
            `${res.headers["content-disposition"]}`.split("=")[1]
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => {
          console.warn(err);
        });
    },

    //点击某一天
    oneDay(e, date) {
      let itemInfo = {};
      this.currentDayItem = null;
      this.calendarDataList.forEach((item, index) => {
        if (e.day == item.date[0]) {
          itemInfo = item;
          this.currentDayItem = item;
          return false;
        }
      });

      if (this.calendarMode === "day") {
        this.currentDate = date;
        return;
      }

      let userIdList = JSON.parse(JSON.stringify(itemInfo.users || []));

      if (userIdList.length === 0) {
        return;
      }

      userIdList.forEach(function (item, index) {
        userIdList[index] = item.businessId;
      });
      let info = {
        timeToQuery: new Date(e.day).getTime(),
        userIdList: userIdList,
      };
      this.info = info;
      this.getScheduleCalendarInfo(info);
    },

    userScopeChange(scope) {
      if (scope * 1 !== 3) {
        this.userSet.value.userIdList = [];
        this.loadData();
      }
    },

    setOptionUserInfo() {
      let userSet = { ...this.userSet };
      userSet.value.calendarMode = this.calendarMode;
      userSet.value.scheduleGroupByUser = this.scheduleGroupByUser;

      userSet.value = JSON.stringify(userSet.value);

      setOptionUserInfo(userSet).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "保存偏好成功!" });
        }
      });
    },

    async getOptionUserInfo() {
      const response = await getOptionUserInfo({
        name: "schedule:preference:home_display",
      });
      this.userSet.value = Object.assign({}, this.userSetDefault.value);
      if (response.success && response.data) {
        const info = JSON.parse(response.data);
        if (info.scope) {
          Object.assign(this.userSet.value, info);
        }
        if (this.userSet.value.scope === 3) {
          await this.getUseDeptList(true);
        }
      }

      this.calendarMode = this.userSet.value.calendarMode;
      this.scheduleGroupByUser = this.userSet.value.scheduleGroupByUser;
    },

    async getUseDeptList(cb) {
      if (cb) {
        const response = await getScheduleUseDeptList({});
        if (response.success) {
          let useDeptList = response.data;
          useDeptList.forEach(function (item) {
            item.uid = item.deptId;
            item.realName = item.deptName;
          });
          this.useDeptList = useDeptList;
        }
      }
    },

    //获取列表数据
    getCalendarList(calendarDate, list) {
      let scope = this.userSet.value.scope;
      if (scope === 3 && (!list || list.length === 0)) {
        return;
      }
      let currentDate = new Date(calendarDate).getTime();
      let userIdList = JSON.parse(JSON.stringify(list));
      if (userIdList) {
        userIdList.forEach(function (item, index) {
          userIdList[index] = item[1];
        });
      }
      let info = {
        timeToQuery: currentDate,
        timeStr: calendarDate,
        timeType: 4, // 日历模式，begin = month.first_day - 7 day, end = month.last_day + 7 day
        scope: scope,
        userIdList: userIdList ? userIdList : [],
      };
      this.loading = true;
      getCalendarList(info)
        .then((response) => {
          this.loading = false;
          this.calendarDataList = response.data;
          this.scheduleList = response.scheduleList;

          this.currentDayItem = null;
          this.calendarDataList.forEach((item) => {
            if (jstool.dateStr(this.currentDate) === item.date[0]) {
              this.currentDayItem = item;
              return false;
            }
          });
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
        });
    },

    //获取事件详情
    getCalendarDetails(id) {
      getCalendarThingDetails(id).then((response) => {
        this.selectThingsDetails = response.data;
        this.detailsDialog = true;
      });
    },

    getScheduleCalendarInfo(info) {
      var that = this;
      getScheduleCalendarInfo(info).then((response) => {
        let selectUserDetails = response.data;

        selectUserDetails.forEach(function (item, index) {
          item.children = item.scheduleList;
          let nameTitle = "";
          if (item.deptList != null) {
            nameTitle = that.getTextByJs(item.deptList) + "：";
          }
          item.title = nameTitle + item.realName;
        });
        that.selectUserDetails = selectUserDetails;
        that.calendarUserDetailsDialog = true;
      });
    },

    getTextByJs(arr) {
      var str = "";
      for (var i = 0; i < arr.length; i++) {
        str += arr[i].deptName + "、";
      }
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        str = str.substr(0, str.length - 1);
      }

      return str;
    },

    //打开详情弹窗
    showThingsDetails(thingsId, data) {
      this.selectThingId = thingsId;
      this.selectDate = data.day;
      this.getCalendarDetails(thingsId);
    },

    showThingsDetails2(thingsId) {
      this.selectThingId = thingsId;
      this.getCalendarDetails(thingsId);
    },

    showUsersDetails(id, data) {
      let userIdList = [];
      userIdList.push(id);
      let info = {
        timeToQuery: new Date(data.day).getTime(),
        userIdList: userIdList,
      };
      this.info = info;

      this.getScheduleCalendarInfo(info);
    },

    // 监听编辑、新增弹窗关闭
    listenEditClose(params) {
      let { close, editType } = params;
      if (editType === "add") {
      } else if (editType === "edit") {
        this.getCalendarDetails(this.selectThingId);
        this.detailsDialog = false;
      }
      this.editDialog = !close;
      this.loadData();
    },

    //监听详情弹窗关闭
    listenDetails(params) {
      let { close, type } = params;
      if (type === "delete") {
        this.detailsDialog = !close;
        this.loadData();
      } else if (type === "edit") {
        this.editDialog = true;
      } else {
        this.detailsDialog = !close;
      }
    },

    // 监听多个用户当日日程弹窗关闭
    async listenCalendarUserDetails(params) {
      let { close, type, info } = params;
      this.calendarUserDetailsDialog = !close;

      if (type === "scope") {
        this.userSet.value.scope = 3;
        this.userSet.value.userIdList = info;
        await this.getUseDeptList(true);
      }
      this.loadData();
    },

    // 日/周/月 模式切换
    calendarModeChange(mode) {
      if (mode === "week" || mode === "day") {
        this.thisWeek();
      } else if (mode === "month") {
        this.calendarRange = null;
        this.today();
      }
    },

    // 上个月
    prevMonth() {
      this.currentDate = new Date(
        +new Date(this.getYearAndMounth + "-01 00:00:00") - 1
      );
      this.loadData();
    },

    // 下个月
    nextMonth() {
      this.currentDate = new Date(
        +new Date(jstool.getMonthLastDay(this.currentDate) + " 23:59:59") + 1000
      );
      this.loadData();
    },

    // 今天
    today() {
      this.currentDate = new Date();
      this.loadData();
    },

    // 前一天
    prevDay() {
      this.currentDate = new Date(+this.currentDate - 1 * 86400000);
      this.calendarRange = [
        jstool.getWeekFirstDay(this.currentDate),
        jstool.getWeekLastDay(this.currentDate),
      ];
      this.loadData();
    },

    // 后一天
    nextDay() {
      this.currentDate = new Date(+this.currentDate + 1 * 86400000);
      this.calendarRange = [
        jstool.getWeekFirstDay(this.currentDate),
        jstool.getWeekLastDay(this.currentDate),
      ];
      this.loadData();
    },

    // 上一周
    prevWeek() {
      this.currentDate = new Date(+this.currentDate - 7 * 86400000);
      this.calendarRange = [
        jstool.getWeekFirstDay(this.currentDate),
        jstool.getWeekLastDay(this.currentDate),
      ];
      this.loadData();
    },

    // 下一周
    nextWeek() {
      this.currentDate = new Date(+this.currentDate + 7 * 86400000);
      this.calendarRange = [
        jstool.getWeekFirstDay(this.currentDate),
        jstool.getWeekLastDay(this.currentDate),
      ];
      this.loadData();
    },

    // 本周
    thisWeek() {
      this.currentDate = new Date();
      this.calendarRange = [
        jstool.getWeekFirstDay(this.currentDate),
        jstool.getWeekLastDay(this.currentDate),
      ];
      this.loadData();
    },

    // 获取日程标题
    getScheduleInfo(scheduleId) {
      for (const schedule of this.scheduleList) {
        if (schedule.id === scheduleId) {
          return `${schedule.title}`;
        }
      }
      return "";
    },

    //获取日程 开始时间 ~ 结束时间
    getScheduleTimeStr(scheduleId) {
      for (const schedule of this.scheduleList) {
        if (schedule.id === scheduleId) {
          const beginHourStr = jstool.hourStr(new Date(schedule.beginTime));
          const endHourStr = jstool.hourStr(new Date(schedule.endTime));

          const beginDateStr = jstool.dateStr(new Date(schedule.beginTime));
          const endDateStr = jstool.dateStr(new Date(schedule.endTime));

          let time = "";
          if (beginDateStr === endDateStr) {
            time = `${beginHourStr} ~ ${endHourStr}`;
          } else {
            time = `${beginDateStr} ${beginHourStr} ~  ${endDateStr} ${endHourStr}`;
          }

          let thisYear = new Date().getFullYear();
          time = time.replaceAll(thisYear + "-", "");

          return time;
        }
      }
      return "";
    },

    // 获取日程关联的人员，owner 放在第一个位置
    scheduleUsersName(schedule) {
      let names = "";
      for (let user of schedule.userList) {
        if (user.realName !== schedule.createUserName) {
          names += "，" + user.realName;
        }
      }
      return schedule.createUserName + names;
    },

    userIdListChange() {
      this.loadData();
    },

    // 时间轴触发新建事件
    timeCalendarNewEvent(info) {
      console.info(info);
      // this.calendarSimpleInfo = info;
      // this.calendarSimpleInfo.timestamp = +new Date();
      // this.showCalendarSimpleEdit = true;

      const startDate = jstool.parseDate(info.startStr);
      let endDate = jstool.parseDate(info.endStr);
      if (jstool.hourStr(endDate) === "00:00") {
        endDate = new Date(endDate.getTime() - 1000);
      }

      this.selectThingsDetails = {
        beginTime: startDate.getTime(),
        endTime: endDate.getTime(),
      };
      this.newScheduleDialog = true;
    },

    // 关闭极简新增事件窗口
    simpleEditClose(info) {
      // ok 表示有事件被保存
      if (info.state === "ok") {
        this.loadData();
      } else if (info.state === "full-edit") {
        this.selectThingsDetails = {
          title: info.title,
          beginTime: info.beginTime,
          endTime: info.endTime,
        };
        this.editDialog = true;
      }

      this.showCalendarSimpleEdit = false;
    },

    // 时间轴触发修改事件
    async timeCalendarChangeEvent(info) {
      console.info(info);
      const id = info.id;
      const beginTime = jstool.parseDate(info.startStr).getTime();
      let endTime = null;
      if (info.allDay) {
        if (info.endStr) {
          endTime = new Date(jstool.parseDate(info.endStr).getTime() - 1000);
        } else {
          endTime = jstool.parseDate(info.startStr + " 23:59:59").getTime();
        }
      } else {
        if (info.endStr) {
          endTime = jstool.parseDate(info.endStr).getTime();
        } else {
          // 为空可能是从全天拖入时段，结束时间默认为开始时间+1小时
          const oneHour = 60 * 60 * 1000;
          endTime = beginTime + oneHour;
        }
      }
      const param = { id, beginTime, endTime };
      editCalendarTime(param)
        .then((res) => {
          if (res.success) {
            this.$message.success("保存成功");
          }
          this.loadData();
        })
        .catch((reason) => {
          this.loadData();
          console.error(reason);
        });
    },

    // 时间轴上点击一个已创建的事件
    timeCalendarClickEvent(info) {
      console.info(info);
      if (info.id) {
        this.showThingsDetails2(info.id);
      }
    },

    // 监听打开会议纪要详情事件
    listenOpenMeetingDetail(info) {
      const meetingId = info.meetingId;
      this.meetingId = meetingId;
      this.showMeetingDetail = true;
    },

    dot(item) {
      if (typeof item === "number") {
        for (const schedule of this.scheduleList) {
          if (schedule.id === item) {
            item = schedule;
            break;
          }
        }
      }
      if (item.output === "meeting_record") {
        if (item.meetingProgress) {
          let progress = item.meetingProgress.progress;
          if (progress == 100) {
            return "green-dot";
          } else if (progress == 0) {
            return "red-dot";
          } else {
            return "yellow-dot";
          }
        }
      }
      return "no-dot";
    },
    addSchedule() {
      this.newScheduleDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.div_ca {
  height: 100%;

  .div_ca_b {
    // height: 80%;
  }
}

.have_users {
  overflow-y: auto;
  height: 50px;
}

.no_users {
  overflow-y: auto;
  height: 50px;
}

.is-selected {
  color: #f8a535;
  font-size: 16px;
  margin-top: 5px;
}

.tip {
  color: red;
  font-size: 18px;
  text-align: left;
  margin-left: 15px;
}

.thingsTitle {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  font-size: 12px;
}

.bar {
  overflow: hidden;
  padding: 10px 0;

  li {
    min-height: 50px;
    line-height: 50px;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5px 0;

    .lableTitle {
      display: inline-block;
      width: 95px;
      text-align: right;
    }

    .childWidth {
      width: calc(100% - 120px);
    }
  }

  .width50 {
    width: 50%;
  }

  .width100 {
    width: 100%;

    p {
      width: calc(100% - 120px);
      float: right;

      span {
        display: inline;
        padding: 0 10px;
      }
    }
  }
}

#calendar[data-st="week"] {
  .thingsTitle {
    margin-top: 10px;
    margin-bottom: 10px;

    ul {
      margin-top: 5px;

      li {
        padding-left: 2px;
        margin-left: 7px;
        //
        //&::before {
        //  content: '\25cb';
        //  font-size: 14px;
        //  padding-right: 3px;
        //  color: #7f7f7f;
        //}
      }
    }
  }

  div.scrollbar {
    height: 500px;
    overflow-y: auto;
  }
}

.day-panel {
  padding: 0 0 0 20px;
  font-size: 14px;
  line-height: 20px;

  .date {
    font-weight: bold;
    font-size: 18px;
    color: #409eff;
  }

  .by-user {
    padding-left: 10px;

    .name {
      display: inline-block;
      padding: 5px 0;
      margin-top: 5px;
      font-weight: bold;
      color: #606060;
    }
  }

  .empty {
    padding: 10px;
  }

  ul.schedule {
    margin-top: 5px;

    li {
      //padding-left: 2px;
      //margin-left: 7px;
      //
      //&::before {
      //  content: '\25cb';
      //  font-size: 14px;
      //  padding-right: 3px;
      //  color: #7f7f7f;
      //}

      span {
        cursor: pointer;

        &:hover {
          color: #409eff;
        }
      }

      span.time {
        padding-left: 10px;
        color: #aaaaaa;

        &:hover {
          color: #409eff;
        }
      }
    }
  }
}

.date-ctrl {
  button {
    height: 28px;
  }
}

$dotSize: 6px;

.blue-dot {
  display: inline-block;
  width: $dotSize;
  height: $dotSize;
  margin-right: 2px;
  background-color: #2d8cf0;
  border-radius: 10px;
  vertical-align: middle;
  margin-top: -2px;
}

.red-dot {
  display: inline-block;
  width: $dotSize;
  height: $dotSize;
  margin-right: 2px;
  background-color: #ed4014;
  border-radius: 10px;
  vertical-align: middle;
  margin-top: -2px;
}

.green-dot {
  display: inline-block;
  width: $dotSize;
  height: $dotSize;
  margin-right: 2px;
  background-color: #19be6b;
  border-radius: 10px;
  vertical-align: middle;
  margin-top: -3px;
}
.yellow-dot {
  display: inline-block;
  width: $dotSize;
  height: $dotSize;
  margin-right: 2px;
  background-color: #ffd700;
  border-radius: 10px;
  vertical-align: middle;
  margin-top: -3px;
}
.no-dot {
  display: inline-block;
  width: $dotSize;
  height: $dotSize;
  margin-right: 2px;
  vertical-align: middle;
  margin-top: -3px;
}
</style>

<style lang="scss" scoped>
sup.el-badge__content.el-badge__content--undefined {
  background-color: #cccccc !important;
}
</style>

<style>
.el-calendar .el-calendar__header .el-calendar__button-group {
  display: none;
}
.el-calendar[data-st="week"] .el-calendar-day {
  height: auto !important;
  min-height: 85px;
}
.el-calendar[data-st="week"] .scrollbar {
  height: auto !important;
}
</style>
