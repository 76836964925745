<template>
  <el-row class="scrollbar">
    <div class="fa" v-if="todoList.length > 0">
      <div v-for="(item, index) in todoList" :key="index" class="text item">
        <div
          class="link"
          :class="{ active: new Date().getTime() > item.endTime &&  item.endTime}"
          @click="jumpLink(item)"
        >
          <span>{{ index + 1 }}.</span>
          <el-link
           :class="{ active: new Date().getTime() > item.endTime &&  item.endTime }"
            :type="item.businessType != 0 ? 'primary' : ''"
            :underline="item.businessType != 0"
            >{{ item.content }}
            <span v-show="item.endTime != null">{{
              item.endTime | formatDateFilter
            }}</span>
          </el-link>
        </div>
      </div>
    </div>
    <div class="faa flexJA" v-else>暂无待办列表</div>

    <!-- 详情 -->
    <calendarDetials
      v-if="detailsDialog"
      v-on:listenDetails="listenDetails"
    ></calendarDetials>
    <calendarEdit
      v-if="editDialog"
      v-on:listenEditClose="listenEditClose"
    ></calendarEdit>
    <!-- 会议详情 -->
    <meetingDetails
      v-if="meetingDetailsDialog"
      :detailsDialog="meetingDetailsDialog"
      v-on:listenDetailsClose="listenDetailsClose"
      :id="meetingInfoId"
      type="homeClick"
    ></meetingDetails>
  </el-row>
</template>

<script>
import calendarDetials from "../calendar/calendarDetails.vue";
import calendarEdit from "../calendar/edit.vue";
import meetingDetails from "@/components/minutesOfMeeting/meetingDetails";

import { getTodoQueryList } from "@/api/home";
import { getCalendarThingDetails } from "../../api/calendar.js";
import { formatDate } from "@/common/date";

export default {
  name: "needDealt",
  data() {
    return {
      activeName: "first",
      todoList: [], //待办事项
      detailsDialog: false,
      editDialog: false,
      selectThingsDetails: null,
      editType: null,
      selectThingId: null,
      meetingDetailsDialog: false,
      meetingInfoId: null,
    };
  },
  props: {
    indexType: String,
  },
  components: {
    calendarDetials,
    calendarEdit,
    meetingDetails,
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm");
    },
  },
  computed: {},
  watch: {
    indexType(newVal, old) {
      if (newVal == 0) {
        this.getTodoQueryList();
      }
    },
  },
  created() {
    // this.getTodoQueryList();
  },
  activated() {
    console.log("home--------actived3");
    this.getTodoQueryList();
  },
  methods: {
    //新增编辑弹窗显示
    openDetailsDialog(id) {
      console.log(id);
      this.meetingInfoId = id;
      this.meetingDetailsDialog = true;
    },
    //监听详情弹窗关闭
    listenDetailsClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.meetingDetailsDialog = !isClose;
      if (type == "sure") {
      }
    },
    //
    jumpLink(item) {
      console.log(item);
      if (item.businessType == 5) {
        this.$emit("listenDetailsClose", {
          type: "sure",
          isClose: true,
          info: item,
        });
      } else if (item.businessType == 6) {
        this.openDetailsDialog(item.businessId);
      } else if (item.businessType == 8) {
        this.$router.push("myMission");
      }
    },

    handleClick(tab, event) {
      console.log(tab.index, event);
      let index = tab.index;
      if (index == 0) {
        this.getTodoQueryList();
      } else if (index == 1) {
      }
    },
    getTodoQueryList() {
      getTodoQueryList(true).then((response) => {
        console.log(response);
        this.todoList = response.data;
      });
    },
    //
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  height: 13rem;
  overflow-y: auto;
  overflow-x: hidden;
  .faa {
    height: 100%;
    font-size: 14px;
    color: #999;
  }
}
.home {
  width: 100%;
  .left {
    width: 67%;
    text-align: left;
    .needDealt {
      .cardHeader {
        display: flex;
        justify-content: flex-start;
        // align-items: baseline;
      }
    }
    .projectOverview {
      margin-top: 30px;
    }
  }
  .right {
    width: 29%;
    text-align: left;
  }
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.is-selected {
  color: #f8a535;
  font-size: 16px;
  margin-top: 5px;
}
.tip {
  color: red;
  font-size: 18px;
  text-align: left;
  margin-left: 15px;
}
.thingsTitle {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  font-size: 12px;
}
.active {
  color: darkgrey !important;
  span {
    color: darkgrey !important;
  }
}
</style>
