<template>
  <el-row class="scrollbar">
    <el-tree
      :data="selectListDepartmentOption"
      node-key="id"
      accordion
      :expand-on-click-node="false"
      :default-expanded-keys="[]"
      @node-click="handleNodeClick"
      @current-change="currentChange"
    >
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span>{{ data.realName }}</span>
        <span>
          <!-- <el-button
            type="text"
            size="mini"
            @click="() => append(data)">
            Append
          </el-button>
          <el-button
            type="text"
            size="mini"
            @click="() => remove(node, data)">
            Delete
          </el-button> -->
        </span>
      </span>
    </el-tree>
  </el-row>
</template>

<script>
import { getOverviewList } from "@/api/home";
import { selectListDepartment } from "@/api/department";
import eventBus from "@/common/eventBus.js";
export default {
  name: "workProgress",
  data() {
    return {
      overviewList: [], // 
      detailsDialog: false,
      editDialog: false,
      selectThingsDetails: null,
      editType: null,
      selectThingId: null,
      selectListDepartmentOption: [],
      routeName: "myMission",
    };
  },
  components: {},
  computed: {},
  watch: {},
  created() {
    // this.selectListDepartment();
  },
  activated() {
    console.log("home--------actived6");
    this.selectListDepartment();

  },
  methods: {
    handleNodeClick(data) {
      console.log(data);
      //   eventBus.$emit("userInfo", data);
      //   window.sessionStorage.setItem("workProgressUserId", data.uid);
      if (data.uid) {
        const { href } = this.$router.resolve({
          name: this.routeName,
          query: { workProgressUserId: data.uid },
        });
        window.open(href);
      }
    },
    currentChange(data, node) {
      // console.log(data);
      // console.log(node);
    },
    selectListDepartment() {
      selectListDepartment({}).then((response) => {
        let selectListDepartmentOption = response.data;
        selectListDepartmentOption.forEach(function (item, index) {
          item.children = item.userList;
          item.realName = item.name;
        });
        this.selectListDepartmentOption = selectListDepartmentOption;
      });
    },
    projectOverviewJump(item, node) {
      console.log(item);
      console.log(node);
    },
    //
    jumpLink(item) {
      console.log(item);
      this.$emit("listenDetailsClose", {
        type: "sure",
        isClose: true,
        info: item,
      });
    },

    handleClick(tab, event) {
      console.log(tab.index, event);
      let index = tab.index;
      if (index == 0) {
        this.getTodoQueryList();
      } else if (index == 1) {
      }
    },
    getOverviewList() {
      let info = { day: null };
      getOverviewList(info).then((response) => {
        console.log(response.data);
        this.overviewList = response.data;
      });
    },
    //
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  .item {
    margin: 10px 0;
    .nodeList {
      overflow-x: auto;
      overflow-y: hidden;
      height: 4rem;
      width: 100%;
      .el-button {
        margin: 5px 10px;
      }
    }
  }
  .el-tree-node__content:hover {
    color: #409eff;
  }
  .custom-tree-node:hover {
    color: #409eff;
  }
}
</style>
