<template>
  <div class="Echarts">
    <div id="main" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
import Vue from "vue";
import echarts from "echarts";
// Vue.prototype.$echarts = echarts
import { getProjectQuantityInfo } from "@/api/home";
export default {
  name: "projectStatistics",
  data() {
    return {
      overviewList: [], //待办事项
      detailsDialog: false,
      editDialog: false,
      selectThingsDetails: null,
      editType: null,
      selectThingId: null,
      projectStatisticsDays: "-7",
      projectQuantityInfo: null,
    };
  },
  props: {
    projectStatisticsTime: String,
  },
  components: {},
  computed: {},
  watch: {
    projectStatisticsTime(newVal, old) {
      console.log(newVal + "-------------");
      if (newVal == "-7") {
        this.projectStatisticsDays = newVal;
      } else {
        this.projectStatisticsDays = null;
      }
      this.getProjectQuantityInfo();
    },
  },
  created() {
    // this.getProjectQuantityInfo();
  },
  activated() {
    console.log("home--------actived5");
    this.getProjectQuantityInfo();
  },
  mounted() {
    // this.myEcharts();
  },
  methods: {
    myEcharts() {
      var that = this;
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("main"));

      // 指定图表的配置项和数据
      var option = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          data: that.projectQuantityInfo.groupNames,
        },
        xAxis: {
          type: "category",
          data: that.projectQuantityInfo.statusNames,
          axisTick: {
            // alignWithLabel: true,
          },
          splitLine: { show: false },
          axisLabel: {
            interval: 0,
            rotate: 40,
          },
        },
        yAxis: {
          type: "value",
          scale: true,
          max: function (value) {
            return value.max + 1;
          },
          minInterval: 1,
        },
        series: that.projectQuantityInfo.quantityList,
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      myChart.on("click", function (params) {
        console.log(params);
        console.log(params.data);
        const { href } = this.$router.resolve({
          name: "project",
          query: { nodeId: node.nodeId, workflowId: node.workflowId },
        });
        window.open(href);
      });
    },
    projectOverviewJump(item, node) {
      console.log(item);
      console.log(node);
    },
    //
    getProjectQuantityInfo() {
      let info = { day: this.projectStatisticsDays };
      getProjectQuantityInfo(info).then((response) => {
        // console.log(response.data);
        let info = response.data;
        info.quantityList.map(function (item, index) {
          item.type = "bar";
          item.name = item.groupName;
          item.data = item.quantities;
        });
        this.projectQuantityInfo = info;
        this.myEcharts();
      });
    },
    //
  },
};
</script>

<style lang="scss" scoped>
.Echarts {
  height: 12rem;
}
</style>
