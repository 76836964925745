import request from '@/utils/request';
import requestFormData from '@/utils/requestFormData';
//条件查询待办事项列表
export function getTodoQueryList(param) {
    return request({
        url: '/admin/todo/queryList',
        method: 'post',
        data: {
            owned: param,
            pageNum: 1,
            pageSize: 30,
        }
    })
}

//评估记录导入
export function importRecord(param) {
    return requestFormData({
        url: '/admin/evaluation/record/import',
        method: 'post',
        data: param
    })
}

export function getLatestList(param) {
    return request({
        url: '/admin/info/internal/latestList',
        method: 'post',
        data: param
    })
}
//查询流程概览列表
export function getOverviewList(param) {
    return request({
        url: '/admin/workflow/findOverviewList',
        method: 'post',
        data: param
    })
}

//查询流程概览列表
export function getProjectQuantityInfo(param) {
    return request({
        url: '/admin/project/findQuantityInfo',
        method: 'post',
        data: param
    })
}

