<template>
  <div>
    <el-form
      :model="calendarForm"
      ref="form1"
      label-width="0"
      :rules="rules"
    >
      <el-row>
        <el-form-item label="" prop="title">
          <el-input type="text" ref="title" placeholder="请填写事务主题" v-model="calendarForm.title"></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <div>时间：{{ timeText }}</div>
      </el-row>
    </el-form>
    <div class="dialog-footer">
      <el-button size="mini" @click="fullEdit">完整编辑</el-button>
      <el-button size="mini" @click="addCalendarThing" type="primary">确 定</el-button>
    </div>

  </div>
</template>

<script>

import {addCalendarThing} from "../../api/calendar.js";

export default {
  name: "simpleEdit",

  props: {
    info: Object,
  },

  data() {
    return {
      calendarForm: {},
      rules: {
        title: [{required: true, message: "请填写事务主题", trigger: "blur"}],
      },
    };
  },

  created() {
  },

  mounted() {
    this.$refs['title'].focus();
  },

  watch: {
    'info.timestamp': {
      handler(val, old) {
        if (val !== old) {
          if (this.$refs['form1']) {
            this.$refs["form1"].resetFields();
          }
          if (this.$refs['title']) {
            this.$refs['title'].focus();
          }
        }
      }
    }
  },

  computed: {
    timeText() {
      const startDate = jstool.parseDate(this.info.startStr);
      let endDate = jstool.parseDate(this.info.endStr);
      if (jstool.hourStr(endDate) === '00:00') {
        endDate = new Date(endDate.getTime() - 1000);
      }

      const start = jstool.dateInfo(startDate);
      const end = jstool.dateInfo(endDate);

      let result = '';
      if (start.dateStr === end.dateStr) {
        result = start.longDateStr;
        if (!this.info.allDay) {
          result = `${result} ${start.hourStr} ~ ${end.hourStr}`;
        }
      } else {
        result = `${start.longDateStr} ~ ${end.longDateStr}`;
      }
      return result;
    },

  },

  methods: {

    //新增事件
    addCalendarThing() {
      this.$refs["form1"].validate((valid) => {

        if (valid) {

          this.calendarForm.beginTime = this.getBeginTime();
          this.calendarForm.endTime = this.getEndTime();
          addCalendarThing(this.calendarForm).then(() => {
            this.$message.success("添加成功");
            this.$refs["form1"].resetFields();
            this.$emit("close", {state: 'ok'});
          });
        }
      });
    },

    getBeginTime() {
      const startDate = jstool.parseDate(this.info.startStr);
      return startDate.getTime();
    },

    getEndTime() {
      let endDate = jstool.parseDate(this.info.endStr);
      if (jstool.hourStr(endDate) === '00:00') {
        endDate = new Date(endDate.getTime() - 1000);
      }
      return endDate.getTime();
    },

    close() {
      this.$emit("close", {state: 'cancel'});
    },

    fullEdit() {
      this.$emit("close", {
        state: 'full-edit',
        title: this.calendarForm.title,
        beginTime: this.getBeginTime(),
        endTime: this.getEndTime()
      });
    }

  },
};
</script>

<style lang="scss" scoped>

.el-form {
  .el-form-item {
    width: 100%;

    .el-input {
      width: 100% !important;
    }

  }
}

.dialog-footer {

  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  .el-button {
    margin: 0 0;
  }
}

</style>
